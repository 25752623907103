import {
    organisationsCollection,
    googleMarketPlaceCollection} from "@/firebase.js";
import { doc as firestoreDoc, getDoc, collection, query, where, onSnapshot } from "firebase/firestore";
import { GOOGLE_MARKETPLACE_ACTIVE_ENTITLEMENT_STATES } from "../../constants/appConstants";

const state = {
    userHasActiveEntitlement: null,
    marketPlaceCollectionSubscription: null
}

const getters = {}

const mutations = {
    setUserHasActiveEntitlement(state, userHasActiveEntitlement) {
        state.userHasActiveEntitlement = userHasActiveEntitlement;
    },
    setMarketPlaceCollectionSubscription(state, marketPlaceCollectionSubscription) {
        state.marketPlaceCollectionSubscription = marketPlaceCollectionSubscription;
    }
}

const actions = {
    queryOrgSubscriptions(context, orgId) {
        let marketPlaceAccountId = null;
        if (!orgId) {
            return;
        }
        // get org document using orgId and then get value of marketPlaceAccountId field
        getDoc(firestoreDoc(organisationsCollection, orgId)).then(doc => {
            if (doc.exists()) {
                marketPlaceAccountId = doc.data().marketPlaceAccountId;
            }
            if (marketPlaceAccountId) {
                // check googleMarketPlace collection for document with matching marketPlaceAccountId
                getDoc(firestoreDoc(googleMarketPlaceCollection, marketPlaceAccountId)).then(doc => {
                    if (doc.exists()) {
                        // get the entitlements subcollection and check for any documents with state = ACTIVE
                        const q = query(collection(doc.ref, "entitlements"), where("state", "in", GOOGLE_MARKETPLACE_ACTIVE_ENTITLEMENT_STATES))
                        const unsubscribe = onSnapshot(q, querySnapshot => {
                            if (querySnapshot.empty) {
                                // commit to state
                                context.commit('setUserHasActiveEntitlement', false);
                            } else {
                                // commit to state
                                context.commit('setUserHasActiveEntitlement', true);
                            }
                        })
                        context.commit('setMarketPlaceCollectionSubscription', unsubscribe);
                    }
                })
            }

        }).catch(error => {
            console.warn(`Error getting organisation google marketplace subscription for organisation ${orgId}: ${error}`);
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
