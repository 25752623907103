<template>
  <div v-if="organisation">
    <h1>{{ organisation.name }} Dash</h1>
    <h1>Welcome {{ user.displayName }}</h1>
  </div>
</template> 

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import { AuthService } from "../services/auth.service";
import { organisationsCollection } from "@/firebase.js";
import { doc as firestoreDoc, getDoc } from "firebase/firestore";

export default {
  name: "OrgDash",
  props: {
    orgId: { type: String, required: true }
  },
  data: () => ({
    user: null,
    organisation: null
  }),
  created() {
    console.log("OrgDash:  created params:" + this.$route.params);

    this.subscription = AuthService.loggedUser$.subscribe(user => {
      this.user = user;
    });

    if (this.$route.params.orgId) {
      const docRef = firestoreDoc(organisationsCollection, this.$route.params.orgId)
      getDoc(docRef)
        .then(doc => {
          if (doc.exists()) {
            this.organisation = doc.data();
          }
        });
    }
  },
  beforeUnmount() {
    this.subscription.unsubscribe();
  }
};
</script>