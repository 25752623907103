<template>
    <div>
        <EbxSearchBar 
        v-model="searchBar" 
        :filter-options="searchBarFilterOptions" 
        :hide-options="!canShareWorkflows" 
        class="mb-20 filter-wide">
            <EbxButton theme="action" icon="file_upload" @click="handleUpload">Upload new feature collection</EbxButton>
        </EbxSearchBar>
        <div v-if="userDatasetsLoading === false" class="asset-card--container">
            <AssetsList
                v-if="filteredUserAssets.length > 0"
                :assetsList="filteredUserAssets"
                @remove-asset="handleRemoveAsset"
                @asset-deleted="refreshAssets()"
                @asset-loaded="assetLoaded()"
                />
        </div>
        <div class="assets-tab--empty" v-if="filteredUserAssets.length <= 0 || userDatasetsLoading">
            <div class="ebx-icon">
                <img :src="assets.icons.emptyStateAssets" alt="No assets">
            </div>
            <template v-if="searchBar.filterBy == 'org'">
                <p class="ebx-primary">Upload feature collections to use across your projects</p>
            </template>
            <template v-else>
                <p class="ebx-primary">Upload feature collections to use across your projects</p>
            </template>
        </div>
        <AreasUploadWrapper ref="uploadDialog" />
    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import AssetsList from "@/components/AssetsList.vue";
import authMixin from "@/components/mixins/authMixin.js";
import AreasUploadWrapper from "@/components/Assets/AreasUploadWrapper.vue"
import EbxSearchBar from "../../components/EbxComponents/EbxSearchBar.vue";
import userDatasetsMixin from '@/components/mixins/userDatasetsMixin';
import { matchSorter } from 'match-sorter';
import { sharedWorkflowMixin } from '@/components/mixins/projectMixin.js'
import assetsMixin from "@/components/mixins/assetsMixin.js" 

export default {
    name: "Areas",
    mixins: [userDatasetsMixin, authMixin, sharedWorkflowMixin, assetsMixin],
    components: {
        AssetsList,
        AreasUploadWrapper,
        EbxSearchBar
    },
    data() {
        return {
            searchBar: {
                searchInput: '',
                filterBy: 'all',
            },
            filterOptionKeyword: 'feature collections'
        }
    },
    computed: {
        userAssets() {
            return this.featureCollectionMetadata
        },
        filteredUserAssets() {
            let filteredUserAssets = this.userAssets

            if (this.searchBar.filterBy === 'org') {
                filteredUserAssets = filteredUserAssets.filter(content => this.userDatasetLocation(content.id) === 'org')
            } else if (this.searchBar.filterBy === 'user') {
                filteredUserAssets = filteredUserAssets.filter(content => this.userDatasetLocation(content.id) === 'user')
            }

            if (this.searchBar.searchInput.length > 0) {
                filteredUserAssets = matchSorter(filteredUserAssets, this.searchBar.searchInput, { keys: ['title', 'keywords'] })
            }


            return filteredUserAssets.map(asset => {
                return {
                    ...asset,
                    userCanEdit: this.userDatasetCanEdit(asset.id, this.user.uid),
                }
            })
        }
    },
    methods: {
        handleUpload() {
            this.$refs.uploadDialog.openDialog();
        },
        assetLoaded(){
            this.$router.push('/workflow');
        },
        handleRemoveAsset(asset) {
            this.$store.dispatch('userdatasets/removeDataset', asset);
        }
    }
}
</script>