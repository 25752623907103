<template>
    <div class="dataset-page--images">
        <div v-if=canEdit class="dataset-page--images-section add-images-button">
            <ImageModal v-model="showImageUpload" :stacId="this.stacId" :valid-bands="datasetBands" :imageType="this.imageType" :cadence="datasetCadence">
                <template v-slot:activator="{ props: activatorProps }">
                    <v-btn color="primary" variant="tonal" prepend-icon="mdi-plus" v-bind="activatorProps" >Add Image</v-btn>
                </template>
            </ImageModal>
        </div>
        <div class="dataset-page--images-section" v-if="imagesLoaded">
            <v-expansion-panels multiple>
                <v-expansion-panel 
                    v-for="image in this.images" 
                    :key="image.id"
                    :title="image.filename"
                    >
                    <div> 
                        <v-expansion-panel-text> 
                            <div v-if="this.imageType=='point'" class="image-date">
                                <VEbxDateInput 
                                    :model-value="image.startDate"
                                    label="Select date"
                                    :disabled="!canEdit"
                                    @update:modelValue="handleStartDateSelection($event, image.id)"
                                    :error-message="getError(image.id)"
                                />
                            </div>

                            <div v-if="this.imageType=='period'">
                                <div class="date-container">
                                    <div class="image-date">
                                        <v-select 
                                            v-model="selectedCadence" 
                                            variant="outlined"
                                            label="Cadence" 
                                            :disabled="true"
                                        />
                                    </div>
                                    <div class="image-date">
                                        <VEbxDateInput 
                                            :model-value="image.startDate"
                                            label="Start date" 
                                            :disabled="!canEdit"
                                            @update:modelValue="handleStartDateSelection($event, image.id)"
                                            :error-message="getError(image.id)"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div v-if="canEdit" class="images-buttons">
                                <v-btn @click="setCurrentImage(image.id); confirmDelete=true"  variant="text" color="error" density="comfortable">Delete Image</v-btn>
                                <div>
                                    <v-btn @click="resetDate(image.id)" variant="text" color="primary" density="comfortable" :disabled="getResetButtonState(image.id)" class="pr-6">Cancel</v-btn>
                                    <v-btn :loading="image.saving" @click="updateImage(image.id)" color="primary" density="comfortable" :disabled="getSaveButtonState(image.id)">Save changes</v-btn>
                                </div>
                            </div>
                        </v-expansion-panel-text>
                    </div>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
        <div class="dataset-page--images-section loading-images" v-else>
            <v-progress-circular indeterminate :size="128" color="primary"/>
        </div>

        <VEbxModal
            v-model="confirmDelete"
            size="s"
            :show-toolbar="false"
            :show-divider="false"
            confirmation-button-text="Delete"
            confirmation-button-colour="error"
            @confirm="deleteImage()"
        >
            <template v-slot:default>
                <p class="ebx-primary">
                    Are you sure you want to delete <strong>{{ selectedImageName }}</strong>?
                </p>
                <p class="ebx-primary" v-if="this.datasetImages.length === 1">
                    Deleting this image will also delete the image collection.
                </p>
            </template>
        </VEbxModal>
    </div>
</template>

<script>
import ImageModal from '@/components/ImageUpload/Modal.vue'
import VEbxDateInput from '@/components/EbxComponents/VEbxDateInput.vue';
import VEbxModal from '../EbxComponents/VEbxModal.vue';
import {EBX_CADENCE} from '@/constants/ebxStacConstants.js'

import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import moment from 'moment'

export default {
    name: 'UserDatasetView',
    components: {
        ImageModal,
        VEbxDateInput,
        VEbxModal
    },
    setup: () => ({ v$: useVuelidate() }),
    props: {
        stacId: {
            type: String,
            required: true,
            default: null
        },
        imageType: {
            type: String,
            required: true
        },
        datasetImages: {
            type: Array,
            required: true,
        },
        formData: { 
            type: Object,
            default: () => ({})
        },
        returnAsDate: {
            type: Boolean,
            default: false
        },
        canEdit: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            images: [],
            showImageUpload: false,
            currentImage: '',
            cadenceOptions: [
                { text: 'Yearly', value: 'year' },
                { text: 'Monthly', value: 'month' },
                { text: 'Weekly', value: 'week' }
            ],
            confirmDelete: false
        }
    },
    emits: ['update:formData', 'update:model-value', 'formValid'],
    methods: {
        ImageUpload() {
            this.showImageUpload = true
        },
        async deleteImage() {
            const lastImage = (this.datasetImages.length === 1)
            console.log('Deleting image', this.currentImage, 'from', this.stacId)
            this.$store.dispatch('userdatasets/removeImage', {
                datasetId: this.stacId,
                imageId: this.currentImage
            })
            if (lastImage) {
                //last image so collection will be deleted too
                console.log('Deleting collection', this.stacId)
                this.$store.commit('userdatasets/removeDataset', this.stacId);
                this.$router.push({ name: 'Projects-Asset-Images'})
            }
        },
        async updateImage(imageId) {
            const imageToUpdate = this.images.find((im) => im.id === imageId)
            imageToUpdate.saving = true

            const newData = {
                start_time: imageToUpdate.startDate, 
                cadence: this.datasetCadence
            }

            console.log('Updating image dates', imageId, 'in', this.stacId)
            const updated = await this.$store.dispatch('userdatasets/updateImage', {
                datasetId: this.stacId,
                imageId: imageId,
                data: newData
            })
            imageToUpdate.saving = false;
            if (updated) {
                this.getImages()
            }
        },
        handleStartDateSelection(value, imageId) {
            const index = this.images.findIndex((im) => im.id === imageId)
            this.images[index].startDate = value;
        },
        setCurrentImage(imageId) {
            this.currentImage = imageId;
        },
        getImages() {
            this.images = [] //reset
            this.datasetImages.forEach((image, index) => {
                this.images.push({
                    id: image.id, 
                    filename: image.filename ?? `Image ${index + 1}`, 
                    startDate: image.startTime, 
                    endDate: image.endTime,
                    saving: false})
            })
        },
        getError(imageId) {
            const index = this.v$.images.$model.findIndex((image) => image.id === imageId)
            const validations = this.v$?.images.$each.$response.$data[index]

            if (!validations) return ""

            if (validations.startDate.$invalid) {
                if (!validations.startDate.required || !validations.startDate.validDate) {
                    return 'Please enter a valid date';
                }
                if (!validations.startDate.between) {
                    return 'Date must be between 1970 and 9999';
                }

                if (!validations.startDate.overlap) {
                    return 'Date overlaps with another image';
                }
                return 'Start date is invalid'
            }
            return ""
        },
        getSaveButtonState(imageId) {
            const originalDate = this.datasetImages.find((image) => image.id === imageId).startTime
            const newDate = this.images.find((image) => image.id === imageId).startDate
            const errors = this.getError(imageId)
            return Date.parse(originalDate) === Date.parse(newDate) || errors !== ""
        },
        getEndDate(startDate) {
            let endDate = moment(startDate) 
            if (this.datasetCadence === 'week') {
                endDate = moment(startDate).add(7, 'days').subtract(1, 'seconds')
            } else if (this.datasetCadence === 'month') {
                const daysInMonth = (year, month) => new Date(year, month, 0).getDate();
                endDate = moment(startDate).add(daysInMonth(startDate.split('-')[0], startDate.split('-')[1]), 'days').subtract(1, 'seconds')
            } else if (this.datasetCadence === 'year') {
                endDate = moment(startDate).add(1, 'years').subtract(1, 'seconds')
            }
            return endDate
        },
        resetDate(imageId) {
            const originalDate = this.datasetImages.find((image) => image.id === imageId).startTime
            const image = this.images.find((image) => image.id === imageId)
            image.startDate = originalDate
        },
        getResetButtonState(imageId) {
            const originalDate = this.datasetImages.find((image) => image.id === imageId).startTime
            const image = this.images.find((image) => image.id === imageId)
            return Date.parse(originalDate) === Date.parse(image.startDate) || image.saving
        },
        
    },
    computed: {
        imagesLoaded() {
            return this.datasetImages.length > 0
        },
        selectedImageName() {
            if (this.currentImage) {
                const image = this.images.find((image) => image.id === this.currentImage)
                return image.filename || ''
            }
            return ''
        },
        datasetCadence() {
            const dataset = this.$store.getters['userdatasets/getDisplayDataset'](this.stacId)
            return dataset[EBX_CADENCE]?.unit || 'year'
        },
        selectedCadence() {
            return this.cadenceOptions.find((c) => c.value === this.datasetCadence).text
        },
        datasetBands() {
            const dataset = this.$store.getters['userdatasets/getDisplayDataset'](this.stacId)
            const bands = dataset?.bands || []
            return bands.map(b => b.name)
        },
    },
    watch: {
        'v$.$invalid': {
            handler() {
                this.$emit('formValid', !this.v$.$invalid)
            },
            immediate: true
        },
        datasetImages(val) {
            if (val.length) {
                this.getImages()
            } 
        }
    },
    created() {
        if (this.imagesLoaded) {
            this.getImages()
        }
    },
    validations () {
        return {
            images: {
                $each: helpers.forEach({
                    startDate: {
                        required,
                        validDate: (value) => {
                            return value !== null
                        },
                        between: value => {
                            if (value === null) return true
                            const date = Date.parse(value);
                            return date >= Date.parse('1970-01-01') && date <= Date.parse('9999-12-31');
                        },
                        overlap: (value, image) => {
                            //Check if image dates overlap with any others in the dataset
                            if (this.datasetImages.length === 1) return true
                            if (value === null) return true

                            let valid = true
                            const endDate = this.getEndDate(moment(value))

                            const date = Date.parse(value)
                            this.images.forEach((compare) => {
                                if (compare.id !== image.id) {
                                    const compareEnd = this.getEndDate(compare.startDate)
                                    if ((date <= Date.parse(compareEnd)) && (Date.parse(compare.startDate) <= Date.parse(endDate))) {
                                        valid = false
                                    }
                                }
                            })
                            return valid
                        },
                    },
                })
            }
            
        }
    }

}
</script>