<template>
    <md-dialog v-model:md-active="showDialog" @md-closed="dialogClosed" class="polygon-upload ebx-dialog dialog modal-ms">
        <md-dialog-title>
            <div class="top-actions--container">
                <p class="ebx-header-2 top-actions--title">
                    Upload feature collection 
                </p>
                <div class="close-button">
                    <md-button class="md-primary" @click="cancelUpload"><md-icon>close</md-icon></md-button>
                </div>
            </div>
        </md-dialog-title>
        <md-dialog-content class="dialog-content">
            <md-content class="content">
                <div class="upload-container">
                    <div v-if="filesToUpload.length === 0 || polygonUploadError === true"
                        id="drop_zone"
                        class="upload-container--drop-zone"
                        @drop="dropHandler($event)"
                        @dragover="dragOverHandler($event)">
                        <p class="ebx-primary">Drag and drop a GeoJSON, KML, KMZ, CSV, or a zipped shape file</p>
                        <button class="ebx-button ebx-button--secondary" @click="triggerFileSelection()">Choose File</button>
                        <input
                            v-show="false"
                            ref="fileInput"
                            class="ebx-button md-button-secondary md-accent" 
                            type="file" 
                            :accept="acceptedTypes"
                            @change="handleFileChange" />
                    </div>
                    <div class="upload-container--file-zone" v-if="filesToUpload.length>0">
                        <span 
                            v-for="file in filesToUpload"
                            :key="file.name"
                            class="ebx-primary">
                            {{file.name}}
                        </span>
                        <md-button class="md-icon-button md-primary" @click="removeFile"><md-icon>close</md-icon></md-button>
                    </div>
                    <EbxAlert theme="error" v-if="polygonUploadError">
                        {{errorMessage}}
                    </EbxAlert>
                    <EbxAlert theme="error" v-if="isUserError">
                        {{fileErrorMessage}}
                    </EbxAlert>

                    <div class="category-dropdown-wrapper" v-if="showCsvFields">
                        <md-field class="category-dropdown">
                            <label>Select latitude column</label>
                            <label class="hidden-label">Latitude Column</label>
                            <md-select v-model="latColumn" :placeholder="csvColumns[0] || ''">
                                <md-option v-for="column in csvColumns" :key="column" :value="column">{{column}}</md-option>
                            </md-select>
                        </md-field>
                    </div>

                    <md-field class="category-dropdown" v-if="showCsvFields">
                        <label>Select longitude column</label>
                        <label class="hidden-label">Longitude Column</label>
                        <md-select v-model="lonColumn" :placeholder="csvColumns[1] || ''">
                            <md-option v-for="column in csvColumns" :key="column" :value="column">{{column}}</md-option>
                        </md-select>
                    </md-field>

                    <template v-if="showCsvFields">
                        <md-autocomplete 
                            name="projection"
                            id="projection"
                            v-model="selectedProjection"
                            class="category-dropdown category-dropdown--projection"
                            :md-options="availableProjections"
                            md-dense
                            :md-open-on-focus="false"
                            >
                            <label 
                                for="projection"
                                >Select coordinate reference system</label>
                        </md-autocomplete>
                    </template>

                    <div class="ebx-concertina upload-columns" v-if="showCsvFields">
                        <div class="ebx-concertina--container">
                            <transition name="concertina">
                                <div class="ebx-concertina--content" v-if="showEbxConcertinaContent">
                                    <div class="csv-column-info">
                                        <div class="csv-column" v-for="csvColumn, index in csvColumnsInfo" :key="`${csvColumn.key}-${index}`">
                                            <div class="csv-column--label">
                                                <label class="ebx-primary-bold">{{csvColumn.key}}</label>
                                            </div>
                                            <div class="csv-column--form">
                                                <md-field>
                                                    <md-select v-model="csvColumn.value">
                                                        <md-option v-for="type in Object.values(csvDataTypes)" :key="`${type}-${index}`" :value="type">{{type}}</md-option>
                                                    </md-select>
                                                </md-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </transition>
                            <div class="ebx-concertina--header">
                                <div class="ebx-concertina--header--title">
                                    <div class="ebx-primary-bold" v-if="!showEbxConcertinaContent">Show columns</div>
                                    <div class="ebx-primary-bold" v-else>Hide columns</div>
                                </div>
                                <div class="ebx-concertina--header--icon">
                                    <button 
                                    v-if="!showEbxConcertinaContent"
                                    class="ebx-icon-button ebx-icon-button__no-shadow"
                                    @click="showEbxConcertinaContent = true"><span class="material-icons-outlined ebx-icon">keyboard_arrow_down</span></button>
                                    <button 
                                    v-else
                                    class="ebx-icon-button ebx-icon-button__no-shadow"
                                    @click="showEbxConcertinaContent = false"><span class="material-icons-outlined ebx-icon">keyboard_arrow_up</span></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <md-checkbox class="ebx-checkbox mb-0" v-if="filesToUpload.length > 0 && canSplitFeatureSet && !forceUpload" v-model="splitByCategory">
                        <div class="ebx-primary-bold">Categorise area by attribute</div>
                    </md-checkbox>
                    <md-field class="category-dropdown category-dropdown--indented" v-if="filesToUpload.length > 0 && splitByCategory && canSplitFeatureSet" :class="isUserError ? 'md-invalid' : ''">
                        <label class="hidden-label">Would you like to split this dataset by attribute:</label>
                        <md-select v-model="selectedSplitProperty" :placeholder="availableSplitProperties[0] || ''">
                            <md-option v-for="property in availableSplitProperties" :key="property" :value="property">{{property}}</md-option>
                        </md-select>
                        <span class="md-error">{{fileErrorMessage}}</span>
                    </md-field>
                    <md-checkbox class="ebx-checkbox" v-model="isUpload" v-if="!forceUpload">
                        <div class="ebx-primary-bold">Save file to Assets</div>
                        <div class="ebx-primary">Recommended for large or complex areas, or areas that are used across multiple projects.</div>
                    </md-checkbox>
                </div>
            </md-content>
        </md-dialog-content>
        <md-dialog-actions>
            <button class="ebx-button ebx-button--secondary" @click="cancelUpload">
                Cancel
            </button>
            <button class="ebx-button ebx-button--spinner" @click="checkFileName(filesToUpload[0].name); startUpload();" :class="isLoading ? 'ebx-button--spinner__disabled' : (!isClean || isUserError || isLoading || filesToUpload.length === 0 ? 'ebx-button--primary__disabled' : 'ebx-button--primary')">
                <md-progress-spinner v-show="isLoading" class="md-accent" md-mode="indeterminate" :md-diameter="20" :md-stroke="2"></md-progress-spinner>
                {{isLoading ? 'Loading' : 'Upload'}}
            </button>
        </md-dialog-actions>
    </md-dialog>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import {
    getGeoJson,
    csvToGeoJson
    } from "@/scripts/ShapefileHandlers.js";
import {MAP_AREA_DEFAULT_SPLIT_PROPERTY, } from "../../constants/nextGenConstants";
import { CsvService } from "@/services/csv.service.js";
import moment from 'moment';
import {supportedProjections} from "@/constants/supportedProjections";
import valueMixin from "@/components/mixins/valueMixin";

export default {
    name: "GoogleMapPolygonUploadFileSelection",
    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
        addToMap: {
            type: Boolean,
            default: false
        },
        clearFiles: {
            type: Boolean,
            default: false
        },
        uploadError: {
            type: Boolean,
            default: false
        },
        uploadErrorMessage: {
            type: String,
            default: ""
        },
        uploadInProgress: {
            type: Boolean,
            default: false
        },
        forceUpload: {
            // hides the checkbox and forces users to upload to long term
            type: Boolean,
            default: false,
            required: false
        },
        hideSplittingFeatures: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        'new-cached-geojson',
        'dialog-closed',
        'cancel-upload',
        'start-upload',
        'clear-upload-error',
        'update:modelValue'
    ],
    mixins: [
        valueMixin
    ],
    data() {
        return {
            acceptedTypes: "",
            allowedExtensions:['.json','.geojson','.kml','.kmz','.zip', '.csv'],
            specialHandlingExtensions: ['.csv'],
            availableSplitProperties: [],
            cachedGeoJSON: null,
            fileErrorMessage: "",
            filesToUpload: [],
            isClean: false,
            isLoading: false,
            isUpload: false,
            isUserError: false,
            selectedSplitProperty: MAP_AREA_DEFAULT_SPLIT_PROPERTY,
            splitByCategory: false,
            cachedCsv: null,
            csvColumns: [],
            csvColumnsInfo: [],
            availableProjections: supportedProjections,
            selectedProjection: "",
            latColumn: "",
            lonColumn: "", 
            errorMessage: this.uploadErrorMessage, 
            polygonUploadError: this.uploadError,
            showColumns: false,
            showEbxConcertinaContent: false,
            csvDataTypes: {
                string: 'String',
                integer: 'Integer',
                decimal: 'Decimal',
                date: 'Date',
            }
        }
    },
    computed: {
        canSplitFeatureSet() {
            if(this.hideSplittingFeatures) {
                return false;
            }
            return (this.isUpload === false || this.addToMap === true) && this.availableSplitProperties.length > 0
        },
        showDialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
        showCsvFields() {
            return this.csvColumns.length > 0 && this.filesToUpload.length > 0
        }
    },
    watch: {
        clearFiles(newValue) {
            if (newValue) {
                this.filesToUpload = []
            }
        },
        uploadInProgress(newValue) {
            if (newValue) {
                this.isLoading = true
            } else {
                this.isLoading = false
            }
        },
        forceUpload(newValue) {
            if (newValue) {
                this.isUpload = true;
            }
        },
        // watch projection, lat and lon columns
        selectedProjection() {
            this.parseCsvToGeoJson();
        },
        latColumn() {
            this.parseCsvToGeoJson();
        },
        lonColumn() {
            this.parseCsvToGeoJson();
        },
        cachedGeoJSON(newValue) {
            this.$emit('new-cached-geojson', newValue)
        }, 
        showDialog() { 
            this.dialogClosed()
        }
    },
    mounted() {
        this.acceptedTypes = this.allowedExtensions.join(',')
    },
    methods: {
        /**
         * Emitters
         */
        dialogClosed() {
            this.isClean = false;
            this.isUserError = false;
            this.polygonUploadError = false;
            this.filesToUpload = [];
            this.availableSplitProperties = []
            this.isUpload = false;
            this.splitByCategory = false;
            this.selectedSplitProperty = MAP_AREA_DEFAULT_SPLIT_PROPERTY;
            this.cachedGeoJSON = null
            this.cachedCsv = null
            this.csvColumns = []
            this.latColumn = ""
            this.lonColumn = ""
            this.selectedProjection = ""
            this.$emit('dialog-closed')
        },
        cancelUpload() {
            this.filesToUpload = []
            this.$emit('cancel-upload')
        },
        startUpload() {

            //Set values from modal to be used down the line in the uploader
            var formData = {
                csvColumnsInfo: this.csvColumnsInfo,
                selectedProjection: this.selectedProjection,
                latColumn: this.latColumn,
                lonColumn: this.lonColumn
            }
            // if csv, we want to use our cached geojson to create a virtual geojson file and upload this instead with the same name bar the extension
            if (this.cachedCsv) {
                const file = this.filesToUpload[0];
                const fileName = this.getFileNameWithoutExtension(file.name);

                // front end requires geojson to be reversed i.e. lat, lon, but real geojson is lon, lat
                // so we must re-reverse it
                // reverse manually by mapping
                const reversedGeoJson = {
                    ...this.cachedGeoJSON,
                    features: this.cachedGeoJSON.features.map(feature => {
                        const coords = feature.geometry.coordinates;
                        const reversedCoords = [coords[1], coords[0]]

                        return {
                            ...feature,
                            geometry: {
                                ...feature.geometry,
                                coordinates: reversedCoords
                            }
                        }
                    })
                }

                const virtualGeoJsonFile = new File([JSON.stringify(reversedGeoJson)], `${fileName}.geojson`, {type: 'application/json'});
                
                //send both the original csv and the virtual geojson file
                this.$emit('start-upload', [virtualGeoJsonFile,this.filesToUpload[0]], this.isUpload, this.selectedSplitProperty, formData, true)
                this.isClean = false
                this.isUpload = false
                return;
            }


            this.$emit('start-upload', this.filesToUpload, this.isUpload, this.selectedSplitProperty, formData, false)
            this.isClean = false
            this.isUpload = false
        },
        /**
         * Drag and drop methods
         */
        triggerFileSelection() {
            this.polygonUploadError = false;
            this.$refs.fileInput.click();
        },
        dropHandler(event) {
            // Prevent default behavior (Prevent file from being opened)
            event.preventDefault();

            if (event.dataTransfer.items) {
                // Use DataTransferItemList interface to access the file(s)
                [...event.dataTransfer.items].forEach((item) => {
                    // If dropped items aren't files, reject them
                    if (item.kind === "file") {
                        this.handleFileChange(event, [item.getAsFile()]);
                    }
                });
            } else {
                // Use DataTransfer interface to access the file(s)
                [...event.dataTransfer.files].forEach((file) => {
                    this.handleFileChange(event, [file]);
                });
            }
        },
        dragOverHandler(event) {
            // Prevent default behavior (Prevent file from being opened)
            event.preventDefault();
        },
        /**
         * FE file upload methods
         */
        checkFileName(fileName) {
            if(fileName === ' ') {
                this.isClean = false;
                this.isUserError = true;
                this.fileErrorMessage = 'Please choose a file with a filename'
                this.filesToUpload = []
                return false;
            }
            return true;
        },
        checkFiles(event, files) {
            if (!files) {
                files = event.target.files;
            }

            // verify we are allowing file type
            if(files.length > 1) {
                console.error('Error -> too many files selected');
                this.isUserError = true;
                this.errorMessage = 'Please only select one file';
                return;
            }

            for (let i = 0; i < files.length; i++) {
                let file = files[i]
                let extension = this.getFileExtension(file.name);
                let fileName = this.getFileNameWithoutExtension(file.name);
                if (!this.allowedExtensions.includes(extension)) {
                    console.info('Extension check fails')
                    this.isClean = false;
                    this.isUserError = true;
                    // get file extension without the "." and make it uppercase
                    extension = extension.toUpperCase().replace('.', '')
                    this.fileErrorMessage = `${extension} files aren't supported. Choose a GeoJSON, KML, KMZ, CSV, or a zipped shape file.`
                } else if (!this.checkFileName(fileName)) {
                    return
                } else {
                    console.info('Extension check passes', file)
                    this.filesToUpload = [file]
                    this.$emit('clear-upload-error')
                    this.isClean = true;
                    this.isUserError = false;
                }
            }
        },

        handleFileChange(event, files) {
            this.checkFiles(event, files);

            const extensions = this.filesToUpload.map(file => this.getFileExtension(file.name));

            const specialCaseExtensions = extensions.filter(extension => this.specialHandlingExtensions.includes(extension));

            if (specialCaseExtensions.length > 0) {
                this.handleSpecialFileTypes(event, specialCaseExtensions[0])
                return;
            }

            this.parseShapeFileForFeaturesAndClasses(event);
        },

        handleSpecialFileTypes(event, extension) {
            switch(extension) {
                case '.csv':
                    this.handleCsv(event);
                    break;
                default:
                    console.error('No special handling for file type', extension);
            }
        },

        // eslint-disable-next-line no-unused-vars
        handleCsv(event) {
            const file = this.filesToUpload[0];

            CsvService.readLatLonColumnsFromCsv(file).then((response) => {
                this.csvColumns = response.headers;
                if (this.csvColumns.length === 0) {
                    this.loadingStatus = "Error"
                    this.isUserError = true;
                    this.fileErrorMessage = 'This CSV does not contain any data.';
                    this.filesToUpload = []
                    return;
                }
                this.csvColumnsInfo = this.getCsvColumnInfo(response);
                this.latColumn = response.latColumn || this.csvColumns[0];
                this.lonColumn = response.lonColumn || this.csvColumns[1];
                this.cachedCsv = response.results;
                this.selectedProjection = 'EPSG:4326';
            }).catch((error) => {
                console.error('Error reading CSV', error);
                this.loadingStatus = "Error"
                this.isUserError = true;
                this.errorMessage = 'Error reading CSV file. Please check the file and try again.';
            });
        },

        // get the information about the columns in the csv file
        getCsvColumnInfo(response) {
            let csvColumns = [];
            let csvColumnsKeys = [];
            let csvColumnsValues = [];
            try {
                if (response.results && response.results.length > 0) {
                    csvColumnsKeys = Object.keys(response.results[0]);
                    csvColumnsValues = Object.values(response.results[0]);
                    csvColumns = csvColumnsKeys.map((key, index) => {
                        return {key, value: this.getColumnType(csvColumnsValues[index])};
                    });
                    return csvColumns;
                }
            } catch {
                console.error('Error reading CSV: CSV has no rows');
                this.loadingStatus = "Error"
                this.isUserError = true;
                this.errorMessage = 'Error reading CSV file, no rows detected';
                return [];
            }
        },
        
        // get type of column data from the csv file column
        getColumnType(columnVal) {
            // check if the columnVal is empty/null/undefined
            if (columnVal === '' || columnVal === null || columnVal === undefined) {
                return this.csvDataTypes.string;
            }
            // check if it is a date
            // TODO : validation/discussion to enforce date format as ISO
            const isDate = moment(columnVal, moment.ISO_8601);
            if (isDate.isValid()) {
                return this.csvDataTypes.date;
            }
            // check if the columnVal is an integer
            if (!isNaN(columnVal) && Number.isInteger(parseFloat(columnVal))) {
                return this.csvDataTypes.integer;
            }
            // check if the columnVal is a decimal
            if (!isNaN(columnVal) && !Number.isInteger(parseFloat(columnVal))) {
                return this.csvDataTypes.decimal;
            }

            // if none of the above, return string
            return this.csvDataTypes.string;
        },
        parseCsvToGeoJson() {
            if (!this.cachedCsv) {
                return;
            }

            const geojson = csvToGeoJson(this.cachedCsv, this.latColumn, this.lonColumn, this.selectedProjection);
            this.cachedGeoJSON = geojson;
            const propertyKeys = Object.keys(this.cachedGeoJSON.features[0].properties);
            const propertyValues = Object.values(this.cachedGeoJSON.features[0].properties);
            this.csvColumnsInfo = propertyKeys.map((key, index) => {
                return {key, value: this.getColumnType(propertyValues[index])};
            });
            this.parseShapeFileForFeaturesAndClasses();
        },

        /**
         * 
         * @param {*} event 
         * @param {*} force - used to force parsing of files that may require special handling first
         */
        // eslint-disable-next-line no-unused-vars
        async parseShapeFileForFeaturesAndClasses(event) {
            this.availableSplitProperties = []

            if (!this.isClean) {
                return;
            }

            if (this.isUserError) {
                return;
            }

            if (this.isUpload) {
                return;
            }

            try {
                if (this.cachedGeoJSON === null || this.cachedGeoJSON === undefined) {
                    this.cachedGeoJSON = await getGeoJson(this.filesToUpload)
                }

                const geojson = this.cachedGeoJSON;
                
                if(Array.isArray(geojson.features) && geojson.features.length > 1) {
                    const feature = geojson.features[0]
                    if(feature.properties && typeof feature.properties === 'object') {
                        this.availableSplitProperties = Object.keys(feature.properties)
                    }
                }
            } catch(error) {
                console.error("Error parsing shapefile", error)
                this.loadingStatus = "Error"
                this.errorMessage = `${error}`
                this.polygonUploadError = true
                this.filesToUpload = []
            }
        },
        /**
         * Other methods
         */
        removeFile() {
            this.filesToUpload = []
            this.isUserError = false;
            this.fileErrorMessage = '';
        },
        getFileExtension(filename) { // can be moved into helpers library if needs to be used elsewhere
            return filename.substring(filename.lastIndexOf('.'), filename.length) || filename;
        },
        getFileNameWithoutExtension(filename) {
            return filename.substring(0, filename.lastIndexOf('.'));
        },
    }
}
</script>