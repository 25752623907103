<template>
  <div>
    <h1>
      Organisations
      <md-button @click="addOrganisation" class="md-fab md-mini md-accent">
        <md-icon>add</md-icon>
      </md-button>
    </h1>

    <md-list class="md-double-line">
      <md-list-item v-for="organisation in organisations" :key="organisation.id">
        <md-icon class="md-primary">business</md-icon>

        <div class="md-list-item-text">
          <span>{{ organisation.name }}</span>
          <span>{{ organisation.id }}</span>
        </div>

        <md-button v-if="isSuperAdmin" class="md-icon-button md-list-action" @click="editOrganisation(organisation)">
          <md-icon>edit</md-icon>
        </md-button>
      </md-list-item>
    </md-list>

    <OrganisationListAddEditOrganisation
      ref="addOrganisationDialog"
      @organisation-save="onOrganisationAdd"
    />
    <OrganisationListAddEditOrganisation
      ref="editOrganisationDialog"
      @organisation-save="onOrganisationEdit"
    />

    <md-snackbar
      :md-position="'center'"
      :md-duration="10000"
      v-model:md-active="showSnackbar"
      md-persistent
    >
      <span>{{ message }}</span>
    </md-snackbar>
  </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import { organisationsCollection } from "@/firebase.js";
import { getDoc, getDocs, setDoc, addDoc, updateDoc, collection, doc as firestoreDoc, query } from "firebase/firestore";
import OrganisationListAddEditOrganisation from "@/components/OrganisationListAddEditOrganisation.vue";
import authMixin from '@/components/mixins/authMixin.js';

export default {
  name: "OrganisationList",
  mixins: [authMixin],
  components: {
    OrganisationListAddEditOrganisation
  },
  data: () => ({
    organisations: null,
    showSnackbar: false,
    message: ""
  }),
  methods: {
    getOrganisation(doc) {
      return new Promise((resolve, reject) => {
        // collect the terms and conditions properties for each org
        getDocs(query(collection(doc.ref, 'termsandconditions'))).then(versions => {
          // need these to store the data, and empty them before the forEach loop to avoid duplication
          let additionalData = {};
          let docData = {};
          let termsandconditions_versions = [];
          let termsandconditions_current = '';
          // for each doc in termsandconditions    
          versions.docs.forEach(function(item) {
            // if it's a version doc store the data in an object
            if (item.id !== 'current') {
              let versionData = {
                'id': item.id,
                'reference': item.data().reference
              };
              termsandconditions_versions.push(versionData);
            }
            // if it's the doc poitnint to the current T&C version store in a variable
            else if (item.id === 'current') {
              termsandconditions_current = item.data().ref.id;
            }
          });
          // build the additional data object to be added to the org doc
          additionalData = {
            'termsandconditions_versions': termsandconditions_versions,
            'termsandconditions_current': termsandconditions_current
          }
          // concat 2 objects into docData which is the org data
          docData = {...doc.data(), ...additionalData};
          docData.id = doc.id;
            resolve(docData);
          }, reject);
      });
    },
    async getOrganisations() {
        const querySnapshot = await getDocs(query(organisationsCollection))
        let organisations = [];
        querySnapshot.forEach(doc => {
          organisations.push(this.getOrganisation(doc))
        })
        this.organisations = await Promise.all(organisations); 
    },
    addOrganisation() {
      this.$refs.addOrganisationDialog.showDialog();
    },
    editOrganisation(organisation) {
        // deep clone of the object because it contains an array
        let organisationClone = JSON.parse(JSON.stringify(organisation));
        this.$refs.editOrganisationDialog.showDialog(organisationClone);
    },
    prepareOrganisationRequest(organisation) {
      return {
        name: organisation.name,
        sector: organisation.sector,
        type: organisation.type,
        maxUsers:
          !isNaN(parseInt(organisation.maxUsers))
            ? parseInt(organisation.maxUsers)
            : null,
        maxExplorerUsers:
          !isNaN(parseInt(organisation.maxExplorerUsers))
            ? parseInt(organisation.maxExplorerUsers)
            : null,
        defaultGeoTIFFDownloadQuota: organisation.defaultGeoTIFFDownloadQuota != null
            ? parseInt(organisation.defaultGeoTIFFDownloadQuota)
            : null,
        defaultVectorDownloadQuota: organisation.defaultVectorDownloadQuota != null
            ? parseInt(organisation.defaultVectorDownloadQuota)
            : null,
        enableTermsAndConditions: organisation.enableTermsAndConditions,
        contactName: organisation.contactName,
        contactEmail: organisation.contactEmail,
        accessType: organisation.accessType,
        stripeIntegrationActive: 
          organisation.stripeIntegrationActive == null
          ? false
          : organisation.stripeIntegrationActive
      };
    },
    /**
     * This function updates the terms and conditions setting for an organisation
     * This will update the current T&Cs version, and/or add a new T&C version and set it as the current, if there is one.
     * After the firestore is updated, we also need to call getOrganisations which fetches the organisations and their settings from firestore after we update them, and stores them locally.
     */
    updateTerms(orgSnapshot, organisation) {
        if (!organisation.enableTermsAndConditions) return
        let orgRef = orgSnapshot.ref;
        // At some point in this component we seem to add a temporary property termsandconditions_versions to the organisation doc.
        // Here we extract that and process it.
        for(let i = 0 ; i < organisation.termsandconditions_versions.length ; i++) {
            // The version doc is a simple object with an id and a reference
            let version = organisation.termsandconditions_versions[i];

            console.log("version:" + version.id) ;
            // set or create version document for this version
            const versionDocRef = firestoreDoc(orgRef, "termsandconditions", version.id)
            setDoc(versionDocRef, {"reference": version.reference}).then(
                () => { 
                    // get the version document and compare to current 
                    getDoc(versionDocRef).then((versiondoc) => {
                        console.log("set or created termsandconditions entry for version:" + versiondoc.id ) ;
                        // if version doc path is same as the current 
                        if(versiondoc.id == organisation.termsandconditions_current)
                        {   
                            const currentDocRef = firestoreDoc(orgRef, "termsandconditions", "current")
                            setDoc(currentDocRef, {"ref": versiondoc.ref}).then(() => {
                                console.log("set current as " + versiondoc.id);
                                this.getOrganisations(); 
                            }
                        );
                        } // if version is current
                    
                    }) ;
                }) // set the version
            } //for each version

            this.getOrganisations(); 

        //set new terms reference if there is one and set it as current T&C
        if (organisation.newTermsRef !== "" && organisation.newTermsRef) {
            let newTermsVersion = (organisation.termsandconditions_versions.length + 1) + '.0';
            
            // set new document with new terms version
            const newVersionDocRef = firestoreDoc(orgRef, "termsandconditions", newTermsVersion)
            setDoc(newVersionDocRef, { "reference":organisation.newTermsRef }).then(() => {
                // get document we just created
                getDoc(newVersionDocRef).then((newVersion) => {
                    // set new doc and the current T&C version
                    const currentDocRef = firestoreDoc(orgRef, "termsandconditions", "current")
                    setDoc(currentDocRef, {"ref": newVersion.ref}).then(() => {
                        console.log("set NEW current as " + newVersion.id);
                        this.getOrganisations(); 
                    });
                });
            });
        }
    },
    onOrganisationAdd(organisation) {
      let self = this;
      let handlePromise = promise => {
        promise
            .then((orgRef) => {
                if(orgRef) // orgRef only passed if handlePromise(addDoc(organisationsCollection, request)) was called
                {
                    organisation.id = orgRef.id ; //auto generated id
                }

                let orgReference = firestoreDoc(organisationsCollection, organisation.id);
                getDoc(orgReference).then(orgSnapshot => {
                    this.updateTerms(orgSnapshot, organisation);
                    this.getOrganisations();
                });
                
                self.message = `Organisation '${organisation.name}' successfully added`;
                self.showSnackbar = true;
            })
            .catch(error => {
                console.error(error);
                self.message = `Could not add the organisation. Error occured: '${error}'`;
                self.showSnackbar = true;
            });
      };

      let request = this.prepareOrganisationRequest(organisation);
      if (organisation.id) {
        let orgReference = firestoreDoc(organisationsCollection, organisation.id);
        getDoc(orgReference).then(orgSnapshot => {
          if (orgSnapshot.exists()) {
            this.message = `Could not add the organisation. Error occured: 'Organisation with this id already exists'`;
            this.showSnackbar = true;
            return;
          }

          handlePromise(setDoc(orgReference, request));
        });
      } else {
        handlePromise(addDoc(organisationsCollection, request));
      }
    },
    onOrganisationEdit(organisation) {
      let orgReference = firestoreDoc(organisationsCollection, organisation.id);
      let request = this.prepareOrganisationRequest(organisation);
      updateDoc(orgReference, request)
        .then(() => {
            getDoc(orgReference).then(orgSnapshot => {
                this.updateTerms(orgSnapshot, organisation);
            });
            this.getOrganisations();
            this.message = `Organisation '${organisation.name}' successfully updated`;
            this.showSnackbar = true;
        })
        .catch(error => {
            console.error(error);
            this.message = `Could not update the organisation. Error occured: '${error}'`;
            this.showSnackbar = true;
        });
    }
  },
  created: function() {
    this.getOrganisations();
  }
};
</script>

<style scoped lang="scss">
.md-fab {
  float: right;
  margin-top: -9px;
}
</style>
