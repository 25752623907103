import { CONFIG_TYPE } from "@/config/constants.js";
import { usersCollection, organisationsCollection, sharedCollection } from "@/firebase.js";
import { doc as firestoreDoc } from "firebase/firestore";
import { EBX_CORE_CONFIG } from "../../constants/appConstants";

export default {
    computed: {
        isLoggedIn() {
            return (this.user && this.userOrg && this.orgConfig) ? true : false
        },
        isAdmin() {
            return this.$store.getters['auth/isAdmin']
        },
        isOrgGroupAdmin() {
            return this.$store.getters['auth/isOrgGroupAdmin']
        },
        isCustomerSupport() {
            return this.$store.getters['auth/isCustomerSupport']
        },
        userAdminOfGroups() {
            return this.$store.getters['auth/userAdminOfGroups']
        },
        isSuperAdmin() {
            return this.$store.getters['auth/isSuperAdmin']
        },
        isCreator() { 
            return this.$store.getters['auth/isCreator']
        },  
        isExplorer() {
            return this.$store.getters['auth/isExplorer']
        },
        isCommercialUser() { 
            return this.$store.getters['auth/isCommercialUser']
        },
        orgGetVisibleDatasets() {
            return this.$store.getters['auth/orgGetVisibleDatasets']
        },
        userTermsAgreed() {
            return this.user && this.user.termsAgreed === true
        },
        userVariablesEnabled() {
            return this.$store.getters['auth/userVariablesEnabled'] || this.isExplorer
        },
        orgType() {
            return this.$store.getters['auth/getOrgType']
        },
        enableTermsAndConditions() {
            return this.$store.getters['auth/enableTermsAndConditions']
        },
        orgCustomisations() {
            return this.orgConfig ? this.orgConfig.getConfig(CONFIG_TYPE.CUSTOMISATION) : undefined;
        },
        user() {
            return this.$store.state.auth.user
        },
        userOrg() {
            return this.$store.state.auth.org
        },
        orgCoreConfig() {
            const orgEbxCoreConfig = this.userOrg.ebxCoreConfig || {}
            const validKeys = Object.keys(EBX_CORE_CONFIG)
            const configKeys = Object.keys(orgEbxCoreConfig)
            const validConfig = {}
            configKeys.forEach(key => {
                if(validKeys.includes(key)) {
                    validConfig[key] = orgEbxCoreConfig[key]
                }
            })
            return Object.assign({}, EBX_CORE_CONFIG, validConfig)
        },
        orgConfig() {
            return this.$store.state.auth.orgConfig
        },
        orgRef() {
            return this.user.orgId ? firestoreDoc(organisationsCollection, this.user.orgId) : null;
        },
        userRef(){
            return this.user.uid ? firestoreDoc(usersCollection, this.user.uid) : null;
        },
        sharedCollectionRef() {
            return firestoreDoc(sharedCollection, this.$store.getters['auth/getWorkflowTemplateCollection'])
        },
        gmpEntitled() {
            return this.user.gmpEntitled
        },
        userIsForcingMode() {
            return this.$store.state.auth.userModeOverride !== null
        },
        userForcedExplorer: {
            get() {
                return (this.$store.state.auth.userModeOverride || 'creator') === 'explorer'
            },
            set(value) {
                if(value === null) {
                    this.$store.commit('auth/setUserModeOverride', null)
                    return
                }
                this.$store.commit('auth/setUserModeOverride', value === false ? 'creator' : 'explorer')
            }
        }
    }
}