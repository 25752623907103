import * as Blockly from "blockly/core";
import { AreaService } from '../../services/area.service';
import { DatasetService } from '../../services/dataset.service';
import { VariablesService } from '../../services/variable.service';
import { GA_EVENT_PROPS } from "@/constants/gaConstants.js";
import { MAP_STUDY_AREA_COLLECTION_ID, WORKFLOW_SAVED_TYPES, SAVED_WORKFLOW_ID_SEPERATOR, VARIABLE_PROVIDERS } from '../../constants/nextGenConstants';
import { doc as firestoreDoc, collection as firestoreCollection, getDoc, getDocs, addDoc, updateDoc, deleteDoc, query, where, serverTimestamp } from "firebase/firestore";
import { functions } from "@/firebaseFunctions.js";
import { RegistryService } from "../../services/registry.service";
import { AuthService } from '@/services/auth.service';
import { getWorkflowDatasetsIds } from '@/components/mixins/workflowMixin.js';
import { globalEventBus } from "@/eventbus";

export const FORM_STATE = {
    DEFAULT: 'default',
    ERROR: 'error',
    SAVING: 'saving',
    SAVED: 'saved'
}

export const PROJECT_MODE = {
    PRIVATE: 'private',
    READONLY: 'readonly',
    EDIT: 'edit',
    GLOBAL_TEMPLATE: 'template',
    ORG_TEMPLATE: 'team_published'
}

export const PROJECT_STATE = {
    NEW: 'new',
    CHANGED: 'changed',
    SAVED: 'saved',
    DUPLICATE: 'duplicate',
    DETAIL_UPDATE: 'detail_update',
    PERMISSION_UPDATE: 'permission_update',
}

const computedStore = function (varName) {
    return {
        get() {
            return this.$store.state.project[varName]
        },
        set(value) {
            this.$store.commit('project/setState', {
                key: varName,
                value
            })
        }
    }
}
const savedComputedStore = function (varName) {
    return {
        get() {
            return this.$store.state.project.saveProjectPrompt[varName]
        },
        set(value) {
            this.$store.commit('project/setSaveProjectPromptState', {
                key: varName,
                value
            })
        }
    }
}

/**
 * Recursively remove keys from the check of an saved workflw
 * @param {*} state 
 * @returns object without ignoreKeys
 */
const removeDataForSaveCheck = function (state, ignoreKeys = ['extraState', 'gee:classes']) {
    if (state === null) {
        return null
    }
    if (Array.isArray(state)) {
        return state.map(item => removeDataForSaveCheck(item, ignoreKeys))
    }
    if (typeof state !== 'object') {
        return state
    }
    let newState = Object.assign({}, state)
    Object.keys(newState).forEach(key => {
        if (ignoreKeys.includes(key)) {
            delete newState[key]
        }
        if (Array.isArray(newState[key])) {
            newState[key] = newState[key].map(item => removeDataForSaveCheck(item, ignoreKeys))
        }else if (typeof newState[key] === 'object') {
            newState[key] = removeDataForSaveCheck(newState[key], ignoreKeys)
        }
    })

    return newState
}

/**
 * Recursively order object keys needed for comparison of saved workflows
 * when adding areas or variable sometimes the order of the keys changes 
 * this will make the object keys ordered alphabetically and hopefully make the comparison more reliable
 * - Can accept any type only objects will be ordered alphabetically
 * @param {*} obj 
 * @returns {object}
 */
const orderByObjectKeys = function (obj) {
    if (obj === null) {
        return null
    }
    if (Array.isArray(obj)) {
        return obj.map(item => orderByObjectKeys(item))
    }
    if (typeof obj !== 'object') {
        return obj
    }
    return Object.keys(obj).sort().reduce((acc, key) => {
        if (Array.isArray(obj[key])) {
            acc[key] = obj[key].map(item => orderByObjectKeys(item))
        }else if (typeof obj[key] === 'object' && obj[key] !== null) {
            acc[key] = orderByObjectKeys(obj[key])
        } else {
            acc[key] = obj[key]
        }
        return acc
    }, {})
}

/**
 * Error Exception to detect changes on error
 */
class ProjectChangedError extends Error { }

/**
 * Shared workflow mixin
 */
export const sharedWorkflowMixin = {
    created() {
        // this dispatch checks organisation properties - for now just whethere it's a 'individual' organisation or not
        if (this.user === null || this.user.uid === null) {
            AuthService.loggedUser$.subscribe(user => {
                if(user !== null && user.uid !== null) {
                    this.$store.dispatch('organisations/checkOrganisationStatus', user.orgId);
                }
            });
        }else {
            this.$store.dispatch('organisations/checkOrganisationStatus', this.user.orgId);
        }
    },
    computed: {
        isOwnerOfProject() {
            return this.projectOwnerUid === null || this.projectOwnerUid === this.user.uid || this.projectMode === PROJECT_STATE.PRIVATE
        },
        needToConfirmProjectSaveOverwrite() {
            if(this.projectIsOrgTemplate) { 
                return true
            }
            if(this.isSuperAdmin && this.projectIsGlobalTemplate) {
                return true
            }
            if (this.isAdmin && this.isOwnerOfProject === false && this.projectMode === PROJECT_MODE.READONLY && this.projectState === PROJECT_STATE.CHANGED) {
                return true
            }
            return this.isOwnerOfProject === false && this.projectMode === PROJECT_MODE.EDIT && this.projectState === PROJECT_STATE.CHANGED
        },
        projectIsSaved() {
            return this.projectState === PROJECT_STATE.SAVED || (this.projectIsLoaded && this.projectState === PROJECT_STATE.CHANGED)
        },
        projectIsNew() {
            return this.projectState === PROJECT_STATE.NEW
        },
        projectIsEditable() {
            return this.isOwnerOfProject || this.isAdmin || this.projectMode === PROJECT_MODE.EDIT
        },
        projectIsGlobalTemplate() {
            return this.projectMode === PROJECT_MODE.GLOBAL_TEMPLATE
        },
        projectIsOrgTemplate() {
            return this.projectMode === PROJECT_MODE.ORG_TEMPLATE
        },
        projectIsPrivate() {
            return this.projectMode === PROJECT_MODE.PRIVATE
        },
        projectHasVariables() {
            return this.projectIsLoaded && Array.isArray(this.projectVariables) && this.projectVariables.length > 0
        },
        projectIsLoaded() {
            return this.projectId !== null
        },
        projectIsChanged() { 
            return this.projectState === PROJECT_STATE.CHANGED
        },
        projectIsReadOnly() {
            if (!this.isOwnerOfProject && !this.projectIsPrivate && !this.projectIsEditable) {
                return true
            } else if (this.isAdmin || this.isSuperAdmin) {
                return false
            } else { 
                return false
            }
        },
        canShareWorkflows() {
            return !this.$store.state.organisations.isIndividual && this.isCreator;
        },
        canViewSharedOrgWorkflows() {
            return !this.$store.state.organisations.isIndividual;
        },
        projectId: computedStore('projectId'),
        projectState: computedStore('projectState'),
        projectMode: computedStore('projectMode'),
        projectOwnerUid: computedStore('projectOwnerUid'),
        projectName: computedStore('projectName'),
        projectDescription: computedStore('projectDescription'),
        projectLoadedDefinitionAndArea: computedStore('projectLoadedDefinitionAndArea'),
        projectUpdatedAt: computedStore('projectUpdatedAt'),
        projectThumbnail: computedStore('projectThumbnail'),
        projectChanged: computedStore('projectChanged'),
        projectApiAccess: computedStore('projectApiAccess'),
        projectApiVersion: computedStore('projectApiVersion'),
        projectVariables: computedStore('projectVariables'),
        projectProvenance: computedStore('projectProvenance'),
        projectSettings: computedStore('projectSettings'),
        projectWorkflow() {
            return this.getProjectWorkflow()
        },
        projectToBeLoaded() {
            return this.$store.state.project.projectToBeLoaded
        },
    },
    methods: {
        getProjectWorkflow(extraData={}) {
            return this.$store.getters['project/getWorkflow'](extraData)
        },
        async setWorkflow(workflow, mode = null, toBeLoaded = false) {
            if(typeof workflow.data === 'function') {
                workflow =  {
                    id: workflow.id,
                    ...workflow.data()
                }
            }
            if(mode === null) { 
                mode = workflow.mode || PROJECT_MODE.PRIVATE
            }
        
            const workflowPayload = {
                workflow: workflow, 
                projectMode: mode,
                projectToBeLoaded: toBeLoaded
            }

            this.$store.commit('project/setWorkflowAndMode', workflowPayload)
        },
        changeProjectState(newState) {
            this.projectState = newState
        },
        hasProjectChanged() {
            if (this.projectIsLoaded === false) {
                return true
            }

            if (this.projectState === PROJECT_STATE.NEW) {
                return true
            }

            // check for projectSettings changes vs the defaults
            const currentProjectSettings = JSON.stringify(this.projectSettings)
            const defaultProjectSettings = JSON.stringify(this.saveProjectDefaults.projectSettings)
            if (currentProjectSettings !== defaultProjectSettings) {
                return true
            }

            // Blocks check
            const currentWorkflowState = this.getProjectAsSerialisedObject()
            if(!currentWorkflowState) {
                return false
            }

            // Blocks check
            if (currentWorkflowState.blockDefinition !== undefined) {
                const currentWorkflowDef = JSON.stringify(orderByObjectKeys(removeDataForSaveCheck(JSON.parse(currentWorkflowState.blockDefinition))))
                const loadedWorkflowDef = JSON.stringify(orderByObjectKeys(removeDataForSaveCheck(JSON.parse(this.projectLoadedDefinitionAndArea.blockDefinition))))
                if(currentWorkflowDef !== loadedWorkflowDef) {
                    return true
                }
            }
            
            // Area check
            if(currentWorkflowState.userAreas && this.projectLoadedDefinitionAndArea.userAreas) {
                const currentWorkflowAreas = JSON.stringify(orderByObjectKeys(JSON.parse(currentWorkflowState.userAreas)))
                const loadedWorkflowAreas = JSON.stringify(orderByObjectKeys(JSON.parse(this.projectLoadedDefinitionAndArea.userAreas)))
                const areasChanged = currentWorkflowAreas !== loadedWorkflowAreas
                if(areasChanged) {
                    return true
                }
            }

            // Variables check
            const currentVariables = JSON.stringify(orderByObjectKeys(currentWorkflowState.variables))
            const loadedVariables = JSON.stringify(orderByObjectKeys(this.projectVariables))
            const variablesChanged = currentVariables !== loadedVariables
            if(variablesChanged){
                return true
            }

            return false
        },
        resetProject() {
            this.$store.commit('project/clearSavedWorkflow')
        },
        performWorkspaceInsertionUsingString(workspace, workspaceAsString) {
            // replace old workflow seperator with new one
            workspaceAsString = workspaceAsString.replaceAll('____', SAVED_WORKFLOW_ID_SEPERATOR)

            const workflowImportType = this.getSavedWorkflowType(workspaceAsString)
            if (workflowImportType === WORKFLOW_SAVED_TYPES.XML) {
                Blockly.Xml.clearWorkspaceAndLoadFromXml(
                    Blockly.utils.xml.textToDom(workspaceAsString),
                    workspace
                );
                return
            }
            if (workflowImportType === WORKFLOW_SAVED_TYPES.JSON) {
                const workflowSavedObject = JSON.parse(workspaceAsString)
                RegistryService.setLoadingWorkflow(true)
                Blockly.serialization.workspaces.load(workflowSavedObject, workspace)
                RegistryService.setLoadingWorkflow(false)
                return
            }
            throw new Error('Workflow must be JSON or XML')
        },
        getProjectAsSerialisedObject(ignoreKeys = null) {
            if (Blockly.getMainWorkspace() === undefined) {
                return {}
            }
            let blockObjectDef = Blockly.serialization.workspaces.save(Blockly.getMainWorkspace())
            if (ignoreKeys !== null) {
                blockObjectDef = removeDataForSaveCheck(blockObjectDef, ignoreKeys)
            }
            const blockDefinition = JSON.stringify(blockObjectDef)
            const userAreasIds = AreaService.getUserAreas().map(a => a[1]);
            const userAreas = JSON.stringify(AreaService.getAreasAndShapesForRunDoc(userAreasIds))
            const variables = VariablesService.getProviderByScope(VARIABLE_PROVIDERS.PROJECT.id).toJSONForSave()
            return { blockDefinition, userAreas, variables }
        }
    }
}

/**
 * Load workflow mixin
 */
export const loadWorkflowMixin = {
    data() {
        return {
            loadProjectPrompt: {
                isActive: false,
                showBlankItem: false
            }
        }
    },
    methods: {
        loadWorkflow() {
            this.loadProjectPrompt.showBlankItem = false;
            this.loadProjectPrompt.isActive = true;
        },
        projectHasMapData(projectAreas) {
            if (projectAreas === undefined || projectAreas === null) {
                return false
            }
            try {
                projectAreas = JSON.parse(projectAreas)
            } catch (e) {
                console.error(e)
                return false
            }
            if (projectAreas.areas && projectAreas.areas.length > 0) {
                return true;
            }
            if (projectAreas.collections && projectAreas.collections.length > 0) {
                return true;
            }
            if (projectAreas.shapes && projectAreas.shapes.length > 0) {
                return true;
            }
            return false
        },

        async onLoadWorkflow(workflow, projectMode, variableProvider) {
            const workflowId = workflow.id
            let props = {};
            props[GA_EVENT_PROPS.NAME] = workflowId;

            const workspace = Blockly.getMainWorkspace();

            // Does current project exist or is it saved? if not confirm overwrite
            if (workspace.getTopBlocks().length > 0 && this.projectState !== PROJECT_STATE.SAVED) {
                const confirmed = await this.$refs.confirmClear.confirm();
                if (confirmed) {
                    this.clearWorkflow(true)
                } else {
                    return
                }
            }

            try {
                let docRef, doc;

                switch (projectMode) {
                    case PROJECT_MODE.PRIVATE: 
                        docRef = firestoreDoc(this.userRef, "workflows", workflowId);
                        doc = await getDoc(docRef);
                        if (doc.exists()) {
                            this.projectOwnerUid = this.user.uid
                            this.projectMode = PROJECT_MODE.PRIVATE
                        }
                        break;
                    case PROJECT_MODE.EDIT:
                    case PROJECT_MODE.READONLY:
                        docRef = firestoreDoc(this.orgRef, "workflows", workflowId);
                        doc = await getDoc(docRef);
                        if (doc.exists()) {
                            this.projectOwnerUid = doc.data().uid
                            this.projectMode = doc.data().mode || PROJECT_MODE.READONLY
                        }
                        break;
                    case PROJECT_MODE.GLOBAL_TEMPLATE:
                        docRef = firestoreDoc(this.sharedCollectionRef, "workflows", workflowId);
                        doc = await getDoc(docRef);
                        this.projectMode = PROJECT_MODE.GLOBAL_TEMPLATE
                        break;
                    case PROJECT_MODE.ORG_TEMPLATE:
                        docRef = firestoreDoc(this.orgRef, "published_workflows", workflowId);
                        doc = await getDoc(docRef);
                        this.projectMode = PROJECT_MODE.ORG_TEMPLATE
                        break;
                }

                if (!doc || !doc.exists()) {
                    console.error( `Workflow '${workflowId}' does not exist!`)
                    // this.errorDialog.message = `Workflow '${workflowId}' does not exist!`;
                    // this.errorDialog.visible = true;
                    return;
                }


                let overwriteUserAreas = true

                const workflowDataString = doc.data().blockDefinition;
                const projectAreas = doc.data().userAreas;
                this.setWorkflow(doc, null, true)

                const hasPinnedAreas = AreaService.getHasPinnedAreas();

                if (hasPinnedAreas && projectAreas && projectAreas.length > 0) {
                    // trigger Global confirmation dialog
                    const message = `This project has areas saved to the map. Would you like to keep your pinned areas or unpin them and use the areas in this project?`
                    const confirmedKeepPinned = await this.$confirmModal(message ,{
                        title: 'Project areas',
                        okButtonText: 'Keep pinned areas',
                        cancelButtonText: 'Unpin areas',
                        ifWarning: false
                    })
                    if (confirmedKeepPinned === false) {
                        overwriteUserAreas = true
                        AreaService.setHasPinnedAreas(false)
                    } else {
                        overwriteUserAreas = false
                    }
                }

                await Promise.all([
                    DatasetService.loadDatasetList(this.user.orgId),
                    this.userDatasetsLoad(this.user.orgId, this.user.uid),
                ])

                // Load the variables into the workspace
                if(variableProvider && this.projectVariables && this.projectVariables.length > 0) {
                    variableProvider.fromJSONForSave(this.projectVariables)
                }

                // load on map projectAreas first
                if (overwriteUserAreas) {
                    const addedAssets = await this.loadProjectAreasOnMap(projectAreas, projectMode)
                    if(VariablesService.getUserType() === 'explorer' && addedAssets?.areas.length > 0) {
                        addedAssets.areas.forEach(id => AreaService.setVisiblityForId(id, false))
                    }
                }

    
                this.performWorkspaceInsertionUsingString(workspace, workflowDataString)

                this.loadProjectPrompt.isActive = false

            } catch (error) {
                console.error(error)
                // this.errorDialog.message = `Error while loading workflow '${workflowId}'. Error: ${error}`;
                // this.errorDialog.visible = true;
                this.clearWorkflow(true)
                Blockly.Events.enable()
            }
        },
        async onPastedWorkflow(xml) {
            // TODO validate xml
            let workspace = Blockly.getMainWorkspace();
            try {
                await Promise.all([
                    DatasetService.loadDatasetList(this.user.orgId)
                ])
                this.performWorkspaceInsertionUsingString(workspace, xml)
            } catch {
                // this.errorDialog.message = `Error while running pasted workflow. Error: ${error}`;
                // this.errorDialog.visible = true;
            }
        },
        zoomToFirstArea(area) {
            globalEventBus.$emit('zoom-to-area', area)
        }
    }
}

/**
 * Save workflow mixin
 */
export const saveWorkflowMixin = {
    computed: {
        saveProjectPromptIsActive: savedComputedStore('isActive'),
        saveProjectFormState: savedComputedStore('formState'),
        saveProjectProjectState: savedComputedStore('projectState'),
        saveProjectSaving: savedComputedStore('saving'),
        saveProjectForceUpdateSave: savedComputedStore('forceUpdateSave'),
        saveProjectClearLoadedWorkflowOnSave: savedComputedStore('clearLoadedWorkflowOnSave'),
        saveProjectDefaults: savedComputedStore('defaults'),
        saveProjectMoveRequiredAssets: savedComputedStore('moveRequiredAssets'),
        saveProjectSettingsIsActive: savedComputedStore('settingsIsActive'),
    },
    methods: {
        loadedProjectWithChanges() {
            return Object.assign({}, this.projectWorkflow, this.getProjectAsSerialisedObject())
        },
        setProjectDefaults(data) {
            this.saveProjectDefaults = Object.assign({}, this.saveProjectDefaults, data)
        },
        constructWorkflowObjectForDefaults(workflow, extraData = {}) {
            const defaultData = {
                id: workflow.id,
                name: (workflow.name || workflow.id),
                description: workflow.description,
                thumbnail: workflow.thumbnail,
                mode: workflow.mode,
                api_access: workflow.api_access,
                api_version: workflow.api_version, 
                variables: workflow.variables,
                provenance: workflow.provenance,
                blockDefinition: workflow.blockDefinition || '{}',
                userAreas: workflow.userAreas || '{}',
                updatedAt: workflow.updatedAt || null
            }
            return Object.assign({}, defaultData, extraData)
        },
        async openProjectSaveModal(assetsRequiredToMove = []) {
            this.saveProjectMoveRequiredAssets = assetsRequiredToMove
            if (this.projectState !== PROJECT_STATE.SAVED) {
                this.saveProjectForceUpdateSave = false
                this.saveProjectProjectState = this.projectState

                if (this.needToConfirmProjectSaveOverwrite) {
                    const createCopy = (await this.$refs.confirm.confirm())
                    this.saveProjectProjectState= this.projectState
                    if (createCopy) {
                        return this.onDuplicateWorkflow(this.loadedProjectWithChanges())
                    }
                }
                
                if (this.projectMode === PROJECT_MODE.ORG_TEMPLATE && (this.isAdmin === false || this.isOwnerOfProject === false)) {
                    // force back to private mode if not admin or owner
                    this.setProjectDefaults({mode: PROJECT_MODE.PRIVATE})
                }
                
                if (this.projectMode === PROJECT_MODE.GLOBAL_TEMPLATE && this.isSuperAdmin === false) {
                    this.setProjectDefaults({mode: PROJECT_MODE.PRIVATE})
                }
                
                if (this.saveProjectProjectState === PROJECT_STATE.CHANGED) {
                    try {
                        await this.saveProjectSave(this.loadedProjectWithChanges(), PROJECT_STATE.CHANGED, true)
                    } catch (error) {
                        if (error instanceof ProjectChangedError) {
                            const createCopy = await this.$refs.confirm.confirm('This project has been changed by someone else in the organisation. Are you sure you want to overwrite the other persons changes?')
                            this.saveProjectProjectState = this.projectState
                            this.saveProjectFormState = FORM_STATE.DEFAULT
                            if (createCopy) {
                                this.saveProjectProjectState = PROJECT_STATE.NEW
                                if (this.isAdmin) {
                                    this.setProjectDefaults({mode: this.projectMode})
                                } else {
                                    this.setProjectDefaults({mode: PROJECT_MODE.PRIVATE})
                                }
                            } else {
                                this.saveProjectForceUpdateSave = true
                            }
                            this.saveProjectPromptIsActive = true;
                        } else {
                            console.error(error)
                        }
                    }
                } else {
                    this.saveProjectPromptIsActive = true;
                }

            }
        },
        onDuplicateWorkflow(workflow) {
            this.saveProjectProjectState = PROJECT_STATE.DUPLICATE
            this.setProjectDefaults({
                ...workflow,
                name: 'COPY OF ' + (workflow.name || workflow.id),
                mode: PROJECT_MODE.PRIVATE,
            })
            this.saveProjectPromptIsActive = true;
        },
        onEditWorkflow(workflow, state, clearWorkflowOnLoad = false) {
            this.saveProjectProjectState = state || PROJECT_STATE.DETAIL_UPDATE
            this.setProjectDefaults(workflow)
            this.saveProjectClearLoadedWorkflowOnSave = clearWorkflowOnLoad
            this.saveProjectPromptIsActive = true;
        },
        onEditProjectSettings(workflow) {
            this.setProjectDefaults(workflow)
            this.saveProjectSettingsIsActive = true;
        },
        async createPrivateProjectSave(documentDetails) {
            if (!this.userRef) {
                throw new Error('userRef was not found')
            }
            if(Object.hasOwn(documentDetails,'groups')) {
                delete documentDetails.groups
            }
            if(Object.hasOwn(documentDetails,'id')) {
                delete documentDetails.id
            }
            if(Object.hasOwn(documentDetails,'uid')) {
                delete documentDetails.uid
            }
            if(Object.hasOwn(documentDetails,'mode')) {
                delete documentDetails.mode
            }
            if(Object.hasOwn(documentDetails,'provenance')) {
                delete documentDetails.provenance
            }
            const docRef = await addDoc(firestoreCollection(this.userRef, "workflows"), documentDetails)
            const savedDocument = await getDoc(docRef);
            return savedDocument
        },
        async createSharedProjectSave(documentDetails) {
            if (!this.orgRef) {
                throw new Error('orgRef was not found')
            }
            if(Object.hasOwn(documentDetails,'id')) {
                delete documentDetails.id
            }
            if(Object.hasOwn(documentDetails,'provenance')) {
                delete documentDetails.provenance
            }
            const docRef = await addDoc(firestoreCollection(this.orgRef, "workflows"), documentDetails)
            const savedDocument = await getDoc(docRef);
            return  savedDocument
        },
        async createPublishedProjectSave(documentDetails) {
            if (!this.orgRef) {
                throw new Error('orgRef was not found')
            }
            if(Object.hasOwn(documentDetails,'id')) {
                delete documentDetails.id
            }
            const docRef = await addDoc(firestoreCollection(this.orgRef, "published_workflows"), documentDetails)
            const savedDocument = await getDoc(docRef);
            return savedDocument
        },
        async createTemplateProjectSave(documentDetails) {
            if (!this.sharedCollectionRef) {
                throw new Error('sharedCollectionRef was not found')
            }
            if(Object.hasOwn(documentDetails,'groups')) {
                delete documentDetails.groups
            }
            if(Object.hasOwn(documentDetails,'id')) {
                delete documentDetails.id
            }
            if(Object.hasOwn(documentDetails,'provenance')) {
                delete documentDetails.provenance
            }
            const docRef = await addDoc(firestoreCollection(this.sharedCollectionRef, "workflows"), documentDetails)
            const savedDocument = await getDoc(docRef);
            return savedDocument
        },
        async updateSharedProjectSave(documentDetails, projectId, ignoreUpdatedAtCheck = false) {
            if (!this.orgRef) {
                throw new Error('orgRef was not found')
            }
            if(Object.hasOwn(documentDetails,'id')) {
                delete documentDetails.id
            }
            if(Object.hasOwn(documentDetails,'provenance')) {
                delete documentDetails.provenance
            }
            const docRef = firestoreDoc(this.orgRef, "workflows", projectId)
            if (ignoreUpdatedAtCheck === false) {
                const savedDocument = await getDoc(docRef);
                if (savedDocument.data().updatedAt !== undefined && this.projectUpdatedAt !== null && savedDocument.data().updatedAt.seconds !== this.projectUpdatedAt.seconds) {
                    throw new ProjectChangedError('Project has been changed by someone else. Cannot update')
                }
            }
            await updateDoc(docRef, documentDetails)
            const updatedDocument = await getDoc(docRef);
            return updatedDocument
        },
        async updateTemplateProjectSave(documentDetails, projectId) {
            if (!this.sharedCollectionRef) {
                throw new Error('sharedCollectionRef was not found')
            }
            if(Object.hasOwn(documentDetails,'groups')) {
                delete documentDetails.groups
            }
            if(Object.hasOwn(documentDetails,'id')) {
                delete documentDetails.id
            }
            if(Object.hasOwn(documentDetails,'provenance')) {
                delete documentDetails.provenance
            }
            const docRef = firestoreDoc(this.sharedCollectionRef, "workflows", projectId)
            const savedDocument = await getDoc(docRef);
            if (savedDocument.data().updatedAt !== undefined && this.projectUpdatedAt !== null && savedDocument.data().updatedAt.seconds !== this.projectUpdatedAt.seconds) {
                throw new ProjectChangedError('Project has been changed by someone else. Cannot update')
            }
            await updateDoc(docRef, documentDetails)
            const updatedDocument = await getDoc(docRef);
            return updatedDocument
        },
        async updatePublishedProjectSave(documentDetails, projectId, ignoreUpdatedAtCheck = false) {
            if (!this.orgRef) {
                throw new Error('orgRef was not found')
            }
            if(Object.hasOwn(documentDetails,'id')) {
                delete documentDetails.id
            }
            const docRef = firestoreDoc(this.orgRef, "published_workflows", projectId)
            if (ignoreUpdatedAtCheck === false) {
                const savedDocument = await getDoc(docRef);
                if (savedDocument.data().updatedAt !== undefined && this.projectUpdatedAt !== null && savedDocument.data().updatedAt.seconds !== this.projectUpdatedAt.seconds) {
                    throw new ProjectChangedError('Project has been changed by someone else. Cannot update')
                }
            }
            await updateDoc(docRef, documentDetails)
            const updatedDocument = await getDoc(docRef);
            return updatedDocument
        },
        async updatePrivateProjectSave(documentDetails, projectId) {
            if (!this.userRef) {
                throw new Error('userRef was not found')
            }
            if(Object.hasOwn(documentDetails,'groups')) {
                delete documentDetails.groups
            }
            if(Object.hasOwn(documentDetails,'id')) {
                delete documentDetails.id
            }
            if(Object.hasOwn(documentDetails,'uid')) {
                delete documentDetails.uid
            }
            if(Object.hasOwn(documentDetails,'mode')) {
                delete documentDetails.mode
            }
            if(Object.hasOwn(documentDetails,'provenance')) {
                delete documentDetails.provenance
            }
            const docRef = firestoreDoc(this.userRef, "workflows", projectId)
            const savedDocument = await getDoc(docRef)
            if (savedDocument.data().updatedAt !== undefined && this.projectUpdatedAt !== null && savedDocument.data().updatedAt.seconds !== this.projectUpdatedAt.seconds) {
                throw new ProjectChangedError('Project has been changed by someone else. Cannot update')
            }
            await updateDoc(docRef, documentDetails)
            const updatedDocument = await getDoc(docRef)
            return updatedDocument
        },
        async deletePrivateProject(projectId) {
            if (!this.userRef) {
                throw new Error('userRef was not found')
            }
            const docRef = firestoreDoc(this.userRef, "workflows", projectId)
            return await deleteDoc(docRef)
        },
        async deleteSharedProject(projectId, currentUpdatedAtDate) {
            if (!this.orgRef) {
                throw new Error('orgRef was not found')
            }
            if (currentUpdatedAtDate !== undefined) {
                const docRef = firestoreDoc(this.orgRef, "workflows", projectId)
                const savedDocument = await getDoc(docRef)
                /** Do not delete the shared project if a currentUpdatedAtDate is supplied and the date is different */
                if (savedDocument.exists() && savedDocument.data().updatedAt !== undefined && currentUpdatedAtDate !== null && savedDocument.data().updatedAt.seconds !== currentUpdatedAtDate.seconds) {
                    return null
                }
            }
            const docRef = firestoreDoc(this.orgRef, "workflows", projectId)
            return await deleteDoc(docRef)
        },
        async deleteTemplateProject(projectId) {
            if (!this.sharedCollectionRef) {
                throw new Error('sharedCollectionRef was not found')
            }
            const docRef = firestoreDoc(this.sharedCollectionRef, "workflows", projectId)
            return await deleteDoc(docRef)
        },
        async deletePublishedProject(projectId) {
            if (!this.orgRef) {
                throw new Error('orgRef was not found')
            }
            const docRef = firestoreDoc(this.orgRef, "published_workflows", projectId)
            return await deleteDoc(docRef)
        },
        async onDeleteWorkflow(workflow) {
            switch (workflow.mode) {
                case PROJECT_MODE.PRIVATE:
                    await this.deletePrivateProject(workflow.id)
                    break;
                case PROJECT_MODE.EDIT:
                case PROJECT_MODE.READONLY:
                    await this.deleteSharedProject(workflow.id)
                    break;
                case PROJECT_MODE.GLOBAL_TEMPLATE:
                    await this.deleteTemplateProject(workflow.id)
                    break;
                case PROJECT_MODE.ORG_TEMPLATE:
                    await this.deletePublishedProject(workflow.id)
                    break;
                default:
                    throw new Error('Project mode was not found to delete')
            }
        },
        async saveProjectSave(projectDetails, projectState, escalateChangedError = false, resetProjectOnSave = false) { 
            let newWorkflow = this.$store.getters['project/getChangesToWorkflow'](projectDetails)

            const uid = this.user.uid

            let datasets = await getWorkflowDatasetsIds(JSON.parse(newWorkflow.blockDefinition))
            // get id for each dataset to store in database
            datasets = datasets.map(dataset => { return { id: dataset.id} })

            newWorkflow = Object.assign({}, newWorkflow, {
                uid: this.user.uid,
                datasets: datasets,
                updatedAt: serverTimestamp(),
                groups: projectDetails.projectGroups || [],
            })

            if(newWorkflow.mode === null || newWorkflow.mode === undefined) {
                newWorkflow.mode = projectDetails.projectMode || PROJECT_MODE.PRIVATE
            }
            if(newWorkflow.name === null) {
                // old workflows use the firestore id/ref as the name. try to standardise workflows here.
                newWorkflow.name = this.projectId
            }

            try {
                this.saveProjectFormState = FORM_STATE.SAVING
                this.saveProjectSaving = true
                let savedDocument = null

                switch (newWorkflow.mode) {
                    case PROJECT_MODE.PRIVATE:
                        if (this.projectIsLoaded && this.isOwnerOfProject && [PROJECT_STATE.DUPLICATE, PROJECT_STATE.PERMISSION_UPDATE].indexOf(projectState) === -1 && this.projectMode === PROJECT_MODE.PRIVATE) {
                            savedDocument = await this.updatePrivateProjectSave(newWorkflow, this.projectId)
                        } else {
                            savedDocument = await this.createPrivateProjectSave(newWorkflow)
                        }
                        if ([PROJECT_STATE.DUPLICATE].indexOf(projectState) === -1) {
                            if (this.projectIsLoaded && this.isOwnerOfProject && [PROJECT_MODE.READONLY, PROJECT_MODE.EDIT].indexOf(this.projectMode) >= 0) {
                                await this.deleteSharedProject(this.projectId, this.projectUpdatedAt)
                            }
                            if (this.projectIsLoaded && this.projectMode === PROJECT_MODE.GLOBAL_TEMPLATE && this.isSuperAdmin) {
                                await this.deleteTemplateProject(this.projectId)
                            }
                            if (this.projectIsLoaded && this.projectMode === PROJECT_MODE.ORG_TEMPLATE && (this.isOwnerOfProject || this.isAdmin)) {
                                await this.deletePublishedProject(this.projectId)
                            }
                        }
                        break;
                    case PROJECT_MODE.EDIT:
                    case PROJECT_MODE.READONLY:
                        if (
                            this.projectIsLoaded &&
                            (
                                (this.isOwnerOfProject && [PROJECT_MODE.EDIT, PROJECT_MODE.READONLY].indexOf(this.projectMode) >= 0) ||
                                this.projectMode === PROJECT_MODE.EDIT ||
                                (this.isAdmin && [PROJECT_MODE.EDIT, PROJECT_MODE.READONLY].indexOf(this.projectMode) >= 0)
                            ) &&
                            projectState !== PROJECT_STATE.DUPLICATE &&
                            this.projectMode !== PROJECT_MODE.GLOBAL_TEMPLATE
                        ) {
                            if (this.projectIsLoaded && this.isAdmin && newWorkflow.mode !== PROJECT_MODE.EDIT) {
                                newWorkflow.uid = this.projectOwnerUid || uid
                            }
                            savedDocument = await this.updateSharedProjectSave(newWorkflow, this.projectId, this.saveProjectForceUpdateSave)
                        } else {
                            savedDocument = await this.createSharedProjectSave(newWorkflow)
                        }
                        if (projectState !== PROJECT_STATE.DUPLICATE) {
                            if (this.projectIsLoaded && this.isOwnerOfProject && this.projectMode === PROJECT_MODE.PRIVATE) {
                                await this.deletePrivateProject(this.projectId)
                            }
                            if (this.projectIsLoaded && this.projectMode === PROJECT_MODE.GLOBAL_TEMPLATE && this.isSuperAdmin) {
                                await this.deleteTemplateProject(this.projectId)
                            }
                            if (this.projectIsLoaded && this.projectMode === PROJECT_MODE.ORG_TEMPLATE && (this.isOwnerOfProject || this.isAdmin)) {
                                await this.deletePublishedProject(this.projectId)
                            }
                        }
                        await this.moveProjectAssetsToOrg(newWorkflow)
                        break;
                    case PROJECT_MODE.GLOBAL_TEMPLATE:
                        if (this.projectIsLoaded && this.projectMode == PROJECT_MODE.GLOBAL_TEMPLATE) {
                            savedDocument = await this.updateTemplateProjectSave(newWorkflow, this.projectId)
                        } else {
                            savedDocument = await this.createTemplateProjectSave(newWorkflow)
                        }
                        if (projectState !== PROJECT_STATE.DUPLICATE) {
                            if (this.projectIsLoaded && this.isOwnerOfProject && this.projectMode === PROJECT_MODE.PRIVATE) {
                                await this.deletePrivateProject(this.projectId)
                            }
                            if (this.projectIsLoaded && this.isOwnerOfProject && this.projectMode && [PROJECT_MODE.READONLY, PROJECT_MODE.EDIT].indexOf(this.projectMode) >= 0) {
                                await this.deleteSharedProject(this.projectId, this.projectUpdatedAt)
                            }
                            if (this.projectIsLoaded && this.projectMode === PROJECT_MODE.ORG_TEMPLATE) {
                                await this.deletePublishedProject(this.projectId)
                            }
                        }
                        break;
                    case PROJECT_MODE.ORG_TEMPLATE:
                            if (
                                this.projectIsLoaded &&
                                (
                                    this.isOwnerOfProject ||
                                    (this.isAdmin && this.saveProjectProjectState !== PROJECT_STATE.NEW)
                                ) &&
                                this.projectMode == PROJECT_MODE.ORG_TEMPLATE
                            ) {
                                if (this.projectIsLoaded && this.isAdmin) {
                                    newWorkflow.uid = this.projectOwnerUid || uid
                                }
                                savedDocument = await this.updatePublishedProjectSave(newWorkflow, this.projectId, this.saveProjectForceUpdateSave)
                            } else {
                                // Set the location of where we move the project to if we are un unpublish the template
                                newWorkflow.provenance = [PROJECT_MODE.READONLY, PROJECT_MODE.READONLY].indexOf(this.projectMode) >= 0 ? 'team' : 'user'
                                savedDocument = await this.createPublishedProjectSave(newWorkflow)
                            }
                            if (projectState !== PROJECT_STATE.DUPLICATE) {
                                if (this.projectIsLoaded && this.isOwnerOfProject && this.projectMode === PROJECT_MODE.PRIVATE) {
                                    await this.deletePrivateProject(this.projectId)
                                }
                                if (this.projectIsLoaded && this.projectMode === PROJECT_MODE.GLOBAL_TEMPLATE && this.isSuperAdmin) {
                                    await this.deleteTemplateProject(this.projectId)
                                }
                                if (this.projectIsLoaded && this.isOwnerOfProject && this.projectMode && [PROJECT_MODE.READONLY, PROJECT_MODE.EDIT].indexOf(this.projectMode) >= 0) {
                                    await this.deleteSharedProject(this.projectId, this.projectUpdatedAt)
                                }
                            }
                            await this.moveProjectAssetsToOrg(newWorkflow)
                            break;
                    default:
                        throw new Error('Project Mode was invalid')
                }

                if(savedDocument) {
                    this.setWorkflow(savedDocument)
                    if(this.hasProjectChanged()) {
                        this.changeProjectState(PROJECT_STATE.CHANGED)
                    } else {
                        this.changeProjectState(PROJECT_STATE.SAVED)
                    }  
                }

                this.saveProjectPromptIsActive = false
                this.saveProjectFormState = FORM_STATE.SAVED

                // this.snackbar.message = `Successfully saved workflow '${workflowName}'`;
                // this.snackbar.visible = true;
            } catch (error) {
                if (escalateChangedError && error instanceof ProjectChangedError) {
                    this.saveProjectSaving = false
                    throw error
                }
                this.saveProjectFormState = FORM_STATE.ERROR
                console.error(error)
                // this.errorDialog.message = `Error while saving workflow '${workflowName}'. Error: ${error}`;
                // this.errorDialog.visible = true;
            } finally {
                this.saveProjectSaving = false
                if(resetProjectOnSave) {
                    this.resetProject()
                }
            }
        },
        // Check assets on a workflow and return any that need to be moved to the org level
        projectAssetNeedingToBeMoved(workflow) {
            let userAreas;
            const blockDefinition = JSON.parse(workflow.blockDefinition)
            try {
                //old project could not have userAreas we can ignore this check
                userAreas = JSON.parse(workflow.userAreas)
            } catch (error) {
                console.error(error)
                return []
            }
            
            const variables = workflow.variables || []
            let datasetIds = []
            // Construct asset Ids from uploaded shapes
            if(userAreas && userAreas.shapes && userAreas.shapes.length > 0) {
                datasetIds = datasetIds.concat(userAreas.shapes.filter(s => {
                    return (
                        s.type === 'UserUploaded' && 
                        s.assetID 
                    ) 
                })
                .map(s => s.assetID))
            }
            // Traverse the block definition to find any inserted datasets/assets
            if(blockDefinition && blockDefinition.blocks && blockDefinition.blocks.blocks) {
                const handleBlockTraversal = (block) => {
                    let datasets = []
                    if(block.type === 'workflow_insert_dataset') {
                        datasets.push(block.fields.dataset_options)
                    }
                    if(block.inputs?.modifiers?.block) {
                        datasets = datasets.concat(handleBlockTraversal(block.inputs.modifiers.block))
                    }
                    if(block.next?.block) {
                        datasets = datasets.concat(handleBlockTraversal(block.next.block))
                    }
                    return datasets
                };
                blockDefinition.blocks.blocks.forEach(block => {
                    datasetIds = datasetIds.concat(handleBlockTraversal(block))
                })
            }
            // Dataset variables are coming, add this in for completeness
            variables.forEach(variable => datasetIds.push(variable.value))
            //Get unique dataset ids
            let uniqueDatasetIds = [...new Set(datasetIds)]
            // Return the dataset objects and return any not found as they do not need to be shared.
            return uniqueDatasetIds
                .filter(s => s !== undefined && s !== null)
                .map(s => this.$store.getters['userdatasets/getDisplayDataset'](s))
                .filter(s => s !== undefined)
                .filter(s => this.$store.getters['userdatasets/getDatasetLocation'](s.id) !== 'org')
        },
        // Upon a new save of a workflow. if its now in a team level status move associated assets as well
        async moveProjectAssetsToOrg(workflow) {
            const userAssets = this.projectAssetNeedingToBeMoved(workflow).map(shape => shape.id)
            if(userAssets.length === 0) {
                return
            }
            await Promise.all(userAssets.map(async assetId => {
                return this.$store.dispatch('userdatasets/moveDataset', {
                    datasetId: assetId,
                    permission: 'viewable'
                })
            }))
        },
        async loadProjectAreasOnMap(projectAreas) {
            AreaService.clearMap();
            this.$refs.TheResultMap.clearLayers();
            

            try {
                projectAreas = JSON.parse(projectAreas)
                if(projectAreas === null) {
                    return
                }
            } catch (error) {
                console.error(error)
                return
            }

            const addedAssets = {
                collections: [],
                areas: []
            }


            if (projectAreas.collections && projectAreas.collections.length > 0) {
                projectAreas.collections.forEach((collection) => {
                    AreaService.addCollection(collection)
                })
                addedAssets.collections = projectAreas.collections.map(c => c.id)
            }

            if (projectAreas.areas && projectAreas.areas.length > 0) {
                var collectionForFeatureView
                projectAreas.areas.forEach((area) => {
                    if (Object.keys(area).includes('limitedArea')) {
                        area.colour = null 
                        area.name = null 
                        collectionForFeatureView = area.collectionId
                    } else { 
                        AreaService.addArea(area)
                    }
                })
                addedAssets.areas = projectAreas.areas.map(c => c.id)
            }
            
            if (projectAreas.shapes && projectAreas.shapes.length > 0) {
                const assetRequests = {}

                projectAreas.shapes.forEach(async (shape) => {
                    let collectionShapes = []
                    let collection = {}
                    let shapeArea = AreaService.getUserAreaById(shape.areaID)
                    if (shapeArea) {
                        collection = AreaService.getCollectionById(shapeArea.collectionId)
                    }
                    if (collection) {
                        // set selected collection based on shape/area collection
                        AreaService.setSelectedCollection(collection)
                        if (collection.clippedAsset) {
                            collectionShapes = AreaService.getShapesForCollection(collection.clippedBy)
                        } else { 
                            collectionShapes = AreaService.getShapesForCollection("STUDY_AREA")
                        }
                    }

                    if (shape.type === 'UserDrawn') {
                        AreaService.addUserDrawnShapeFromSaved(shape)
                    } else if (shape.type === 'CountryFeature') {
                        const requestKey = shape.assetID
                        if (assetRequests[requestKey] === undefined) {
                            assetRequests[requestKey] = {
                                requestFunction: functions.getCountryTable,
                                type: 'CountryFeature',
                                requestParams: {
                                    org_id: this.user.orgId,
                                    user_id: this.user.uid,
                                    country_co: shape.assetID
                                },
                                shapes: []
                            }
                        }
                        assetRequests[requestKey].shapes.push(shape)
                    } else if (shape.type === 'UserUploaded') {
                        const requestKey = shape.assetID + (shape.meta && shape.meta.classProperty ? shape.meta.classProperty : '')
                        if (assetRequests[requestKey] === undefined) {
                            const classProperty =  shape.meta && shape.meta.classProperty
                                ? shape.meta.classProperty
                                : shape.meta && shape.classProperty
                                  ? shape.classProperty
                                  : null
                            assetRequests[requestKey] = {
                                requestFunction: functions.getTable,
                                type: 'UserUploaded',
                                matchClasses: shape.meta && shape.meta.classProperty ? true : false,
                                requestParams: {
                                    org_id: this.user.orgId,
                                    user_id: this.user.uid,
                                    asset_id: shape.assetID,
                                    collection_shapes: collectionShapes,
                                    class_property: classProperty
                                },
                                shapes: [],
                            }
                        }
                        assetRequests[requestKey].shapes.push(shape)
                    }
                })

                try {
                    this.runInProgress = true
                    const assetRequestArray = Object.keys(assetRequests).map(k => assetRequests[k])
                    const loadedAssets = await Promise.all(assetRequestArray.map(async request => {
                        request.response = await request.requestFunction(request.requestParams)
                        return request
                    }))

                    loadedAssets.forEach(request => {
                        if (request.response.data.type === 'featureView') {
                            request.shapes.forEach(shape => {
                                let areas = request.response.data.areas
                                let mapURL = request.response.data.mapURL
                                let bbox = request.response.data.bbox 

                                const meta = []
                                areas.forEach(a => {
                                    meta.push({
                                        areaName:a[0],
                                        areaColour:a[1],
                                        areaProperty:request.requestParams.class_property,
                                        className:a[2],
                                        classProperty: 'system:index'
                                    })
                                })

                                AreaService.setSelectedCollection(AreaService.getCollectionById(collectionForFeatureView))
                                AreaService.addFeatureViewShape(null,bbox, mapURL,shape.assetID,meta,shape.classProperty)
                            })
                        }
                        if(request.type === 'CountryFeature') {
                            request.shapes.forEach(shape => {
                                let mapURL = request.response.data.mapURL
                                let bbox = request.response.data.bbox
                                AreaService.addUserUploadedShape(shape.areaID, bbox, mapURL, shape.assetID, null, 'CountryFeature', shape.id)
                            })
                        }
                        if (request.type === 'UserUploaded') {
                            request.shapes.forEach(shape => {
                                if (request.response.data.areas) {
                                    request.response.data.areas.forEach(area => {
                                        if (shape.meta && shape.meta.areaName && (shape.meta.areaName === area.class || shape.meta.className === area.class) ) {
                                            shape.meta.properties = area.properties
                                            let bbox = request.response.data.bbox
                                            let mapURL = area.mapURL
                                            AreaService.addUserUploadedShape(shape.areaID, bbox, mapURL, shape.assetID, shape.meta, 'UserUploaded', shape.id)
                                        }
                                    })
                                } else {
                                    let bbox = request.response.data.bbox
                                    let mapURL = request.response.data.mapURL
                                    AreaService.addUserUploadedShape(shape.areaID, bbox, mapURL, shape.assetID, shape.meta, 'UserUploaded', shape.id)
                                }
                            })
                        }
                    })
                    // get first area from AOI collection
                    const templateArea = AreaService.getFirstAreaFromCollection(MAP_STUDY_AREA_COLLECTION_ID)
                    // zoom to first area
                    this.zoomToFirstArea(templateArea)
                } catch (error) {
                    console.error(error)
                    // this.snackbar.message = "There was an error loading your assets. Please try again."
                    // this.snackbar.visible = true;
                } finally {
                    this.runInProgress = false
                }
            }
            return addedAssets
        },
        async isProjectNameUnique(projectName, editingId) {
            const wfRef = firestoreCollection(this.userRef, "workflows")
            const q = query(wfRef, where("name", "==", projectName || editingId))
            const workflows = await getDocs(q)

            if (workflows.docs.length > 0) {
                return !workflows.docs.some(w => w.id !== editingId)
            } else {
                return true
            }
        },
        async isProjectUniqueInTeam(projectName, editingId) {
            const wfRef = firestoreCollection(this.orgRef, "workflows")
            const q = query(wfRef, where("name", "==", projectName || editingId))
            const workflows = await getDocs(q)

            if (workflows.docs.length > 0) {
                return !workflows.docs.some(w => w.id !== editingId)
            } else {
                return true
            }

        },
        async isProjectUniqueInPublished(projectName, editingId) {
            const wfRef = firestoreCollection(this.orgRef, "published_workflows")
            const q = query(wfRef, where("name", "==", projectName || editingId))
            const workflows = await getDocs(q)

            if (workflows.docs.length > 0) {
                return !workflows.docs.some(w => w.id !== editingId)
            } else {
                return true
            }

        }
    }, 
}

