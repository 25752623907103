<template>
    <div class="v-ebx-date-input">
        <v-text-field
            ref="dateInput"
            :label="label"
            v-model="displayedDate"
            placeholder="YYYY / MM / DD"
            :error-messages="errorMessage"
            :max-errors="maxErrors"
            :disabled="disabled"
            append-inner-icon="mdi-calendar"
            hide-details="auto"
            @click:appendInner="handleAppendInnerClick"
            v-maska="'#### / ## / ##'"
            @focus="handleFocus"
            @blur="handleBlur"
            @maska="handleMaskaEvent"
        />
        <v-menu
            :target="$refs.dateInput"
            class="custom-menu"
            v-model="menuVisible"
            min-width="auto"
            :close-on-content-click="false"
            location-strategy="connected"
        >
            <v-date-picker
                :model-value="dateForPicker"
                @update:modelValue="handleChosenDate"
                :show-adjacent-months="true"
                hide-header
            ></v-date-picker>
        </v-menu>
    </div>
</template>

<script>

export default {
    name: 'VEbxDatePicker',
    props: {
        modelValue: {
            type: [String, Date, Number],
            default: null,
        },
        label: {
            type: String,
            default: 'Select date',
        },
        errorMessage: {
            type: String,
            default: '',
        },
        returnAsDate: {
            type: Boolean,
            default: false,
        },
        maxErrors: {
            type: Number,
            default: 4,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            dateInput: null,
            menuVisible: false,
            displayedDate: '',
            inputFocused: false,
        }
    },
    computed: {
        dateForPicker: {
            get() {
                return this.dateInput ? new Date(this.dateInput) : null
            },
            set(value) {
                this.dateInput = value.toISOString()
            }
        }
    },
    watch: {
        modelValue: {
            handler(value) {
                if (value instanceof Number) {
                    this.dateInput = new Date(value).toISOString()
                } else if(value instanceof Date) {
                    this.dateInput = value.toISOString()
                } else if(value === null || value === undefined || value === '') {
                    this.dateInput = null
                } else {
                    const stringDate = new Date(value)
                    if(stringDate instanceof Date && stringDate.toString() !== 'Invalid Date') {
                        this.dateInput = stringDate.toISOString()
                    } else {
                        this.dateInput = null
                    }
                }
                if(this.inputFocused) {
                    return
                }
                if(this.dateInput !== null) {
                    this.displayedDate = this.formatDate(new Date(this.dateInput))
                } else {
                    this.displayedDate = ''
                }
            },
            immediate: true,
        },
        dateInput: {
            handler(value) {
                if(this.returnAsDate) {
                    this.$emit('update:modelValue', value ? new Date(value) : null)
                } else {
                    this.$emit('update:modelValue', value)
                }
            }
        },
    },
    methods: {
        handleAppendInnerClick() {
            if(this.disabled) {
                return
            }
            this.menuVisible = !this.menuVisible
        },
        handleMaskaEvent({detail}) {
            if(detail.completed) {
                const newDate = new Date(detail.masked.replaceAll(' / ','-'))
                if(newDate instanceof Date) {
                    if(newDate.toString() !== 'Invalid Date') {
                        if(newDate.toISOString() !== this.dateInput) {
                            this.dateInput = newDate.toISOString()
                        }
                    } else {
                        this.dateInput = detail.masked
                    }
                } else {
                    this.dateInput = null
                }
            }else {
                this.dateInput = null
            }
        },
        handleChosenDate(chosenDate) {
            if (chosenDate.getTimezoneOffset() !== 0) {
                //Add timezone offset back on so that converting to ISO string gives UTC midnight on selected day
                chosenDate = new Date(chosenDate - chosenDate.getTimezoneOffset() * 60000) 
            }
            this.dateForPicker = chosenDate
            this.menuVisible = false
        },
        formatDate(date) {
            // make dd/mm/yyyy into yyyy-mm-dd
            const year = date.getFullYear()
            let month = date.getMonth() + 1
            month = (month < 10) ? `0${month}` : `${month}`
            let day = date.getDate()
            day = (day < 10) ? `0${day}` : `${day}`
            return year + ' / ' + month + ' / ' + day
        },
        handleFocus() {
            this.inputFocused = true
        },
        handleBlur() {
            this.inputFocused = false
        },
    }
}
</script>