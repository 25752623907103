import { usersCollection, organisationsCollection, earthbloxCollection } from "@/firebase.js";
import { doc as firestoreDoc, getDoc, onSnapshot, collection, query, where } from "firebase/firestore";
import {  STRIPE_SUBSCRIPTION_SUB_COLLECTION, STRIPE_ACTIVE_SUB_STATUS, STRIPE_PORTAL_KEY, STRIPE_PORTAL_PREFIX, } from "@/constants/appConstants.js";
// const INACTIVE_SUB_STATUS = ["incomplete", "incomplete_expired", "past_due", "cancelled", "unpaid"];

const state = {
    isActive: null,
    userHasActiveSubscription: null,
    stripePortalKey: STRIPE_PORTAL_PREFIX + STRIPE_PORTAL_KEY,
    orgSubscription: null,
    userSubscription: null,
};

const getters = {
};

const mutations = {
    setIsActive(state, isActive) {
        state.isActive = isActive;
    },
    setUserHasActiveSubscription(state, userHasActiveSubscription) {
        state.userHasActiveSubscription = userHasActiveSubscription;
    },
    setStripePortalKey(state, stripePortalKey) {
        state.stripePortalKey = stripePortalKey;
    },
    setOrgSubscription(state, orgSubscription) {
        state.orgSubscription = orgSubscription;
    },
    setUserSubscription(state, userSubscription) {
        state.userSubscription = userSubscription;
    },
    unregisterOrgSubscription(state) {
        if (state.orgSubscription) {
            state.orgSubscription();
        }
    },
    unregisterUserSubscription(state) {
        if (state.userSubscription) {
            state.userSubscription();
        }
    },
    unregisterAllSubscriptions(state) {
        if (state.orgSubscription) {
            state.orgSubscription();
        }
        if (state.userSubscription) {
            state.userSubscription();
        }
    }
}

const actions = {
    // async action to listen to organisations collection onSnapshot and extract the booleans
    checkStripeIntegrationStatus(context,orgId) { 
        const unsubscribe = onSnapshot(firestoreDoc(organisationsCollection, orgId), doc => {
                if (doc.exists()  && Object.prototype.hasOwnProperty.call(doc.data(), 'stripeIntegrationActive') && doc.data().stripeIntegrationActive === true) { 
                    // commit to state
                    context.commit('setIsActive', true);//set to true if property exists and is true
                } else {
                    // commit to state
                    context.commit('setIsActive', false); //otherwise if it doenst exist or is false, set to false
                }
            }, (error) => {
                    console.warn("Error getting stripe integration configuration. We can proceed, but will disable the stripe check. This is probably not what you want in production.: ", error);
                        context.commit('setIsActive', false);
            })
        context.commit('setOrgSubscription', unsubscribe);
    },
    queryUserSubscriptions(context, userId){
        const stripeSubsColln = collection(usersCollection, userId, STRIPE_SUBSCRIPTION_SUB_COLLECTION)
        const unsubscribeQuery = query(stripeSubsColln, where("status", "in", STRIPE_ACTIVE_SUB_STATUS))
        const unsubscribe = onSnapshot(unsubscribeQuery, (querySnapshot) => {
            if (querySnapshot.empty) {
                context.commit('setUserHasActiveSubscription', false);
            } else {
                context.commit('setUserHasActiveSubscription', true);
            }
        }, (error) => {
            console.warn("Error getting subscription status will set userHasActiveSub to false", error);
            context.commit('setUserHasActiveSubscription', false)
        });
        context.commit('setUserSubscription', unsubscribe);
    },
    getStripePortalKey(context) {
        getDoc(firestoreDoc(earthbloxCollection, 'registration')).then((doc) => {
            if (doc.exists() && doc.get('stripePortalKey')) {
                const stripePortalIdentifier = STRIPE_PORTAL_PREFIX + doc.get('stripePortalKey');
                context.commit('setStripePortalKey', stripePortalIdentifier);
            }
        }).catch((error) => {
            console.warn("Error getting stripe portal key. Proceeding with default key"), error;
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};