/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

/**
 * A front-end service to manage access to STAC dataset info stored on ebx-core.
 * Fetches the full catalog of dataset metadata when initialised, so provides
 * an asynchronous init() method, and getMetadata() should return immediately.
 */

/* eslint-disable no-prototype-builtins */
import { coreDB, projectId, db } from "@/firebase.js";
import { collection as firestoreCollection, doc as firestoreDoc, getDoc, onSnapshot, query } from "firebase/firestore";
import { Subject } from 'rxjs';
import {BAND_NAME, 
        MOMENT_DATE_FORMATS,
        EBX_DATASET_TITLE, 
        TITLE, 
        EBX_CORRELATION, 
        EBX_MODIFIERS, 
        EBX_BLOCK_VALIDATION, 
        GEE_SCHEMA,
        EBX_START_DATE,
        START_DATE, 
        START_YEAR, 
        DATE_REGEX, 
        EBX_END_DATE, 
        END_DATE,
        END_YEAR,
        BANDS, 
        EBX_VALID_INDICES, 
        VIS_PARAM,
        EBX_VISUALIZE, 
        EBX_VISUALIZE_DEFAULT, 
        EBX_HIDE_FROM_LIST, 
        VIS_CONTRAST_TYPE, 
        VIS_CONTRAST_FIXED, 
        VIS_CONTRAST_FIXED_MIN, 
        VIS_CONTRAST_FIXED_MAX, 
        EBX_VISUALIZE_V1, 
        MIN, 
        MAX,
        VIS_CONTRAST_STAT, 
        GEE_SCHEMA_CLASS_NAME, 
        EBX_CADENCE, 
        DEFAULT_START_DATE,
        DEFAULT_END_DATE,
        EBX_CLOUDMASK, 
        THUMBNAIL_URL, 
        GSD, 
        TYPES, 
        VECTOR_TYPES, 
        KEYWORDS, 
        EBX_SHORT_DESCRIPTION, 
        IS_PAID, 
        IS_COMMERCIAL_USE
    } from "../constants/ebxStacConstants";
import moment from 'moment';
import json5 from "json5";
import { find, toPairs, orderBy, fromPairs } from 'lodash';
import sizeof from "js-sizeof";
import { reactive } from 'vue'

// Firestore collection to use as the dataset's source
const DATASETS_COLLECTION_BASE_NAME = "datasets_v2"
const TRAINING_SUFFIX = "_training"
const DEV_PREFIX = "dev-"
const DATASET_METADATA_COLL = "metadata"
const PROTECTED_DATASET_PREFIX = 'protected_'
const READY_STATUS_FOR_DATASETS = 'COMPLETED'

// Decide if the current project should use the dev STAC
const DEV_PROJECTS = ["ebx-trial-dev"]
const useDevStac = DEV_PROJECTS.includes(projectId)
// Set datasets collection name
let DATASETS_COLLECTION = DATASETS_COLLECTION_BASE_NAME
if (useDevStac) DATASETS_COLLECTION = DEV_PREFIX + DATASETS_COLLECTION
// Set derived collection names
const TRAINING_DATASETS_COLLECTION = DATASETS_COLLECTION + TRAINING_SUFFIX
const DATASET_LIST_DOCUMENT = `${DATASET_METADATA_COLL}/${DATASETS_COLLECTION}`

// Service object
let service = reactive({
    user: null,
    initialised: false,
    // Datasets, using the normalised id as key
    datasets: {},
    // A map of original ids to normalised id, for lookup
    datasetIdMappings: {},
    // A list of dataset ids mapped to titles
    datasetTitleMappings: {},
    // A list of dataset ids mapped repository keys
    datasetRepositoryMappings: {},
    // An inverted index of keywords extracted from metadata record fields
    datasetKeywords: {},
    // List of thumbnail URLs
    thumbnailURLS: [],
    // list of dataset ids
    datasetIds: null,
    // Datasets used for training for classification, using the normalised id as key
    trainingDatasets: {},
    // List of ids of datasets used for training
    trainingDatasetIds: [],
    trainingDatasetTitleMappings: {},
    serviceUpdated: new Subject(),
    snapshotListeners: []
});


/**
 * Training and Normal datasets need to be sperated as datasets can be called the same thing.
 * This is used in useDatasets to keep a single version of dataset or training services
 */
const singletonServices = {}

/**
 * a module global to keep track of any pending stac requests while loading
 */
const loadingStacPromises = {}


/**
 * Lazy load the full metadata for a collection.
 * Return a promise if the dataset is not already loaded OR loading
 * If a dataset is loading then push a promise on the loadingStacPromises stack. 
 * Once loaded resolve all the promises in the stack. to allow the caller to continue.
 * 
 * The stack is required to resolve issues where metadata could be requested multiple times before the load has completed 
 * which would do a request multiple times to the firebase store
 * 
 * @param {String} stacId
 * @param {Boolean} trainingSet
 * @return {Object, Promise}
 */

const loadDatasetIfNotLoaded = async (stacId, trainingSet) => {
    const promiseKey = trainingSet ? stacId + TRAINING_SUFFIX : stacId
    const datasetServiceKey = trainingSet ? 'trainingDatasets' : 'datasets'
    if(service[datasetServiceKey][stacId] === undefined) {
        const noPromises = loadingStacPromises[promiseKey] === undefined || loadingStacPromises[promiseKey].length === 0
        if(noPromises) {
            loadingStacPromises[promiseKey] = []
        }
        const promise = new Promise((resolve,reject) => {
            loadingStacPromises[promiseKey].push({ resolve, reject })
        })
        if (noPromises) {
            try {
                const repository = DatasetService.getRepository(service.datasetRepositoryMappings[stacId])
                if (repository !== null) {
                    const collection = trainingSet ? repository.trainingCollection : repository.collection
                    if (collection !== null) {
                        const docRef = firestoreDoc(collection, stacId);
                        const doc = await getDoc(docRef);
                        if(trainingSet) {
                            addMetadataToTrainingDatasets(doc, constrainIdForFirestore(stacId), repository.location)
                        } else {
                            addMetadataToDatasets(doc, constrainIdForFirestore(stacId), repository.location)
                        }
                    }
                }
                while(loadingStacPromises[promiseKey].length > 0) {
                    loadingStacPromises[promiseKey].shift().resolve( service[datasetServiceKey][stacId])
                }
            } catch(error) {
                while(loadingStacPromises[promiseKey].length > 0) {
                    loadingStacPromises[promiseKey].shift().reject( error)
                }
            }
        }
        
        return promise
    }
    return service[datasetServiceKey][stacId]
}

/**
 * Given a document from the Firestore GEE metadata collection,
 * extract the data, restore its original id, then record it in
 * the service's `datasets` object.
 * 
 * Also create derived collections of aggregated information, 
 * such as id mappings and keywords.
 *
 * @param {*} doc
 * @return {*} the id with which the datset metadata was indexed in the service datasets object
 */
function addMetadataToDatasets(doc, stacRef, location) {
    // Get the data from the doc
    let data = doc.data()
    if(data === undefined){
        return null
    }
    data._location = location
    // Use normalised id as the key, and record a mapping to support lookups
    service.datasetIdMappings[stacRef] = stacRef
    // service.datasetTitleMappings[data.id] = data.title
    service.thumbnailURLS[stacRef] = data.thumbnail_url

    // Use the normalised GEE catalog title/id as the lookup id
    service.datasets[stacRef] = data

    // Record keywords
    if(data.keywords === undefined){
        return stacRef
    }
    data.keywords.forEach((kw) => {
        // Add kw to a list
        // service.datasetKeywords.push(kw)
        
        // Add kw to an inverted index
        if (service.datasetKeywords[kw]) {
            service.datasetKeywords[kw].push([data.title, stacRef])
        } else {
            service.datasetKeywords[kw] = [[data.title, stacRef]]
        }
    })
    return stacRef
}

function addMetadataToTrainingDatasets(doc, stacRef, location) {
    // Get the data from the doc
    let data = doc.data()
    if(data !== undefined){
        data._location = location
        // Use normalised id as the key, and record a mapping to support lookups
        // service.datasetIdMappings[data.id] = normalisedId
        // service.trainingDatasetTitleMappings[data.id] = data.title
        // Use the normalised GEE catalog title/id as the lookup id
        service.trainingDatasets[stacRef] = data

        return data.id
    }
}

/**
 * This method converts GEE ids into the constrained format required by Firestore ids.
 * See `constrain_doc_id()` method in `google-functions/gather_stac_data/main.py`.
 * @param {*} id
 * @return {*} 
 */
function constrainIdForFirestore(id) {
    if (id === null || id === undefined) {
        throw new Error('id is null or undefined')
    }

    return id.replace(/\//g, '_');
}

/**
 * Convert a sensible id-value mapping to a list of [value, id] tuples
 * appropriate for feeding a Blockly dropdown.
 * @param {*} map
 * @return {*} 
 */
function _mapToBlocklyTuple(map) {
    let tupleList = [];
    // for (var kv of Object.entries(_.invertBy(map)) { tupleList.push(kv) }
    for (var [k,v] of Object.entries(map).sort(
        (a, b) => String(a[1]).localeCompare(b[1])
    )) {
        tupleList.push([v,k])
    }
    return tupleList
}

/**
 * If the bands appear to be a set of 4 polarization bands based on a heuristic, then 
 * order them in reverse alphabetical order [vv, vh, hv, hh] for convenience.
 * The heuristic used is that a band is a polarization band if:
 * - it is "numeric"
 * - its theme is "spectral_band"
 * - its units are "dB"
 * - its name is one of ['vv', 'hh', 'vh', 'hv']
 * These are all based on ebx properties so they should be robust to change. 
 * 
 * @param {*} bands an array of band objects
 * @return {*} 
 */
function reorderPolarizationBands(bands) {
    if (bands.length==4) {
        bands.sort((a, b) => {
            // Don't sort if this doesn't look like a polarization band
            if (
                a["ebx:datatype"]!=="numeric" ||
                a["ebx:datatype_theme"]!=="spectral_band"  ||
                a["ebx:units"]!=="dB" ||
                !['vv', 'hh', 'vh', 'hv'].includes(a[BAND_NAME].toLowerCase())
            ) {
                return 0;
            }
            // It happens that the order we want for polarization is reverse alphabetical (?)
            // We don"t expect bands to be named the same, so don't check for equality at this point (which would require 0 return value).
            return a[BAND_NAME].toLowerCase() < b[BAND_NAME].toLowerCase() ? 1 : -1
        })
    }
    return bands
}

/*
 ISO 8601 Durations are expressed using the following format, where (n) is replaced by the value for each of the date and time elements that follow the (n):

    P(n)Y(n)M(n)DT(n)H(n)M(n)S

    Where:

        P is the duration designator (referred to as "period"), and is always placed at the beginning of the duration.
        Y is the year designator that follows the value for the number of years.
        M is the month designator that follows the value for the number of months.
        W is the week designator that follows the value for the number of weeks.
        D is the day designator that follows the value for the number of days.
        T is the time designator that precedes the time components.
        H is the hour designator that follows the value for the number of hours.
        M is the minute designator that follows the value for the number of minutes.
        S is the second designator that follows the value for the number of seconds.

        https://momentjs.com/docs/#/durations/creating/
*/


function parseISO8601Duration(dateStr, initialDate) {
    const duration = moment.duration(dateStr);
    if (duration.isValid() === false) {
        throw new Error('Invalid ISO 8601 duration format');
    }
    const date = initialDate.add(duration); // minus numbers work here e.g. P-2D = 2 days ago
    return date;
}

function parseSTACDate(dateStr) {
    return MOMENT_DATE_FORMATS.reduce((acc, format) => {
        if (acc) return acc
        const momentDate = moment(dateStr, format, true)
        if (momentDate.isValid()) {
            return momentDate
        }
        return null
    }, null)
}

/**
 * A service to manage access to STAC dataset info stored on ebx-core.
 * Front-end code.
 */
const DatasetService = {
    useTrainingDatasets_: false,

    isInitialised: () => {
        return service.initialised;
    },
    unsubscribeListeners: () => {
        service.snapshotListeners.forEach(listener => {
            listener()
        });
        service.snapshotListeners = [];
    },
    clearDatasets: () => {
        service.datasets = {}
        service.trainingDatasets = {}
        service.datasetIdMappings = {}
        service.datasetTitleMappings = {}
        service.datasetCorrelationMappings = {}
        service.datasetRepositoryMappings = {}
        service.trainingDatasetTitleMappings = {}
        service.datasetKeywords = {}
        service.thumbnailURLS = {}
        service.datasetIds = null
    },

    /**
     * Initialise the service.
     * Fill the datasets object with metadata for all the datasets. This is about 2.5Mb of info.
     */
    init: async (AuthService) => {
        const start = Date.now();
        console.info("Initialising DatasetService");

        // Un comment this if the dynamic loading of lists causes problems with blockly. 
        // This will load the dropdown data needed for all LBlocks. Datasets are still loaded lazily
        // await Promise.all([
        //     DatasetService.loadDatasetList()
        // ])
        
        // Produce an ordered set of unique keywords from an array
        // service.datasetKeywords = [...new Set(service.datasetKeywords)].sort()

        const initialisedService = await new Promise((resolve) => {
            AuthService.loggedUser$.subscribe((user) => {
                service.user = user
                if(user && user.uid !== undefined && user.orgId !== undefined){
                    DatasetService.instrumentRepositoryListeners(['user', 'org'])
                } else {
                    DatasetService.unsubscribeListeners();
                    DatasetService.clearDatasets();
                }
                resolve(this)
            })
        });

        service.initialised = true;

        const duration = Date.now() - start;
        console.info(`Finished loading datasets metadata; took ${duration}ms`);
        console.info("DatasetService initialised");
        console.info(`Service object is ${sizeof(service)} bytes`);

        return initialisedService;
    },

    getRepositories() {
        return {
            core:{
                documentRef: firestoreDoc(coreDB, DATASET_LIST_DOCUMENT),
                key: 'stac_dataset_ids',
                prefix: '',
                collection: firestoreCollection(coreDB, DATASETS_COLLECTION),
                trainingCollection: firestoreCollection(coreDB, TRAINING_DATASETS_COLLECTION),
                location: 'core',
            },
            user: {
                documentRef: firestoreDoc(db, `/users/${service.user.uid}/metadata/datasets`),
                key: 'stac_dataset_ids',
                prefix: PROTECTED_DATASET_PREFIX,
                collection: firestoreCollection(db, `/users/${service.user.uid}/datasets`),
                trainingCollection: null,
                location: 'user',
            },
            org: {
                documentRef: firestoreDoc(db, `/organisations/${service.user.orgId}/metadata/datasets`),
                key: 'stac_dataset_ids',
                prefix: PROTECTED_DATASET_PREFIX,
                collection: firestoreCollection(db, `/organisations/${service.user.orgId}/datasets`),
                trainingCollection: null,
                location: 'org',
            }
        }
    },
    getRepository(key) {
        return this.getRepositories()[key] || null
    },

    loadRepository(repo, repoKey) {
        return new Promise((resolve, reject) => {
            getDoc(repo.documentRef)
                .then(snapshot => {
                    const stac_dataset_ids = snapshot.get(repo.key)
                    if(typeof stac_dataset_ids === 'object') {
                        Object.keys(stac_dataset_ids).forEach(key => {
                            const dataset = stac_dataset_ids[key]
                            if(
                                dataset.dataset === true && 
                                (dataset.status === undefined || dataset.status === READY_STATUS_FOR_DATASETS)
                            ){
                                if (service.datasetIds === null || service.datasetIds.includes(key) === false) {
                                    service.datasetIds.push(key);
                                }
                                service.datasetRepositoryMappings[key] = repoKey
                                service.datasetTitleMappings[key] = dataset[EBX_DATASET_TITLE] || dataset[TITLE]
                                service.datasetCorrelationMappings[key] = dataset[EBX_CORRELATION] || key
                            }
                        })
                    }
                    resolve()
                }, reject)
        })
    },

    loadDatasetList() {
        if(service.datasetIds === null) {
             
            service.datasetIds = []
            service.datasetTitleMappings = {}
            service.datasetCorrelationMappings = {}
           
            console.log("Loading datasets metadata");
            const repositoryDictionary = this.getRepositories()
            const datasetReposistories = Object.keys(repositoryDictionary).map(key => {
                return {
                    key: key,
                    repo: repositoryDictionary[key]
                }
            });

            return new Promise((resolve, reject) => {
                Promise.all(datasetReposistories.map(repo => this.loadRepository(repo.repo, repo.key))).then(() =>{
                    resolve({
                        datasets: service.datasetTitleMappings, 
                        training:  service.trainingDatasetTitleMappings
                    })
                }, reject)
            });
        }
        return {
            datasets: service.datasetTitleMappings, 
            training:  service.trainingDatasetTitleMappings
        }
    },

    /**
     * Pushes rxjs event
     */
    publishServiceUpdate() {
        service.serviceUpdated.next();
    },

    /**
     * Updates the dataset metadata for the given dataset id.
     * 
     * If failSoftly = false, the method will throw an error if the dataset is not found.
     * 
     * @param {*} datasetId 
     * @param {*} failSoftly 
     */
    async updateDataset(datasetId, failSoftly = false) {
        // check if the dataset isn't already loaded, and if not throw an error if failSoftly is false
        // get the doc
        const datasetServiceKey = 'datasets';
        const stacId = constrainIdForFirestore(datasetId);
        
        if(!service[datasetServiceKey][stacId]) {
            if (failSoftly) {
                console.info(`Dataset ${stacId} not found in the dataset service. Failed to update.`);
                return;
            } 

            throw new Error(`Dataset ${stacId} not found in the dataset service`);
        }

        try {
            const repository = this.getRepository(service.datasetRepositoryMappings[stacId]);
            const collection = repository.collection;
            if (collection === null) {
                if (failSoftly) {
                    console.info(`Dataset ${stacId} not found in the dataset service. Failed to update.`);
                    return;
                }

                throw new Error(`Dataset ${stacId} not found in the dataset service`);
            }

            const doc = await getDoc(firestoreDoc(collection, stacId));

            addMetadataToDatasets(doc, stacId, repository.location);
            this.publishServiceUpdate();
        } catch (error) {
            console.error(`Failed to update dataset ${stacId}`, error)
            if (failSoftly) {
                console.info(`Dataset ${stacId} not found in the dataset service. Failed to update.`);
                return;
            }

            throw error;
        }

    },

    /**
     * Updates the dataset repository mapping if the dataset is found in the service and its location has changed
     * @param {string} datasetId
     * @param {string} newRepository
     * @param {boolean} failSoftly - if true, will not throw an error if the dataset is not found in the service
     */
    updateDatasetRepositoryMapping(datasetId, newRepository, failSoftly = false) {
        const stacId = constrainIdForFirestore(datasetId);
        
        // check if the dataset is in the repository mapping
        if(!service.datasetRepositoryMappings.hasOwnProperty(stacId)) {
            if (failSoftly) {
                console.info(`Dataset ${stacId} not found in the dataset service. Failed to update.`);
                return;
            }

            throw new Error(`Dataset ${stacId} not found in the dataset service`);
        }

        const currentRepo = service.datasetRepositoryMappings[stacId];
        if (currentRepo !== newRepository) {
            service.datasetRepositoryMappings[stacId] = newRepository;
            this.updateDataset(stacId, true);
        }
    },

    /**
     * Creates snapshot listeners for the collections in the supplied repositories, calls updateDataset on changed datasets
     * @param {Array} repositories - array of repositories string names to listen to
     */
    instrumentRepositoryListeners(repositories) {
        const collections = repositories.map(repo => this.getRepository(repo).collection)
        const metaData = repositories.map(repo => this.getRepository(repo).documentRef)

        DatasetService.unsubscribeListeners();

        metaData.forEach((doc, index) => {
            service.snapshotListeners.push(
                onSnapshot(doc, async () => {
                    if (service.datasetIds !== null) {
                        await this.loadRepository(this.getRepository(repositories[index]), repositories[index], service.user.orgId)
                        this.publishServiceUpdate();
                    }
                }, error => {
                    console.error('Dataset service meta', error);
                })
            );
        });

        collections.forEach((collection, index) => {
            if (collection) {
                service.snapshotListeners.push(
                    onSnapshot(query(collection, []), snapshot => {
                        snapshot.docChanges().forEach(change => {
                            if (change.type === "added") {
                                // likely only called when a dataset has been modified by the user, and then pushed from one collection to another
                                const datasetId = change.doc.id;
                                console.info("added", datasetId, repositories[index])
                                this.updateDatasetRepositoryMapping(datasetId, repositories[index], true);
                            }

                            if (change.type === "modified") {
                                const datasetId = change.doc.id;
                                // only updates the dataset if it is already loaded
                                this.updateDataset(datasetId, true);
                            }
                        })
                    }, error => {
                        console.error('Dataset datasets meta', error);
                    })
                )
            }
        })
    },

    /**
     * Get metadata block for an id. If there is no entry in the service's datasets object
     * for the supplied id, the method returns null.
     *
     * @param {*} datasetId
     * @return {Promise} the metadata for the given id, or null if there is no match
     */
    async getMetadata(datasetId) {

        // Normalise the search id for lookup
        let lookupDatasetId = constrainIdForFirestore(datasetId)
        // let data = service.trainingDatasetIds.includes(datasetId) ? service.trainingDatasets[lookupDatasetId] : service.datasets[lookupDatasetId];
        // console.debug(`Returning data ${data}`)
        //return data;
        const dataset = await loadDatasetIfNotLoaded(lookupDatasetId, this.useTrainingDatasets_);
        return dataset || null
    },
    
    /**
     * Get a list of suggested modifiers.
     *
     * @param {*} datasetId
     * @return {*} 
     */
    async getDefaultModifiers(datasetId) {
        const dataset = await this.getMetadata(datasetId)
        if(dataset === null) {
            return []
        }
        const modifiers = dataset[EBX_MODIFIERS] || []
        return modifiers.flatMap(modifiers => {
            // Handle the old way we determin modifiers via a string only
            if (typeof(modifiers) === "string") {
                if (modifiers.includes("XOR")) {
                    let mods = modifiers.split(/\s+XOR\s+/)
                    return [{type: mods[0]}]
                } else if (modifiers.match(/(OR|,)/)) {
                    let mods = modifiers.split(/\s+(?:,|OR)\s+/)
                    return [{type: mods[0]}]
                } else if (modifiers.startsWith("NOT ")) {
                    return []
                }
                return [{type:modifiers}]
            }
            // If its an object assume that it is a recommended modifier that needs to be applied
            if (typeof(modifiers) === "object" && Array.isArray(modifiers) === false) {
                return [modifiers]
            }
            throw new Error(`Unexpected modifiers type ${typeof(modifiers)} for ${datasetId}`)
        })
    },

    /**
     * Get a list of block validation.
     *
     * @param {*} datasetId
     * @return {*} 
     */
    async getBlockValidationSpec(datasetId) {
        const dataset = await this.getMetadata(datasetId)
        if(dataset === null) {
            return []
        }
        const validation_spec = dataset[EBX_BLOCK_VALIDATION];
        return validation_spec || [];
    },

    /**
     * @param {*} datasetId
     * @return {*} 
     */
    async getGEESchemaForDatasetId(datasetId) {
        const dataset = await this.getMetadata(datasetId);
        if(dataset === null) {
            return []
        }
        const geeSchema = dataset[GEE_SCHEMA];
        // TODO Add end date/year to schema if missing
        return geeSchema ? geeSchema : [];
    },

    /**
     * 
     * @param {*} datasetId 
     * @return {*} 
     */
    async getStartDate(datasetId, fallbackToNow = true) {
        const dataset = await this.getMetadata(datasetId)
        if(dataset === null) {
            return null
        }
        // try and get the ebx start date first, then the normal start date if none are found return null
        const startDate = dataset[EBX_START_DATE]
        const fallbackDate =  dataset[START_DATE]
        const actualDate = startDate ? startDate : fallbackDate || null
        const startYear = dataset[START_YEAR]
        // if we still don't have a date, return the start year
        if((actualDate === null || DATE_REGEX.test(''+actualDate) === false)) {
            if (startYear) {
                return startYear + '-01-01'
            }
            if(fallbackToNow) {
                return moment().format('YYYY-MM-DD')
            }
        }
        return actualDate
    },

    /**
     * 
     * @param {*} datasetId 
     * @return {*} 
     */
    async getEndDate(datasetId, fallbackToNow = true) {
        const dataset = await this.getMetadata(datasetId)
        if(dataset === null) {
            return null
        }
        // try and get the ebx end date first, then the normal end date if none are found return null
        const endDate = dataset[EBX_END_DATE]
        const fallbackDate = dataset[END_DATE]
        const actualDate = endDate ? endDate : fallbackDate || null
        const endYear = dataset[END_YEAR]
        // if we still don't have a date, return the end year
        if((actualDate === null || DATE_REGEX.test(''+actualDate) === false)) {
            if (endYear) {
                return endYear + '-12-31'
            }
            if (fallbackToNow) {
                return moment().format('YYYY-MM-DD')
            }
        }
        return actualDate
    },

    /**
     * Get a list of bands from the STAC metadata which are marked for display.
     * Those with ebx:display equal to false are omitted, and those with 
     * polarization bands have them reordered before return.
     * @param {*} datasetId 
     * @returns {*}
     */
    async getBandsForDisplay(datasetId) {
        let bands = []
        const dataset = await this.getMetadata(datasetId);
        if(dataset === null) {
            return []
        }
        const datasetBands = dataset[BANDS] || []
        // check bands have ebx:display = true
        datasetBands.forEach((band) => {
            if (band["ebx:display"]=== true) {
                bands.push(band)
            }
        });
        // Reorder polarization bands
        bands = reorderPolarizationBands(bands);
        return bands
    },
    
    /**
     * Get the list of ebx:valid_indices from the STAC metadata.
     * @param {*} datasetId 
     * @returns 
     */
    async getValidIndices(datasetId) {
        const dataset = await this.getMetadata(datasetId);
        if(dataset === null) {
            return []
        }
        const indices = dataset[EBX_VALID_INDICES]
        return indices
    },
    
    /**
     * Get a list of bands used in a visualization spec, from the visparam.
     * This can be in one of these two forms:
     * 
     *     {"red": "nir", "green": "red", "blue": "green"}
     *     {"value": "temperature", "palette": "cmocean"}
     * 
     * @param {*} datasetId id of a dataset
     * @param {*} visualizationName the name of a visualization in ebx:visualize 
     * @returns {*} a dictionary of bands params
     */
    async getVisualizationBands(datasetId, visualizationName) {
        let bands = []
        try {
            const visualization = await this.getVisualizationBlock(datasetId, visualizationName)
            if (visualization) {
                // Get either v1 visparam bands or v2 bands
                if (visualization.hasOwnProperty(VIS_PARAM)) {
                    let visparam = visualization[VIS_PARAM]
                    if (typeof(visparam) === "string") {
                        visparam = json5.parse(visparam)
                    }
                    bands = visparam[BANDS]   
                } else {
                    bands = visualization[BANDS]
                }
            }
        } catch (err) {
            console.debug("Couldn't get visualization bands:", err)
        }
        return bands;
    },
    
    /**
     * Get a visualization block spec, based on a dataset id and optional viz name.
     * If visualizationName is not supplied, get the default one as indicated by 
     * the EBX_VISUALIZE_DEFAULT field.
     * 
     * @param {*} datasetId id of a dataset
     * @param {*} visualizationName the name of a visualization in ebx:visualize_v2
     * @param {*} visualizationKey the key to use to get the visulization data 
     * @returns {*}
     */
    async getVisualizationBlock(datasetId, visualizationName, visualizationKey = EBX_VISUALIZE) {
        const dataset = await this.getMetadata(datasetId);
        if(dataset === null) {
            return null;
        }
        // Use the default visualization is none is selected
        if (!visualizationName && dataset.hasOwnProperty(EBX_VISUALIZE_DEFAULT)) {
            visualizationName = dataset[EBX_VISUALIZE_DEFAULT]
        }
        const visualization = dataset[visualizationKey] ? dataset[visualizationKey][visualizationName] : undefined
        return visualization
    },
    
    /**
     * Get the dataset title from stac for a nice name to show in dropdowns
     * @param {*} datasetId 
     * @returns 
     */
    async getDatasetTitle(datasetId) {
        const dataset = await this.getMetadata(datasetId)
        if(dataset === null) {
            return null;
        }
        const dataset_title = dataset[EBX_DATASET_TITLE] || dataset[TITLE]
        return dataset_title
    },
    /**
     * Get the dataset hide_from_list value from stac for a flag to decide if showing dataset in picker
     * @param {*} datasetId
     * @returns
     */
    async getDatasetHideFromList(datasetId) {
        const dataset = await this.getMetadata(datasetId)
        if(dataset === null) {
            return null;
        }
        const hide_from_list = dataset[EBX_HIDE_FROM_LIST]
        return hide_from_list
    },

    /**
     * Get the dataset title from stac for a nice name to show in dropdowns
     * @param {*} datasetId 
     * @returns 
     */
    async getDatasetLocation(datasetId) {
        const dataset = await this.getMetadata(datasetId)
        if(dataset === null) {
            return null;
        }
        return dataset._location
    },
    
    /**
     * Get the min/max of bands used in a visualization spec, from the visparam.
     * This is entirely tied to v1 ebx:visualize, as it is unclear how to get min/max 
     * from v2 as yet.
     * @param {*} datasetId id of a dataset
     * @param {*} visualizationName the name of a visualization in ebx:visualize 
     * @returns {*}
     */
    async getVisualizationMinMax(datasetId, visualizationName) {
        let minMax = {
            min: null,
            max: null,
            mode: {
                min: 'single',
                max: 'single',
            },
            rgb: null
        }

        const expandForRGBMinMax = (minMax) => {
            minMax.rgb = [[0,255],[0,255],[0,255]]
            minMax.mode = {
                min: 'default',
                max: 'default'
            }
            if (Array.isArray(minMax.min)){
                minMax.mode.min = minMax.min.length === 3 ? 'rgb' : 'applied'
                minMax.rgb[0][0] = minMax.min[Math.min(0,minMax.min.length-1)]
                minMax.rgb[1][0] = minMax.min[Math.min(1,minMax.min.length-1)]
                minMax.rgb[2][0] = minMax.min[Math.min(2,minMax.min.length-1)]
                minMax.min = minMax.min[0]
            } else {
                minMax.mode.min = 'single'
                minMax.rgb[0][0] = minMax.min
                minMax.rgb[1][0] = minMax.min
                minMax.rgb[2][0] = minMax.min
            }
            if (Array.isArray(minMax.max)){
                minMax.mode.max = minMax.max.length === 3 ? 'rgb' : 'applied'
                minMax.rgb[0][1] = minMax.max[Math.min(0,minMax.max.length-1)]
                minMax.rgb[1][1] = minMax.max[Math.min(1,minMax.max.length-1)]
                minMax.rgb[2][1] = minMax.max[Math.min(2,minMax.max.length-1)]
                minMax.max = minMax.max[0]
            } else {
                minMax.mode.max = 'single'
                minMax.rgb[0][1] = minMax.max
                minMax.rgb[1][1] = minMax.max
                minMax.rgb[2][1] = minMax.max
            }
            return minMax
        }
        
        try {
            // Try to get the min/max from the v2 vis param
            const vis_v2 = await this.getVisualizationBlock(datasetId, visualizationName, EBX_VISUALIZE)
            if(typeof vis_v2 === 'object' && vis_v2[VIS_CONTRAST_TYPE]){
                const contrast = vis_v2[VIS_CONTRAST_TYPE]
                if (contrast.hasOwnProperty(VIS_CONTRAST_FIXED)) {
                    const fixedContrasts = contrast[VIS_CONTRAST_FIXED]
                    if(fixedContrasts.hasOwnProperty(VIS_CONTRAST_FIXED_MIN) && fixedContrasts.hasOwnProperty(VIS_CONTRAST_FIXED_MAX)) {
                        minMax.min = fixedContrasts[VIS_CONTRAST_FIXED_MIN]
                        minMax.max = fixedContrasts[VIS_CONTRAST_FIXED_MAX]
                        return expandForRGBMinMax(minMax)
                    }
                }

                // fallback to a global min/max if exists
                if(vis_v2.hasOwnProperty(VIS_CONTRAST_FIXED_MIN) && vis_v2.hasOwnProperty(VIS_CONTRAST_FIXED_MAX)) {
                    minMax.min = vis_v2[VIS_CONTRAST_FIXED_MIN]
                    minMax.max = vis_v2[VIS_CONTRAST_FIXED_MAX]
                    return expandForRGBMinMax(minMax)
                }
            }

            // Fallback to using the v1 vis param
            const visualization = await this.getVisualizationBlock(datasetId, visualizationName, EBX_VISUALIZE_V1)
            if(visualization !== undefined) {
                if (visualization.hasOwnProperty(VIS_PARAM)) {
                    let visparam = visualization[VIS_PARAM]
                    if (typeof(visparam) === "string") {
                        visparam = json5.parse(visparam)
                    }
                    minMax.min = visparam[MIN]
                    minMax.max = visparam[MAX]
                } else if (visualization.hasOwnProperty(VIS_CONTRAST_TYPE)) {
                    let contrast = visualization[VIS_CONTRAST_TYPE]
                    if (contrast.hasOwnProperty(VIS_CONTRAST_FIXED)) {
                        let fixedContrast = contrast[VIS_CONTRAST_FIXED]
                        minMax.min = fixedContrast[VIS_CONTRAST_FIXED_MIN]
                        minMax.max = fixedContrast[VIS_CONTRAST_FIXED_MAX]
                        // TODO Note the min/max here are in the form [0.0, n] - these are min and max for a band
                    } else if (visualization.hasOwnProperty(VIS_CONTRAST_STAT)) {
                        let statistic = visualization[VIS_CONTRAST_STAT]
                        console.log(`Statistic ${statistic}`)
                    }
                }
            }
        } catch (err) {
            console.debug("Couldn't get visualization min/max:", err)
        }
        return minMax;
    },

    /**
     * Get the palette values for the dataset if any
     * @param {*} datasetId id of a dataset
     * @param {*} bandName ebx:name of the band
     * @returns {Array, null}
     */
    async getVisualizationPaletteForDatasetBand(datasetId, bandName) {
        const bands = await this.getBandsForDisplay(datasetId)
        const bandMatchObj = {}
        bandMatchObj[BAND_NAME] = bandName
        const foundBand = find(bands, bandMatchObj)
        if(foundBand && foundBand[GEE_SCHEMA_CLASS_NAME]) {
            let palette = foundBand[GEE_SCHEMA_CLASS_NAME]
            // Remove any hash in the colour codes
            Object.keys(palette).forEach((key) => {
                let entry = palette[key]; 
                if (entry.hasOwnProperty('color')) {
                    entry['color'] = entry['color'].replace(/^#/, '')
                }
            })
            return palette
        }
        return null
    },
    
    /**
     * Get a contrast block describing visualization contrast settings, from the visparam.
     * This can be in one of these two forms:
     * 
     *     {"statistical":"p98"}
     * 
     *     { "fixed": {
     *        "min": [0.0, n],
     *        "max": [0.3, n]
     *     }}
     * 
     * @param {*} datasetId id of a dataset
     * @param {*} visualizationName the name of a visualization in ebx:visualize 
     * @returns {*} a dictionary of bands params
     */
    async getVisualizationContrastType(datasetId, visualizationName) {
        const visualization = await this.getVisualizationBlock(datasetId, visualizationName)

        if(!visualization) {
            return null
        }

        if(!visualization.hasOwnProperty(VIS_CONTRAST_TYPE)) {
            return null
        }

        let contrast = visualization[VIS_CONTRAST_TYPE]
        return contrast
    },


    async getDatasetDefaultCadenceAndInterval(datasetId) {
        const dataset = await this.getMetadata(datasetId)
        if(dataset === null) {
            return null;
        }
        const cadence = dataset[EBX_CADENCE]
        if (cadence) {
            return cadence
        }
        return {
            unit: 'day',
            interval: 0,
            default: true
        }
    },

    /** 
     * For a given dataset, calculate the default time range for the dataset.
     * 
     * @param {string} datasetId
     * @returns {Object} - a dictionary with start and end keys
     * 
     * supported format within the stac entry for the default start and end dates are:
     * {now:start:end}:P(n)Y(n)M(n)DT(n)H(n)M(n)S
     * now
     * start
     * end
     * YYYY-MM-DD
     * 
     * e.g.
     * 
     * now:PT1H = now + 1 hour
     * start:P-2Y-1D = ebx:start_date - 2 years - 1 day
     * end:P1H = ebx:end_date + 1 hour
     * P1YT3M = now + 1 year + 3 months
     * 2023-01-01 = 2023-01-01
     */

    _getDatasetDefaultTimeRange(datasetId, startDate, endDate, extentEndDate, extentStartDate, cadence) {
        const START_DATE_INDEX = 0
        const END_DATE_INDEX = 1
        const hasDefaultStartDate = startDate !== undefined
        const hasDefaultEndDate = endDate !== undefined
        const hasExtentDates = extentEndDate !== null && extentStartDate !== null
        const hasCadence = cadence && cadence.default !== true


        if(String(startDate).startsWith('end') && String(endDate).startsWith('start') && hasDefaultStartDate && hasDefaultEndDate) {
            throw new Error(`Cannot resolve default start and end dates for dataset ${datasetId} as both depend on each other`)
        }


        /**
        Setting Default start and end periods based on various rules that have been defined.
        */

        // no cadence and no default dates, use the extent dates
        if (hasCadence === false && hasDefaultStartDate === false && hasDefaultEndDate === false) {
            if(hasExtentDates) {
                startDate =  'start'
                endDate =  'end'
            } else if(extentStartDate !== null) { // has end extent date
                startDate = 'start'
                endDate = 'now';
            } else if (extentStartDate === null && extentEndDate === null) {  // doesnt look like we have any date info, use now
                startDate = 'now';
                endDate = 'now';
            } else {
                throw new Error(`Cannot resolve default start and end dates for dataset ${datasetId} as no start extent dates are available`)
            }
            
        }

        // If we have no cadence and no default start date, use the extent start date and calculate the end date
        if (endDate === undefined) {
            // fallback to use now as a default end date
            endDate = 'now';
            // if we have an extent end date, use that as the end date
            if(extentEndDate !== null) {
                endDate = extentEndDate;
            }

            // if default start date is still undefined and we have an extent start date, use that as the start date
            // we try and use the cadence as each cadence has slightly different rules (day being most complicated)
            if (startDate === undefined) {
                // we always want to use the end date if available to work otu the default dates
                // fallback to now if we have no end date
                const startingPointForDurationCalculation =  (extentEndDate === null) ? 'now':'end'
                if (hasCadence && cadence.unit === 'day') {
                    // we have an extent start date no end date, and we have no default start and end dates. Lets calcualte the defualt dates from day cadence
                    if(extentStartDate !== null && extentEndDate === null && hasDefaultStartDate === false && hasDefaultEndDate === false) {
                        // we assume we should use now/end as the date and shift end date back by the cadence interval. Start date is an additional cadence interval back
                        const interval = cadence.interval === undefined ? 5 : cadence.interval
                        startDate = `${startingPointForDurationCalculation}:P-${interval * 2}D`;
                        endDate = `${startingPointForDurationCalculation}:P-${interval}D`;
                    } else if(extentEndDate !== null){
                         // we have an extent end date so lets shift back 14 days from the end date
                         startDate = startingPointForDurationCalculation+':P-14D';
                    }else{
                        // we have a day cadence but no dates on the dataset. lets shift back 14 days from now
                        startDate = startingPointForDurationCalculation+':P-28D';
                        endDate = startingPointForDurationCalculation+':P-14D';
                    }
                }else if (hasCadence && cadence.unit === 'hour') {
                    startDate = startingPointForDurationCalculation+':P-2D';
                }else if (hasCadence && cadence.unit === 'month') {
                    startDate = startingPointForDurationCalculation+':P-1Y';
                }else if (hasCadence && cadence.unit === 'year') {
                    if (extentStartDate !== null) {
                        startDate = extentStartDate
                    }
                } else {
                    startDate = startingPointForDurationCalculation+':P-14D';
                }
            }
        }

        if (hasCadence && cadence.unit === 'hour') {
            if(startDate === undefined && endDate !== undefined){
                startDate = 'end:P-2D'
            }
        }
        if (hasCadence && cadence.unit === 'day') {
            if(startDate === undefined && endDate !== undefined){
                startDate = 'end:P-14D'
            }
        }
        if (hasCadence && cadence.unit === 'month') {
            if(startDate === undefined && endDate !== undefined){
                startDate = 'end:P-1Y'
            }
        }

        /**
         * Parse the dates proceesing the duration periods if found and return a moment date object
         */

        const parseDate = (date, index) => {
            const dateText = index == START_DATE_INDEX ? "start" : "end"
            let dateAsString = String(date)

            if(dateAsString.toLowerCase() === 'now') {
                return moment().set({hour:0,minute:0,seconds:0})
            }
            
            let initialDate = moment().set({hour:0,minute:0,seconds:0})

            if (dateAsString === 'start'){
                initialDate = startDate && hasDefaultStartDate && index !== START_DATE_INDEX 
                    ? moment(parseDate(startDate,START_DATE_INDEX)) : parseSTACDate(extentStartDate)
                dateAsString = initialDate.format('YYYY-MM-DD')
            }

            if (dateAsString === 'end'){
                initialDate = endDate && hasDefaultEndDate && index !== END_DATE_INDEX 
                    ? moment(parseDate(endDate,END_DATE_INDEX)) : parseSTACDate(extentEndDate)
                dateAsString = initialDate.format('YYYY-MM-DD')
            }

            if (dateAsString.startsWith('start:')) {
                initialDate = startDate && hasDefaultStartDate && index !== START_DATE_INDEX 
                    ? moment(parseDate(startDate,START_DATE_INDEX)) : parseSTACDate(extentStartDate)
                dateAsString = dateAsString.replace('start:', '')
            }

            if (dateAsString.startsWith('end:')) {
                initialDate = endDate  && hasDefaultEndDate && index !== END_DATE_INDEX 
                    ? moment(parseDate(endDate,END_DATE_INDEX)) : parseSTACDate(extentEndDate)
                dateAsString = dateAsString.replace('end:', '')
            }

            if (dateAsString.startsWith('now:')) {
                dateAsString = dateAsString.replace('now:', '')
            }

            if(initialDate.isValid() === false) {
                throw new Error(`Dataset ${datasetId} has invalid inital ${dateText} date ${dateAsString}`)
            }

            const parsedDate = parseSTACDate(dateAsString)
            if (parsedDate !== null) {
                return parsedDate
            }
            return parseISO8601Duration(dateAsString, initialDate)
        }
        
        // parse the start and end date, its a map as the logic is the same for both
        const dates = [startDate, endDate].map(parseDate);
        
        return {
            start_date: dates[0].set('millisecond',0).toISOString(),
            end_date: dates[1].set('millisecond',0).toISOString(),
        }
    },


    async getDatasetDefaultTimeRange(datasetId) {
        const metaData = await this.getMetadata(datasetId)
        let startDate = metaData[DEFAULT_START_DATE]
        let endDate = metaData[DEFAULT_END_DATE]
        const cadence = await this.getDatasetDefaultCadenceAndInterval(datasetId)
        let extentEndDate = await this.getEndDate(datasetId, false)
        let extentStartDate = await this.getStartDate(datasetId, false)
        return this._getDatasetDefaultTimeRange(datasetId, startDate, endDate, extentEndDate, extentStartDate, cadence)
    },

    /**
     * get the default start and end dates for a dataset, use default dates if start and end date are not defined or the default dates are outside the extent dates
     * @param {*} datasetId 
     * @returns 
     */
    async getDatasetStartAndEndDateRange(datasetId) {
        let start_date = await DatasetService.getStartDate(datasetId);
        let end_date =  await DatasetService.getEndDate(datasetId);
        let default_dates = await DatasetService.getDatasetDefaultTimeRange(datasetId);
        // use default dates if start and end date are not defined
        if(start_date === undefined || start_date === null) {
            start_date = moment(default_dates.start_date).format('YYYY-MM-DD')
        }
        if(end_date === undefined || end_date === null) {
            // set the end date to now if the default end date is before now. 
            // an empty end_date assumes a current end date. 
            // However if the end date is futher in the future than now, use the default end date
            const now = moment()
            const m_end_date = moment(default_dates.end_date)
            if(m_end_date.isAfter(now)) {
                end_date = m_end_date.format('YYYY-MM-DD')
            }else {
                end_date = now.format('YYYY-MM-DD')
            }
        }
                
        if(moment(default_dates.start_date).unix() < moment(start_date).unix() > moment(end_date).unix()) {
            start_date = moment(default_dates.start_date).format('YYYY-MM-DD')
        }
        if(moment(default_dates.end_date).unix() > moment(end_date).unix()) {
            end_date = moment(default_dates.end_date).format('YYYY-MM-DD')
        }

        return {
            start_date: start_date,
            end_date: end_date
        }

    },

    /**
     * Get a list of all dataset ids.
     * Returns the original dataset ids not the constrained ones.
     *
     * @return {*} 
     */
    async getDatasetIds() {
        console.debug(`Getting list of dataset ids`);
        await this.loadDatasetList()
        return Object.keys(service.datasetIdMappings);
    },
    
    /**
     * Get a list of all dataset titles.
     *
     * @return {*} 
     */
    async getDatasetTitles() {
        console.debug(`Getting list of dataset titles`);
        await this.loadDatasetList()
        let mappings = service.datasetTitleMappings;
        return Object.values(mappings).sort();
    },


    
    /**
     * Get a list of dataset title/id tuples.
     * This is the format required by Blockly.
     *
     * @return {*} 
     */
    getDatasetTitlesIds() {
        if(this.useTrainingDatasets_) {
            console.debug(`Getting mapping of training dataset titles to ids`);
            const promiseOrDataset = this.loadDatasetList()

            if (typeof promiseOrDataset.then === 'function') {
                return new Promise((resolve, reject) => {
                    promiseOrDataset
                    .then(() => {
                        resolve(_mapToBlocklyTuple(service.trainingDatasetTitleMappings))
                    })
                    .catch(err => reject(err))
                })
            }
            
            return _mapToBlocklyTuple(service.trainingDatasetTitleMappings)
        }

        
        console.debug(`Getting mapping of dataset titles to ids`);
        const promiseOrDataset = this.loadDatasetList()
        const getDatasetOptions = () => {
            let mappings = service.datasetTitleMappings;
            let options = _mapToBlocklyTuple(mappings);
            return options
        }

        if (typeof promiseOrDataset.then === 'function') {
            return new Promise((resolve, reject) => {
                promiseOrDataset
                .then(() => {
                    resolve(getDatasetOptions())
                })
                .catch(err => reject(err))
            })
        }
        
        return getDatasetOptions()
    },

    /**
     * Get a list of all dataset keywords.
     * 
     *
     * @return {*} 
     */
    getDatasetKeywords() {
        console.debug(`Getting list of dataset keywords`);
        let kws = service.datasetKeywords;
        return Object.keys(kws).sort();
    },
    
    /**
     * Get a list of dataset title/id tuples matching a keyword.
     *
     * @return {*} 
     */
    getDatasetTitlesIdsForKeyword(keyword) {
        console.debug(`Getting mapping of dataset titles to ids for keyword ${keyword}`);
        let kws = service.datasetKeywords;        
        return kws[keyword].sort() || [];
    },
    
    /**
     * Get datasets metadata to be used by the blocks
     *
     * @param {*} datasetId
     * @return {*} the metadata object for the given id, or null if there is no match
     * { 
     *   from: 'yyyy-mm-dd', 
     *   to: 'yyyy-mm-dd', 
     *   name: 'Hansen Global Forest Change v1.8 (2000-2020)', 
     *   scale: 30, 
     *   collectionID: 'UMD/hansen/global_forest_change_2020_v1_8', 
     *   bands: { tree_cover: 'treecover2000', loss: 'loss', gain: 'gain' } // this will need refactored
     * },
     */
    async getBlockDatasetSpec(dataset) {
        // format the data id for firestore
        let formattedDataset = constrainIdForFirestore(dataset)

        let datasetsLength = Object.keys(service.datasets).length;

        if (service.datasets && datasetsLength > 0) {
            let candidates = this.getDatasetsFromPartialId(formattedDataset, service.datasets)
            
            // get the latest dataset by ID
            let metadata = this.returnMostRecentCatalog(candidates)
            let catalogSpec = metadata ? { 
                from: metadata.start_date, 
                to: metadata.end_date, 
                name: metadata.title, 
                scale: metadata.gsd[0], 
                collectionID: metadata.id
            } : {}
            return catalogSpec
        } else {
            return {}
        }
    },
    
    /**
     * Return a set of catalogs from ebx-core matching a partial id.
     *
     * @param {*} id
     * @param {*} catalogs
     * @return {*} array of datasets that match the partial id 
     */
    getDatasetsFromPartialId(id, catalogs) {
        let candidates = []
        Object.keys(catalogs).forEach(key => {
            if (key.includes(id)) {
                candidates.push(catalogs[key])
            }
        })
        return candidates;
    },

    /**
     * Returns the available cloud filters, if any for a given dataset.
     * 
     * @param {*} datasetId
     * @return {*} array of cloud filters or null if none available
     */
    async getCloudFiltersForDataset(datasetId) {
        try {
            let dataset = await this.getMetadata(datasetId)
            const pairs = toPairs(dataset[EBX_CLOUDMASK])
            const orderedPairs = orderBy(pairs, p => p[1].order)
            const cloudMasks = fromPairs(orderedPairs)
            return cloudMasks;
            /**
             * once mask has name in object:
             * return Object.keys.map(key => maskObj[key].name))
             */
        } catch {
            console.info("Error getting cloud filters for dataset, returning null");
            return null;
        }
    },
    
    /**
     * Sort out an array of catalogs metadata
     *
     * @param {*} catalogs
     * @return {*} the most recent by end_year dataset
     */
    returnMostRecentCatalog(catalogs) {
        catalogs.sort(function (a,b){
            return parseInt(b.endyear) - parseInt(a.endyear)
        })
        return catalogs[0]
    },
    /** 
     * create a new instance of the dataset service if we are using training dataset
     */
    useDatasets(dataset) {
        dataset === 'training' ? 'training' : 'dataset'
        if (singletonServices[dataset] === undefined) {
            const self = Object.assign({}, this)
            self.useTrainingDatasets_ = dataset === 'training'
            singletonServices[dataset] = self
        }
       
        return singletonServices[dataset]
    },


    /**
     * Returns thumbnail URLs for all datasets
     *
     * @return {*} Array of URLs, organised alphabetically
     */
    getThumbnailURLS() { 
        let mappings = service.thumbnailURLS;
        return Object.values(mappings).sort();
    }, 

    /**
     * Returns thumbnail URL for a specified dataset
     *@param {*} datasetId
     * @return {*} 
     */

    async getDatasetThumbnail(datasetId) {
        let dataset = await this.getMetadata(datasetId)
        if(dataset === null){
            return null
        }
        let dataset_thumbnail = dataset[THUMBNAIL_URL]
        return dataset_thumbnail
    }, 

    /**
     * Returns the GSD Value for the dataset
     *@param {*} datasetId
     * @return {*} 
     */
    async getDatasetGsd(datasetId) {
        let dataset = await this.getMetadata(datasetId)
        if(dataset === null){
            return null
        }
        let gsd = dataset[GSD]
        return Array.isArray(gsd) && gsd.length > 0 ? gsd[0] : null
    },

    /**
     * Returns the gee type of the dataset
     *@param {*} datasetId
     * @return {*} 
     */
     async getDatasetType(datasetId) {
        let dataset = await this.getMetadata(datasetId)
        if(dataset === null){
            return null
        }
        for (let i = 0; i < TYPES.length; i++) {
            const stacType = TYPES[i]
            if(dataset[stacType] !== undefined) {
                const typeString = dataset[stacType].toLowerCase()
                return VECTOR_TYPES.indexOf(typeString) >= 0 ? 'vector': 'raster'
            }
        }
        return 'raster'
    },

    /**
     * Returns keywords for a specified dataset
     *@param {*} datasetId
     * @return {*} 
     */

    async getKeywords(datasetId) { //Double check this is working properly when we need it. 
        let dataset = await this.getMetadata(datasetId)
        if(dataset === null){
            return null
        }
        let dataset_keywords = dataset[KEYWORDS]
        return dataset_keywords
    },

    /**
     * Returns keywords for a specified dataset
     *@param {*} datasetId
     * @return {*} 
     */

    async getShortDescriptions(datasetId) {
        let dataset = await this.getMetadata(datasetId)
        if(dataset === null){
            return null
        }
        let dataset_short_description = dataset[EBX_SHORT_DESCRIPTION]
        return dataset_short_description
    },

    /**
     * Returns whether dataset is paid access
     *@param {*} datasetId
     * @return {*} 
     */

     async getIsPaid(datasetId) {
        let dataset = await this.getMetadata(datasetId)
        if(dataset === null){
            return null
        }
        let isPaid = dataset[IS_PAID]
        return isPaid
    },

    /**
     * Returns whether dataset is for commercial use
     *@param {*} datasetId
     * @return {*} 
     */

    async getIsCommercialUse(datasetId) {
        let dataset = await this.getMetadata(datasetId)
        if(dataset === null){
            return false
        }
        let isCommercialUse = dataset[IS_COMMERCIAL_USE]
        if(isCommercialUse === undefined) {
            return true
        }
        return isCommercialUse === true
    },

    /**
     * Returns the correlation id for a dataset
     *@param {*} datasetId
     * @return {*} 
     */

     async getCorrelationId(datasetId) {
        if (service.datasetCorrelationMappings[datasetId] !== undefined) {
            return service.datasetCorrelationMappings[datasetId]
        }
        return null
    },

    /**
     * Returns an object for each dataset with information required by dataset modal (title, thumbnail url, keywords, short description)
     * @return {*} 
     */

    async getDatasetModalContent() { 
        async function populateObject(dataset) {
            const metadata = await DatasetService.getMetadata(dataset)
            if (metadata === null) {  
                return null
            }
            return {
                'id': constrainIdForFirestore(dataset),
                'title': await DatasetService.getDatasetTitle(dataset), 
                'thumbnail':  await DatasetService.getDatasetThumbnail(dataset), 
                'keywords':  await DatasetService.getKeywords(dataset), 
                'short_description': await DatasetService.getShortDescriptions(dataset),
                'location': await DatasetService.getDatasetLocation(dataset),
                'type': await DatasetService.getDatasetType(dataset),
                'hideFromList': await DatasetService.getDatasetHideFromList(dataset),
                'isPaid': await DatasetService.getIsPaid(dataset),
                'isCommercialUse' : await DatasetService.getIsCommercialUse(dataset),
                'correlationId': await DatasetService.getCorrelationId(dataset)
            };
        }

        function sortDatasets(x,y) { 
            return x.title.localeCompare(y.title) //Allows us to sort by dataset title so they appear in alphabetical order
        }
        const datasetsIds = (service.datasetIds !== null) ? service.datasetIds : []
        
        return (await Promise.all(datasetsIds.map(populateObject))).filter(ds => {
            return ds !== null
        }).sort(sortDatasets)
    },

    serviceUpdated$: service.serviceUpdated.asObservable(),
};

DatasetService.useDatasets('dataset')

export {
    DatasetService
}