<template>
    <div>
        <DatasetInfoForm 
            v-if="stepIndex === 0" 
            v-model:form-data="formData" 
            @form-valid="handleInfoValidForm" 
        />
        <UploadForm 
            v-if="stepIndex === 1 || stepIndex === 2" 
            :disabled="isUploading"
            :stac-id="stacId"
            :valid-bands="validBands"
            v-model:form-data="formData" 
            v-model:file-data="files" 
            @formValid="handleUploadValidForm" 
        >
            <template v-slot:afterForm>
                <v-alert v-if="operationCreationFailed" type="error" class="mt-2">
                    <p class="text-body-2">
                        There was an error processing your 
                        file<template v-if="files.length">s</template>. 
                        Please try again
                    </p>
                </v-alert>
            </template>
        </UploadForm>
        
    </div>
</template>

<script>
import DatasetInfoForm from './DatasetInfoForm.vue';
import UploadForm from './UploadForm.vue';
import { v4 as uuidv4 } from 'uuid';
import { doc as firestoreDoc, getDoc, setDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { ref as storageRef, uploadBytesResumable } from "firebase/storage";
import moment from 'moment';
import { unscannedBucketRef, usersCollection } from '@/firebase';

export default {
    name: "ImageUploadContainer",
    components: {
        DatasetInfoForm,
        UploadForm
    },
    props: {
        confirmedStage: {
            type: Number,
            default: 0
        },
        stacId: {
            type: [String, null],
            default: null
        },
        userId: {
            type: String,
            required: true
        },
        orgId: {
            type: String,
            required: true
        },
        imageType: {
            type: String,
            required: false
        },
        validBands: {
            type: Array,
            default: () => []
        },
        cadence: {
            type: String,
            required: false,
            default: null
        },
        cancelRequest: {
            type: Boolean,
            default: false
        }
    },
    emits: ['formValid', 'uploading','update:cancelRequest'],
    data() {
        return {
            formData: {
                stac_id: null,
                name: '',
                description: '',
                startDate: null,
                imageType: '',
                cadence: null
            },
            files: {
                files: [],
                meta: {}
            },
            uploadStats: {
                fileCount: 0,
                uploading: 0,
                uploaded: 0
            }, 
            stepIndex: 0,
            infoFormValid: false,
            uploadFormValid: false,
            uploadConcurrency: 5,
            maxUploadAttempts: 3,
            fileUploadTimeout: 30000, // 30 seconds
            isUploading: false,
            uploadJobId: null,
            operationDocRef: null,
            operationCreationFailed: false
        }
    },
    computed: {
        isNewUpload() {
            return this.formData.stac_id === null
        }
    },
    watch: {
        cadence: {
            handler(value) {
                if(value !== null) {
                    this.formData.cadence = value
                }
            },
            immediate: true
        },
        confirmedStage: {
            handler(value) {
                this.stepIndex = value
                if(value === 0) {
                    this.resetOperationCreatedAlert()
                }
                this.emitFormValid()
            },
            immediate: true
        },
        stacId: {
            handler(value) {
                this.formData.stac_id = value
            },
            immediate: true
        },
        imageType: {
            handler(value) {
                this.formData.imageType = value
            },
            immediate: true
        },
        isUploading: {
            handler(value) {
                this.$emit('uploading', value)
            }
        },
        cancelRequest: {
            async handler(value) {
                if(value) {
                    if(this.isUploading) {
                        // cancel the operation, any new files being uploaded will be put in a invalid state
                        await this.cancelOperation();
                        await this.cancelUploads();
                    }
                    this.$emit('update:cancelRequest', false)
                }
            }
        }
    },
    methods: {
        resetOperationCreatedAlert() {
            this.operationCreationFailed = false;
        },
        handleInfoValidForm(isValid) {
            this.infoFormValid = isValid
            this.emitFormValid()
        },
        handleUploadValidForm(isValid) {
            this.uploadFormValid = isValid
            this.emitFormValid()
        },
        emitFormValid() {
            if(this.stepIndex === 0) {
                this.$emit('formValid', this.infoFormValid)
            } 
            if(this.stepIndex === 1) {
                this.$emit('formValid', this.uploadFormValid)
            }
        },
        async createOperationDoc(filesToUpload, uploadJob) {
            let docId = `${this.userId}_${uploadJob}`;
            this.operationCreationFailed = false;
            // check if an operation doc for this upload already exists
            // if it does then raise error as filename is not unique
            let docRef = firestoreDoc(usersCollection, this.userId , "operations", docId);
            let docSnapshot =  await getDoc(docRef);
            if (docSnapshot.exists()) {
                console.warn("Operation document already exists.");
                return;
            }
            const operationData = {
                files: filesToUpload.map((file) => file.name),
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
                state: "UPLOADING",
                submittedBy: this.userId,
                task_type: "INGEST_IMAGE",
                formData: {
                    stac_id: this.formData.stac_id,
                    name: this.formData.name,
                    description: this.formData.description,
                    startDate: this.formData.startDate ? moment(this.formData.startDate).toDate() : null,  // convert date or string to date
                    imageType: this.formData.imageType,
                    cadence: this.formData.cadence
                },
                hasDismissedAssetNotification: {
                    isUploading: true,
                    isFinished: false,
                }
            }
            try {
                await setDoc(docRef,operationData);
                return docRef;
            } catch(e) {
                console.error("Failed to create operation doc", e);
                this.operationCreationFailed = true;
                return null
            }
        },
        async uploadToStorageBucket(fileList, storagePath) {
            this.uploadStats.fileCount = fileList.length;
            // function that uploads file list to storage bucket
            let pendingFiles = fileList.slice(0).map((file,i) => {
                return {
                    fileIndex: i,
                    attempts: 0,
                    error: null,
                    progress: 0
                }
            })

            const uploadFile = async (fileData) => {
                const file = fileList[fileData.fileIndex];
                this.uploadStats.uploading += 1;
                let fileName = file.name;
               
                if (fileName.indexOf(" ") >= 0) {
                    fileName = fileName.replaceAll(" ", "_");
                }
                try {
                    
                    await new Promise((resolve, reject) => {
                        if(this.files.meta[file.name].uploadErrored || this.files.meta[file.name].uploadCancelled) {
                            resolve();
                        }
                        const handleTimeout = () => {
                            reject("upload taking too long (cancelled): "+ file.name)
                        }
                        fileData.timer = setTimeout(handleTimeout,this.fileUploadTimeout)
                        this.files.meta[file.name].uploading = true;

                        const bucket = storageRef(unscannedBucketRef, `${storagePath}/${fileName}`);

                        fileData.task = uploadBytesResumable(bucket, file);
                        fileData.task.on(
                            "state_changed", 
                            snapshot => {
                                console.log("uploading file", file.name, snapshot.state)
                                fileData.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                clearTimeout(fileData.timer)
                                fileData.timer = setTimeout(handleTimeout,this.fileUploadTimeout)
                            },
                            (err) => {
                                clearTimeout(fileData.timer)
                                reject(err)
                            }, 
                            () => {
                                clearTimeout(fileData.timer)
                                this.files.meta[file.name].uploaded = true;
                                resolve()
                            }
                        );
                    });

                    this.uploadStats.uploaded += 1;
                    this.uploadStats.uploading -= 1;
                } catch (error) {
                    if(fileData.task) {
                        fileData.task.cancel()      
                    }
                    this.uploadStats.uploading -= 1;
                    if (fileData.attempts >= this.maxUploadAttempts) {
                        console.error("Failed to upload file after "+this.maxUploadAttempts+" attempts");
                        this.files.meta[file.name].uploadErrored = true;
                        this.files.meta[file.name].uploading = false;
                        throw error;
                    }else {
                        console.error("Failed to upload file", error);
                        fileData.attempts += 1;
                        fileData.error = error;
                        pendingFiles.push(fileData)
                    }
                } finally {
                    this.files.meta[file.name].uploading = false;
                }
                
                if (pendingFiles.length > 0) {
                    return await uploadFile(pendingFiles.shift());
                }
            }

            const concurrency = Math.min(pendingFiles.length, this.uploadConcurrency)
            const toRun = pendingFiles.slice(0, concurrency);
            pendingFiles = pendingFiles.slice(concurrency);
            return await Promise.all(toRun.map(mapFile => uploadFile(mapFile)));

        },
        async uploadFiles() {
            if (!this.uploadFormValid) {
                return false;
            }
            this.uploadJobId = uuidv4();
            let storagePath = `/${this.orgId}/${this.userId}/${this.uploadJobId}`;
            this.isUploading = true;
            this.operationDocRef = await this.createOperationDoc(this.files.files, this.uploadJobId);
            if(this.operationDocRef === null) {
                this.isUploading = false;
                return false
            }
            try {
                await this.uploadToStorageBucket(this.files.files, storagePath);
                await updateDoc(this.operationDocRef,{
                    updatedAt: serverTimestamp()
                });
            }
            catch (file) {
                console.error("File failed to upload", file);
                await updateDoc(this.operationDocRef, {
                    state: "ERROR",
                    updatedAt: serverTimestamp()
                });
            }
            finally {
                this.isUploading = false;
            }
            return true;
        },
        cancelOperation() {
            if(this.operationDocRef) {
                return updateDoc(this.operationDocRef, {
                    state: "CANCELLED",
                    updatedAt: serverTimestamp(),
                    hasDismissedAssetNotification: {
                        isUploading: false,
                        isFinished: true,
                    }
                });
            }
        },
        cancelUploads() {
            this.files.files.forEach((file) => {
                if(this.files.meta[file.name].uploading) {
                    if(this.files.meta[file.name].task) {
                        this.files.meta[file.name].task.cancel()
                    }
                    this.files.meta[file.name].uploading = false;
                    this.files.meta[file.name].uploadCancelled = true;
                } else {
                    this.files.meta[file.name].uploadCancelled = true;
                }
                if(this.files.meta[file.name].timer) {
                    clearTimeout(this.files.meta[file.name].timer)
                }
            })
        }
    }
}
</script>