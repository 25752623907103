<template>
    <div
        @drop="dropHandler($event)"
        @dragover="dragOverHandler($event)"
    >
        <NoFilesPrompt v-if="filesToUpload.length === 0" :disabled="disabled" @click="triggerFileSelection" />
        <template v-else>
            <div v-if="filesToUpload.length > 1">
                <p class="font-weight-bold"> Files </p> 
                <p class="text-body-2 mb-3">These files will be merged into a single image</p>
            </div>
            
            <v-list v-if="metaDataLoaded" variant="flat" class="pa-0">
                <v-list-item 
                    v-for="(file, index) in filesToUpload" 
                    :key="index"
                    class="bg-slate-50 mb-2"
                    rounded
                >
                    <v-list-item-title v-text="file.name"></v-list-item-title> 
                    <template v-slot:append>
                        <v-btn 
                            v-if="isMetaUploaded(file.name)" 
                            variant="plain" 
                            density="compact"
                            :disabled="disabled" 
                            icon="mdi-check"></v-btn>
                        <v-btn 
                            v-else-if="isMetaUploadError(file.name) || isMetaUploadCancelled(file.name)" 
                            density="compact" 
                            variant="plain" 
                            :disabled="disabled" 
                            icon="mdi-alert-outline"></v-btn>
                        <v-progress-circular 
                            v-else-if="isMetaUploading(file.name) || isMetaLoading(file.name)" size="18" 
                            color="primary" 
                            indeterminate />
                        <v-btn 
                            v-else 
                            density="compact" 
                            variant="plain" 
                            :disabled="disabled" 
                            @click.stop="removeFile(index)" 
                            icon="mdi-delete-outline"></v-btn>
                    </template>
                </v-list-item>
            </v-list>

            <template v-else>
                <slot name="loading" :progress="metaDataProgress" :file-count="filesToUpload.length" :meta-data-count="metaDataLength">
                    <LoadingFiles :progress-percent="metaDataProgress" :total-count="filesToUpload.length" :loaded-count="metaDataLength" />
                </slot>
            </template>
        </template>

        <v-alert v-if="hasErroredFiles" type="error" class="mt-2">
            <template v-if="erroredFiles.length === 1">
                <p class="text-body-2">
                    <strong class="font-weight-bold">{{ erroredFiles[0] }}</strong> couldn’t be processed because its band structure does not match the other images in the collection. 
                    Please ensure that all images in the collection have the same number and type of bands and try again.
                </p>
            </template>
            <template v-else>
                <p class="text-body-2">The following images couldn’t be processed as their band structures do not match the other images in the collection.</p>
                <ul class="my-1">
                    <li v-for="file in erroredFiles" :key="file" class="text-body-2 font-weight-bold">{{ file }}</li>
                </ul>   
                <p class="text-body-2">Please ensure that all images in the collection have the same number and type of bands and try again.</p>
            </template>
        </v-alert>
                    
        <input
            v-show="false"
            ref="fileInput"
            :accept="acceptedTypes"
            multiple
            type="file" 
            @change="checkFiles($event);" 
        />

    </div>
</template>

<script>
import {GeotiffFileHandler} from '@/scripts/GeotiffFileHandlers'
import LoadingFiles from './LoadingFiles.vue';
import NoFilesPrompt from './NoFilesPrompt.vue';

const BAND_JOIN_SEPERATOR = ', ';

export default {
    components: {
        LoadingFiles,
        NoFilesPrompt
    },
    props: {
        fileData: {
            type: Object,
            default: () => ({files: [], meta: {}})
        },
        disabled: {
            type: Boolean,
            default: false
        },
        stacId: {
            type: [String, null],
            default: null
        },
        validBands: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            filesToUpload: [],
            fileMeta: {},
            allowedExtensions: [".tif", ".tiff"],
            loadingImages: false,
            metaDataConcurrency: 5,
            erroredFiles: []
        };
    },
    emits: ['update:fileData'],
    computed: {
        hasStacId() {
            return this.stacId !== null;
        },
        acceptedTypes() {
            return this.allowedExtensions.join(',');
        },
        metaDataLoaded() {
            return this.metaDataLength === this.filesToUpload.length;
        },
        metaDataLength() {
            return Object.keys(this.fileMeta).length;
        },
        metaDataProgress() {
            return this.metaDataLength / this.filesToUpload.length * 100;
        },
        firstMetaData() {
            const keys = Object.keys(this.fileMeta);
            return this.fileMeta[keys[0]];
        },
        metaDataComplete() {
            if(!this.metaDataLoaded) {
                return false;
            }
            const keys = Object.keys(this.fileMeta);
            return keys.every(key => this.fileMeta[key].loading === false);
        },
        fileBandRelationships() {
            // group files by band relationship, store the filename in an array to get the count
            const filenames = Object.keys(this.fileMeta)
            const bandRelationships = {};

            filenames.forEach(filename => {
                const meta = this.fileMeta[filename];
                
                if (!meta.bands) {
                    return;
                }

                const bandString = meta.bands.map(band => (band || '').toString()).join(BAND_JOIN_SEPERATOR);
                if (bandRelationships[bandString]) {
                    bandRelationships[bandString].push(filename);
                } else {
                    bandRelationships[bandString] = [filename];
                }
            });
            return bandRelationships
        },
        fileBandsCount() {
            return Object.keys(this.fileBandRelationships).length;
        },
        getMostRelatedBands() {
            if(this.hasStacId) {
                // If we have a stacId, we should use the valid bands also passed in.
                return this.validBands.join(BAND_JOIN_SEPERATOR);
            }
            // find the band sequence from all the files that have the most bands.
            const bandRelationships = this.fileBandRelationships;
            const bandCounts = Object.keys(bandRelationships).map(key => bandRelationships[key].length);
            const maxCount = Math.max(...bandCounts);
            return Object.keys(bandRelationships).find(key => bandRelationships[key].length === maxCount);
        },
        hasErroredFiles() {
            return this.erroredFiles.length > 0;
        }
    },
    watch: {
        filesToUpload: {
            handler: async function() {
                if (this.filesToUpload.length === 0) {
                    this.loadingImages = false;
                } else {
                    try {
                        this.loadingImages = true;
                        await this.processMetaForUploadedFiles();
                    } finally {
                        this.loadingImages = false;
                    }
                }
            },
            deep: true 
        },
        metaDataComplete: {
            handler: function(isComplete) {
                if (isComplete) {
                    const invalidFiles = this.checkFilesForConsistantBands()
                    this.erroredFiles = invalidFiles
                    invalidFiles.forEach(file => this.removeFile(this.getFileIndexByName(file), true))

                    this.emitFileUpdate()
                }
            }
        },
        fileData: {
            handler: function(data) {
                this.filesToUpload = data.files;
                this.fileMeta = data.meta;
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        checkFilesForConsistantBands() {
            // check if all the files have the same bands
            const validBands = this.getMostRelatedBands
            const allBands = Object.keys(this.fileBandRelationships)
            const invalidBands = allBands.filter(bands => bands !== validBands)
            const invalidFiles = invalidBands.map(bands => this.fileBandRelationships[bands]).flat()
            return invalidFiles
        },
        emitFileUpdate() {
            this.$emit('update:fileData', {
                files: this.filesToUpload, 
                meta: this.fileMeta
            });
        },
        dropHandler(event) {
            if (this.disabled) {
                return;
            }
            // Prevent default behavior (Prevent file from being opened)
            event.preventDefault();
            if (event.dataTransfer.items) {
                // Use DataTransferItemList interface to access the file(s)
                [...event.dataTransfer.items].forEach((item) => {
                    // If dropped items aren't files, reject them
                    if (item.kind === "file") {
                        const file = item.getAsFile();
                        this.checkFiles(event, [file]);
                    }
                });
            }
            else {
                // Use DataTransfer interface to access the file(s)
                [...event.dataTransfer.files].forEach((file) => {
                    this.checkFiles(event, [file]);
                });
            }
        },
        dragOverHandler(event) {
            // Prevent default behavior (Prevent file from being opened)
            event.preventDefault();
        },
        isMetaLoading(fileName) {
            return this.fileMeta[fileName] && this.fileMeta[fileName].loading;
        },
        isMetaUploading(fileName) {
            return this.fileMeta[fileName] && this.fileMeta[fileName].uploading;
        },
        isMetaUploaded(fileName) {
            return this.fileMeta[fileName] && this.fileMeta[fileName].uploaded;
        },
        isMetaUploadError(fileName) {
            return this.fileMeta[fileName] && this.fileMeta[fileName].uploadErrored;
        },
        isMetaUploadCancelled(fileName) {
            return this.fileMeta[fileName] && this.fileMeta[fileName].uploadCancelled;
        },
        getFileIndexByName(fileName) {
            return this.filesToUpload.findIndex(file => file.name === fileName);
        },
        removeFile(index, disabledResetErrorFiles = false) {
            if (this.disabled) {
                return;
            }
            const fileName = this.filesToUpload[index].name
            if(this.isMetaLoading(fileName)) {
                return
            }
            if(this.isMetaUploaded(fileName)) {
                return
            }
            if(this.isMetaUploading(fileName)) {
                return
            }
            if(this.fileMeta[fileName]) {
                delete this.fileMeta[this.filesToUpload[index].name];
            }
            this.filesToUpload.splice(index, 1);
            // reset errored files once all files are removed
            if(this.filesToUpload.length === 0 && disabledResetErrorFiles === false) {
                this.erroredFiles = []
            }
            this.emitFileUpdate()
        },
        triggerFileSelection() {
            if (this.disabled) {
                return;
            }
            this.$refs.fileInput.click();
        },
        getFileExtension(filename) {
            return filename.substring(filename.lastIndexOf("."), filename.length) || filename;
        },
        checkFiles(event, files) {
            if (!files) {
                files = event.target.files;
            }
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                let extension = this.getFileExtension(file.name);
                if (this.allowedExtensions.includes(extension)) {
                    this.filesToUpload.push(file);
                }
            }
        },
        async processMetaForUploadedFiles() {
            let pendingFiles = this.filesToUpload.slice(0)

            const loadMeta = async file => {
                if (this.fileMeta[file.name]) {
                    if (pendingFiles.length > 0) {
                        return await loadMeta(pendingFiles.shift());
                    }else{
                        return false
                    }
                }
                var handle = async () => {
                    const handler = new GeotiffFileHandler(file);
                    try {
                        this.fileMeta[file.name] = {
                            loading: true
                        }
                        this.fileMeta[file.name] = {
                            loading: false,
                            uploaded: false,
                            uploading: false,
                            uploadErrored: false,
                            uploadCancelled: false,
                            espg: await handler.getESPG(),
                            bands: await handler.getBands(),
                        }
                        handler.close()
                    }catch(e) {
                        this.fileMeta[file.name] = {
                            loading: false,
                            uploaded: false,
                            uploading: false,
                            uploadErrored: true,
                            uploadCancelled: false,
                            errorMessage: e.message,
                            espg: null,
                            bands: []
                        }
                    } finally {
                        handler.close()
                    }
                    
                };
                await handle();
                if (pendingFiles.length > 0) {
                    return await loadMeta(pendingFiles.shift());
                }
            }

            const concurrency = Math.min(pendingFiles.length, this.metaDataConcurrency)
            const toRun = pendingFiles.slice(0, concurrency);
            pendingFiles = pendingFiles.slice(concurrency);

            return await Promise.all(toRun.map(file => loadMeta(file)));

        },
    }
}
</script>