<template>
    <div class="">
        <GettingStarted v-if="gettingStartedPackages" :packages="gettingStartedPackages" :isExplorer="isExplorer" @new="handleNewBlankProject"></GettingStarted>
        <h1 class="ebx-header-1">
            Projects
        </h1>
        <div class="load-project-container"> 
            <div class="md-layout md-layout--projects">
                <div class="md-layout-item">
                    <md-menu v-if="!isExplorer" md-size="small" md-menu-trigger class="container-item">                        
                        <md-button md-menu-trigger class="ebx-action-text ebx-action-text--primary ebx-action-text__icon"><span class="material-icons-outlined ebx-icon">add</span>Create new project</md-button>
                        <md-menu-content>
                            <md-menu-item @click="handleNewBlankProject">New blank project</md-menu-item>
                            <md-menu-item @click="handleFromTemplate">From template</md-menu-item>
                        </md-menu-content>
                    </md-menu>
                    <md-button v-if="isExplorer" class="ebx-action-text ebx-action-text--primary ebx-action-text__icon" @click="handleFromTemplate"><span class="material-icons-outlined ebx-icon">add</span>Open template</md-button>

                </div>
            </div> 
        </div>
        <div class="project-search">
            <div v-if="isExplorer" class="md-layout-item text-right">
                <EbxSearchBar 
                v-model="searchBar"
                placeholder="searchBar.teamPlaceholder"></EbxSearchBar>
            </div>
            <div v-else-if="canShareWorkflows" class="md-layout-item text-right">
                <EbxSearchBar
                v-model="searchBar"
                :filter-options="searchBarFilterOptions"></EbxSearchBar>
            </div>
            <div v-else class="md-layout-item text-right">
                <EbxSearchBar 
                v-model="searchBar"
                ></EbxSearchBar>
            </div>
        </div>
        <div class="project-list-container">

            <div class="workflow-tab--loading" v-if="loading">
                <md-progress-spinner class="md-accent" md-mode="indeterminate"
                    :md-diameter="84" :md-stroke="2"
                ></md-progress-spinner>
            </div>

            <div class="workflow-tab--empty" v-if="!loading && filteredWorkflows.length === 0">
                <div class="ebx-icon">
                    <img :src="assets.icons.emptyStateFolder" alt="No assets">
                </div>
                <p v-if="isExplorer" class="ebx-primary">Your team doesn’t have any shared projects yet</p>
                <p v-else-if="searchBar.filterBy === 'all'" class="ebx-primary">We couldn't find any projects matching this search</p>
                <p v-else-if="searchBar.filterBy === 'org'" class="ebx-primary">We couldn’t find any projects matching this search in 'Team Projects'</p>
                <p v-else-if="searchBar.filterBy === 'user'" class="ebx-primary">We couldn’t find any projects matching this search in 'My Projects'</p>
            </div>

            <template v-if="!loading">
                <md-list class="workflows-lists">
                    <WorkflowItem v-for="workflow in filteredWorkflows" :key="workflow.id" 
                        :content="workflow"
                        :can-edit="canEditWorkflow(workflow)" 
                        :selected="isSelectedWorkflow(workflow)"
                        :can-make-copy="isCreator"
                        :can-edit-api-access="isCreator && (isAdmin || workflow.uid === user.uid) && (workflow.mode === PROJECT_MODE.EDIT || workflow.mode === PROJECT_MODE.READONLY)"
                        :canShareWorkflows="canShareWorkflows"
                        :projectIsGlobalTemplate="workflow.mode === PROJECT_MODE.GLOBAL_TEMPLATE"
                        :projectIsOrgTemplate="workflow.mode === PROJECT_MODE.ORG_TEMPLATE"
                        :projectIsPrivate="workflow.mode === PROJECT_MODE.PRIVATE"
                        @load="handleLoadWorkflow" 
                        @edit="handleEditWorkflow" 
                        @duplicate="handleDuplication"
                        @delete="handleDeletion"
                        @api-access="handleAPIAccess"
                        @move-to-team="handleMoveToTeam"
                        @move-to-published="handleMoveToPublished"
                        @unpublish-org-template="handleMoveToUnPublished"
                        @move-to-global-templates="handleMoveToGlobalTemplates"
                        @unpublish-global-template="handleMoveGlobalToUnPublished"
                        />
                </md-list>
            </template>
        </div>
        <ConfirmationModal ref="confirm" :ok-button-text="confirmationText" :title="confirmationTitle" :if-warning="isWarning"/>
        <md-dialog v-model:md-active="showMoveToTeamConfirm" class="ebx-dialog dialog-confirmation">
            <md-dialog-content class="dialog-content">
                <p class="ebx-primary">
                    Are you sure you want to move <strong>{{ wantedToMoveWorkflow ? wantedToMoveWorkflow.name || wantedToMoveWorkflow.id : '' }}</strong> to Team Projects?
                </p>
                <MoveAssetAlert class-name="mb-0" mode="readonly" :assets="assetsRequiringToMove" />
            </md-dialog-content>
            <md-dialog-actions>
                <slot name="actions">
                    <button class="ebx-button ebx-button--secondary"  @click.prevent="showMoveToTeamConfirm = false">
                        Cancel
                    </button>
                    <button class="ebx-button ebx-button--primary"  @click.prevent="confirmedMovedToTeam">
                        Confirm
                    </button>
                </slot>
            </md-dialog-actions>
        </md-dialog>
        <EbxErrorDialog
                :showErrorDialog="showErrorDialog"
                :errorDialogMessage="errorDialogMessage"
                @error-dialog-active="handleErrorDialogActive"
            />
        <PublishProjectModal 
            v-model="showPublishModal" 
            :defaults="wantedToMoveWorkflow" 
            :mode="publishMode" 
            :assets="assetsRequiringToMove"
            @publish="moveToPublished" 
        />
    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import EbxSearchBar from '../../components/EbxComponents/EbxSearchBar.vue';
import WorkflowItem from '../../components/ProjectSaveLoad/LoadProjectModal/WorkflowItem.vue';
import { matchSorter } from 'match-sorter';
import { PROJECT_MODE, PROJECT_STATE, saveWorkflowMixin, sharedWorkflowMixin } from '../../components/mixins/projectMixin';
import authMixin from '../../components/mixins/authMixin';
import packageMixin from '../../components/mixins/packageMixin';
import organisationsMixin from '../../components/mixins/organisationsMixin';
import { sortBy } from 'lodash';
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import GettingStarted from '@/components/ProjectSaveLoad/GettingStarted.vue'
import EbxErrorDialog from '@/components/EbxComponents/EbxErrorDialog.vue';
import PublishProjectModal from '@/components/ProjectSaveLoad/PublishProjectModal.vue';
import userDatasetsMixin from '@/components/mixins/userDatasetsMixin';
import MoveAssetAlert from '@/components/ProjectSaveLoad/MoveAssetAlert.vue'
import { onSnapshot, collection, where, query } from "firebase/firestore";
import assetsMixin from "@/components/mixins/assetsMixin.js" 

const COLLECTION_INDEX = {
    USER: 0,
    ORG: 1,
    SHARED: 2,
    PUBLISHED: 3
}

export default {
    mixins: [
        authMixin,
        saveWorkflowMixin, 
        sharedWorkflowMixin, 
        organisationsMixin, 
        packageMixin,
        userDatasetsMixin,
        assetsMixin
    ],
    components: {
        EbxSearchBar,
        WorkflowItem,
        ConfirmationModal,
        GettingStarted, 
        EbxErrorDialog,
        PublishProjectModal,
        MoveAssetAlert
    },
    props: {
        filterBy: {
            type: String,
            default: 'all'
        },
        searchTerm: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            userWorkflows: [],
            organisationWorkflows: [],
            templateWorkflow: [],
            publishedWorkflows: [],
            loading: true,
            errored: false,
            error: null,
            searchBar: {
                filterBy: 'all',
                searchInput: this.searchTerm,
                teamPlaceholder: 'Search Team Projects',
            },
            snapshots: [],
            selectedItem: {},
            showProgressBar: false,
            threshold: matchSorter.rankings.MATCHES,
            PROJECT_MODE: PROJECT_MODE,
            isWarning: true,
            confirmationText: 'Delete',
            confirmationTitle: 'Are you sure?',
            showMoveToTeamConfirm: false,
            wantedToMoveWorkflow: null, 
            showErrorDialog: false,
            errorDialogMessage: 'Error',
            showPublishModal: false,
            publishMode: PROJECT_MODE.ORG_TEMPLATE,
            assetsRequiringToMove: [],
        }
    },
    computed: {
        searchBarFilterOptions() {
            const options = [{
                value: 'all',
                label: 'All Projects'
            }]
            if (this.canShareWorkflows) {
                options.push({
                    value: 'org',
                    label: 'Team Projects'
                })
            }
            options.push({
                value: 'user',
                label: 'My Projects'
            })
            return options
        },
        showWorkflows() {
            if (this.searchBar.filterBy === 'user') {
                return this.userWorkflows
            }
            if (this.searchBar.filterBy === 'org') {
                return [].concat(this.organisationWorkflows, this.publishedWorkflows, this.templateWorkflow)
            }
            if (this.searchBar.filterBy === 'published') {
                return [].concat(this.publishedWorkflows, this.templateWorkflow)
            }
            return [].concat(this.userWorkflows, this.organisationWorkflows, this.publishedWorkflows, this.templateWorkflow)
        },
        filteredWorkflows() {
            if (this.searchBar.searchInput === "" || this.searchBar.searchInput === null) {
              return sortBy(this.showWorkflows, [v => v.name || v.id])
            }
            const filteredData = matchSorter(this.showWorkflows, this.searchBar.searchInput, { keys: ['name'], threshold: matchSorter.rankings.CONTAINS })
            return sortBy(filteredData, [v => v.name || v.id])
        },
        selectedItemIsEmpty() {
            return this.selectedItem && this.selectedItem.workflow && this.selectedItem.workflow.id === '__empty__'
        },
        hasSelectedItem() {
            if (this.selectedItemIsEmpty) {
                return true
            }
            if (this.selectedItem && this.selectedItem.workflow) {
                const seletedItems = this.filteredWorkflows.filter(w => w.id === this.selectedItem.workflow.id)
                return seletedItems.length === 1
            }
            return false
        },
        searchInput() {
            return this.searchBar.searchInput
        },
        searchFilterBy() {
            return this.searchBar.filterBy
        }, 
    },
    async mounted() {
        await this.loadOrgGroups()
        this.registerFirebaseSnapshots()
        await this.getGlobalPackages()
    },
    beforeUnmount() {
        this.unregisterFirebaseSnapshots()
    },
    methods: {
        handleErrorDialogActive(value) {
            this.showErrorDialog = value;
        },
        isSelectedWorkflow(workflow) {
            return this.selectedItem && this.selectedItem.workflow && workflow.id === this.selectedItem.workflow.id
        },
        registerFirebaseSnapshots() {
            this.loading = true;

            if(this.isCreator) {
                this.snapshots.push(onSnapshot(collection(this.userRef, "workflows"), this.handleFirebaseSnapshot(COLLECTION_INDEX.USER), error => {
                    console.error('workflows user workflows', error);
                }))
                if (this.canViewSharedOrgWorkflows) {
                    const sharedRef = collection(this.orgRef, "workflows")
                    this.snapshots.push(onSnapshot(sharedRef, this.handleFirebaseSnapshot(COLLECTION_INDEX.ORG), error => {
                        console.error('workflows orgs workflows', error);
                    }))
                }
            }
            const sharedWorkflowCollection = collection(this.sharedCollectionRef, "workflows")
            const sharedWorkflowQuery = query(sharedWorkflowCollection, where('uid','==',this.user.uid)) // only load user created published workflows
            this.snapshots.push(
                onSnapshot(sharedWorkflowQuery, this.handleFirebaseSnapshot(COLLECTION_INDEX.SHARED), error => {
                    console.error('workflows shared workflows', error);
                })
            )
            if(this.userVariablesEnabled) {
                const orgWorkflowCollection = collection(this.orgRef, "published_workflows")
                const sharedWorkflowQuery = query(orgWorkflowCollection, where('uid','==',this.user.uid)) // only load user created published workflows
                this.snapshots.push(
                    onSnapshot(sharedWorkflowQuery, this.handleFirebaseSnapshot(COLLECTION_INDEX.PUBLISHED), error => {
                        console.error('workflows shared workflows', error);
                    })
                )
            }
            
            
        },
        unregisterFirebaseSnapshots() {
            while (this.snapshots.length > 0) {
                this.snapshots.pop()()
            }
        },
        handleAPIAccess(workflow) {
            this.setWorkflow(workflow) // Set the workflow as loaded to it can be used
            this.saveProjectClearLoadedWorkflowOnSave = true
            // open api access modal
            this.$APIConfiguration('',{
                showModal: true,
                projectId: workflow.id,
                project: workflow
            })
        },
        async handleMoveToTeam(workflow) {
            let nameUnique = await this.isProjectUniqueInTeam(workflow.name, workflow.id)
            if (nameUnique === false) {
                this.showErrorDialog = true;
                this.errorDialogMessage = 'A project with this name already exists in your team. Please rename before moving.'
            } else { 
                await this.userDatasetsLoad(this.user.orgId, this.user.uid)
                this.isWarning = false
                this.wantedToMoveWorkflow = workflow
                this.assetsRequiringToMove = this.projectAssetNeedingToBeMoved(workflow)
                this.showMoveToTeamConfirm = true
            }
        },
        async handleMoveToPublished(workflow) {
            let nameUnique = await this.isProjectUniqueInPublished(this.projectName, this.projectId)
            if(nameUnique === false) {
                this.showErrorDialog = true;
                this.errorDialogMessage = 'A project with this name already exists as a published template. Please rename before moving.'
                return
            }
            
            this.wantedToMoveWorkflow = workflow
            this.isWarning = false
            await this.userDatasetsLoad(this.user.orgId, this.user.uid)
            this.assetsRequiringToMove = this.projectAssetNeedingToBeMoved(workflow)
            this.publishMode = PROJECT_MODE.ORG_TEMPLATE
            this.showPublishModal = true
        },
        async handleMoveToGlobalTemplates(workflow) {
            let nameUnique = await this.isProjectUniqueInPublished(this.projectName, this.projectId)
            if(nameUnique === false) {
                this.showErrorDialog = true;
                this.errorDialogMessage = 'A project with this name already exists as a template. Please rename before moving.'
                return
            }
            await this.userDatasetsLoad(this.user.orgId, this.user.uid)
            this.wantedToMoveWorkflow = workflow
            this.isWarning = false
            this.assetsRequiringToMove = this.projectAssetNeedingToBeMoved(workflow)
            this.publishMode = PROJECT_MODE.GLOBAL_TEMPLATE
            this.showPublishModal = true
        },
        
        async handleMoveToUnPublished(workflow) {
            this.isWarning = false
            this.confirmationText = 'Unpublish'
            this.confirmationTitle = 'Unpublish template'
            const confirmed = await this.$refs.confirm.confirm(
                'Unpublishing this template will remove it from your team templates. It will still be available in your Projects.',
            )
            if (confirmed) {
                this.saveProjectProjectState = PROJECT_STATE.NEW
                await this.setWorkflow(workflow) // Set the workflow as loaded to it can be used
                await this.saveProjectSave({
                    projectMode: workflow.provenance === 'team' ? PROJECT_MODE.READONLY : PROJECT_MODE.PRIVATE,
                }, PROJECT_STATE.CHANGED, true)
                await this.resetProject()
            }
            
        },
        async handleMoveGlobalToUnPublished(workflow) {
            this.isWarning = false
            this.confirmationText = 'Unpublish'
            this.confirmationTitle = 'Unpublish Earth Blox'
            const confirmed = await this.$refs.confirm.confirm(
                'Unpublishing this template will remove it from your Earth Blox templates. It will still be available in your Projects.',
            )
            if (confirmed) {
                this.saveProjectProjectState = PROJECT_STATE.NEW
                await this.setWorkflow(workflow) // Set the workflow as loaded to it can be used
                await this.saveProjectSave({
                    projectMode: PROJECT_MODE.PRIVATE,
                }, PROJECT_STATE.CHANGED, true)
                await this.resetProject()
            }
            
        },

        
        async confirmedMovedToTeam() {
            this.showMoveToTeamConfirm = false
            await this.setWorkflow(this.wantedToMoveWorkflow) // Set the workflow as loaded to it can be used
            await this.saveProjectSave({ projectMode: PROJECT_MODE.READONLY }, PROJECT_STATE.CHANGED, true)
            await this.resetProject()
        },
        async moveToPublished(workflow) {
            this.saveProjectProjectState = PROJECT_STATE.NEW
            await this.setWorkflow(workflow) // Set the workflow as loaded to it can be used
            await this.saveProjectSave({
                projectMode: this.publishMode,
            }, PROJECT_STATE.CHANGED, true)
            await this.resetProject()
        },
        handleFirebaseSnapshot(affectedCollection) {
            return snapshot => {
                this.handleLoadedWorkflowDocument(snapshot.docs, affectedCollection)
            }
        },
        canEditWorkflow(workflow) {
            if(this.isExplorer) {
                return false
            }
            if (workflow.mode === PROJECT_MODE.READONLY && this.isAdmin) {
                return true
            }
            if (!this.user) {
                return false
            }
            return (this.user.uid === workflow.uid || [PROJECT_MODE.EDIT, PROJECT_MODE.PRIVATE].indexOf(workflow.mode) >= 0)
        },
        handleLoadedWorkflowDocument(workflows, workflowIndex) {
            this.resetWorkflows(workflowIndex)
            switch (workflowIndex) {
                case COLLECTION_INDEX.USER:
                    this.userWorkflows = workflows.map(workflow => { return { id: workflow.id, ...workflow.data(), mode: PROJECT_MODE.PRIVATE } })
                    break;
                case COLLECTION_INDEX.ORG:
                    workflows.forEach(workflow => this.organisationWorkflows.push({ id: workflow.id, ...workflow.data() }))
                    break;
                case COLLECTION_INDEX.SHARED:
                    workflows.forEach(workflow => this.templateWorkflow.push({ id: workflow.id, ...workflow.data(), mode: PROJECT_MODE.GLOBAL_TEMPLATE }))
                    break;
                case COLLECTION_INDEX.PUBLISHED:
                    workflows.forEach(workflow => this.publishedWorkflows.push({ id: workflow.id, ...workflow.data(), mode: PROJECT_MODE.ORG_TEMPLATE }))
                    break;
                default:
                    throw new Error('workflow index not found')
            }

            if (this.loading) {
                this.loading = false;
            }
        },
        resetWorkflows(workflowIndex) {
            if (workflowIndex === undefined || workflowIndex === COLLECTION_INDEX.USER) {
                this.userWorkflows = []
            }
            if (workflowIndex === undefined || workflowIndex === COLLECTION_INDEX.ORG) {
                this.organisationWorkflows = []
            }
            if (workflowIndex === undefined || workflowIndex === COLLECTION_INDEX.SHARED) {
                this.templateWorkflow = []
            }
            if (workflowIndex === undefined || workflowIndex === COLLECTION_INDEX.PUBLISHED) {
                this.publishedWorkflows = []
            }
        },
        handleLoadWorkflow(workflow, projectMode) {
            this.setWorkflow(workflow, projectMode, true)
            this.$router.push( {
                name:'Workflow',
                params: {
                    workflowId: workflow.id
                }
            })
        },
        async handleNewBlankProject() {
            this.$store.commit('project/setProjectToBeLoaded', true)
            this.$router.push( { name:'Workflow'})
        },
        handleDuplication(workflow) {
            this.setWorkflow(workflow) // Set the workflow as loaded to it can be used
            this.saveProjectClearLoadedWorkflowOnSave = true
            this.onDuplicateWorkflow(workflow)
        },
        async handleDeletion(workflow) {
            this.isWarning = true
            this.confirmationText = 'Delete'
            this.confirmationTitle = 'Are you sure?'
            const confirmed = await this.$refs.confirm.confirm('Are you sure you want to delete this project?')
            if (confirmed) {
                this.onDeleteWorkflow(workflow)
            }
        },
        handleEditWorkflow(workflow) {
            this.setWorkflow(workflow) // Set the workflow as loaded to it can be used
            this.onEditWorkflow(this.projectWorkflow, PROJECT_STATE.DETAIL_UPDATE, true)
        },
        handleFromTemplate() {
            this.$router.push( { name:'Templates'})
        },
    },
}
</script>
