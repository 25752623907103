<template>
    <VEbxModal 
        :toolbar-title="modalTitle" 
        v-model="showImageUpload" size="m" 
        :confirmation-button-disabled="uploadModal.confirmButtonDisabled"
        :confirmation-button-text="confirmButtonText"
        :confirmation-close-on-action="false"
        :dismiss-close-on-action="false"
        :cancel-close-on-action="false"
        persistent
        @confirm="handleConfirmation"
        @dismiss="handleCancelRequest"
        @cancel="handleCancelRequest"
    >   
        <template v-slot:activator="{ props: activatorProps }">
            <slot name="activator" :props=activatorProps :reset="handleReset">
                <v-btn color="primary" variant="text" prepend-icon="mdi-tray-arrow-up" v-bind="activatorProps" >{{ actionButtonText }}</v-btn>
            </slot>
        </template>
        <template v-slot:default>
            <ImageUploadContainer 
                ref="uploadContainer" 
                :stac-id="stacId" 
                :valid-bands="validBands"
                :image-type="imageType"
                :confirmed-stage="uploadModal.confirmedStage" 
                :user-id="user ? user.uid : ''"
                :org-id="user ? user.orgId: ''"
                :cadence="cadence"
                :cancel-request="uploadModal.userCancelledRequest"
                @formValid="handleValidForm" 
                @update:cancel-request="handleRequestCancelled"
            />
        </template>
        <template v-slot:left-actions>
            <v-btn v-if="showBackButton" text color="primary" @click="handleBack">Back</v-btn>
        </template>
    </VEbxModal>
</template>

<script>
import VEbxModal from '../../components/EbxComponents/VEbxModal.vue';
import ImageUploadContainer from './Container.vue'
import authMixin from '@/components/mixins/authMixin'

const CONFIRMED_STAGES = {
    NONE: 0,
    INFO: 1,
    UPLOAD: 2
}

export default {
    components: {
        VEbxModal,
        ImageUploadContainer
    },
    mixins: [authMixin],
    props: {
        modelValue: {
            type: Boolean,
            required: true
        },
        stacId: {
            type: String,
            default: null
        },
        imageType: {
            type: String,
            required: false,
            default: ''
        },
        actionButtonText: {
            type: String,
            default: 'Upload Image Collection'
        },
        validBands: {
            type: Array,
            default: () => []
        },
        cadence: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            showImageUpload: false,
            formValid: false,
            uploadModal: {
                userCancelledRequest: false,
                confirmButtonDisabled: true,
                confirmedStage: CONFIRMED_STAGES.NONE
            }
        }
    },
    emits: ['update:modelValue'],
    watch: {
        modelValue: {
            handler(value) {
                this.showImageUpload = value
            },
            immediate: true
        },
        showImageUpload: {
            handler(value) {
                if(value) {
                    this.setConfirmedStateForStacId()
                }
                this.$emit('update:modelValue', value)
            }
        }
    },
    computed: {
        showBackButton() {
            return this.uploadModal.confirmedStage === CONFIRMED_STAGES.INFO && this.isNewUpload
        },
        isNewUpload() {
            return this.stacId === null
        },
        modalTitle() {
            if (this.isNewUpload) {
                if (this.uploadModal.confirmedStage === CONFIRMED_STAGES.INFO) {
                    return 'Upload first image'
                } else {
                    return 'New image collection'
                }
            }
            return "Upload image"
        },
        confirmButtonText() {
            if(this.uploadModal.confirmedStage === CONFIRMED_STAGES.UPLOAD) {
                return 'Processing...'
            }
            if(this.uploadModal.confirmedStage === CONFIRMED_STAGES.INFO) {
                return 'Upload'
            }
            return 'Next'
        }
    },
    methods: {
        setConfirmedStateForStacId() {
            if(this.stacId === null) {
                this.uploadModal.confirmedStage = CONFIRMED_STAGES.NONE
            } else {
                this.uploadModal.confirmedStage = CONFIRMED_STAGES.INFO
            }
        },
        async handleConfirmation() {
            if(this.uploadModal.confirmedStage === CONFIRMED_STAGES.INFO) {
                this.uploadModal.confirmedStage = CONFIRMED_STAGES.UPLOAD
                this.uploadModal.confirmButtonDisabled = true
                const successfullyUploaded = await this.$refs.uploadContainer.uploadFiles()
                if(successfullyUploaded) {
                    this.showImageUpload = false
                } else {
                    this.uploadModal.confirmedStage = CONFIRMED_STAGES.INFO
                }
            } else {
                this.uploadModal.confirmedStage = CONFIRMED_STAGES.INFO
                this.uploadModal.confirmButtonDisabled = true
            }
        },
        handleBack() {
            if(this.uploadModal.confirmedStage === CONFIRMED_STAGES.INFO) {
                this.uploadModal.confirmButtonDisabled = !this.formValid
            }
            this.uploadModal.confirmedStage = CONFIRMED_STAGES.NONE
        },
        handleValidForm(isValid) {
            this.formValid = isValid
            this.uploadModal.confirmButtonDisabled = !isValid
        },
        handleReset() {
            this.uploadModal.confirmedStage = CONFIRMED_STAGES.NONE
            this.uploadModal.confirmButtonDisabled = true
        },
        handleCancelRequest() {
            this.uploadModal.userCancelledRequest = true
        },
        handleRequestCancelled() {
            this.uploadModal.userCancelledRequest = false
            this.showImageUpload = false
        }
    }
}
</script>
