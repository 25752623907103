<template>
    <md-content class="dashboard-tab">
        <div class="dashboard-tab--empty" v-if="outputs.length === 0 && !runId">
            <div class="ebx-icon">
                <img :src="assets.icons.emptyStateDashboard" alt="Empty dashboard">
            </div>
            <p class="ebx-primary">Create tables and charts by using the Table output block in your workflow</p>
        </div>
        <div v-else class="dashboard" ref="DashboardContainer" :class="outputs.length === 0 ? 'bottom-aligned' : ''">
            <div
                v-for="group in groupedOutputs"
                :key="group.id"
                class="dashboard--group"
            >
                <md-card>
                    <md-card-header class="table-card-header">
                        <h1 class="ebx-header-3" v-if="group.title && group.title.length > 0">{{ group.title }}</h1>
                        <div class="ebx-primary table-description" v-if="group.description">{{group.description}}</div>
                    </md-card-header>
                    <div v-for="output in group.outputs" :key="output.doc_id">
                        
                        <template v-if="output.type === 'Chart'">
                            <div class="md-subhead" v-if="output.ai_generated">&#x2728; AI Generated &#x2728;</div>
                            <PlotlyChart
                                :chartData="output.figure"
                                :outputId="output.id"
                                @create-chart="handleCreateChart"
                                />
                        </template>
                        <template v-else-if="output.type === 'Table'">
                            <div class="md-subhead" v-if="output.ai_generated">&#x2728; AI Generated &#x2728;</div>
                            <DashboardTable 
                                :df="output.df" 
                                :resolution="output.resolution"
                                :crs="output.crs"
                                @save-csv="(data) => saveCsv(output.title, data)"
                                />
                        </template>

                    </div>

                </md-card>
            </div>
            <div v-if="isRunCompleted && runId" class="dashboard--workflow-log">
                <md-menu md-size="small" :md-offset-x="100" :md-offset-y="-100">
                    <EbxButton theme="action" icon="file_download" icon-position="right" md-menu-trigger>Download workflow log</EbxButton>
                    <md-menu-content class="ebx-options-menu">
                        <md-menu-item class="ebx-options-menu--option" @click="downloadLog(false)">Workflow</md-menu-item>
                        <md-menu-item class="ebx-options-menu--option" @click="downloadLog(true)">Workflow and areas</md-menu-item>
                    </md-menu-content>
                </md-menu>
            </div>
        </div>
    </md-content>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import PlotlyChart from '@/components/Dashboard/PlotlyChart.vue';
import {functions} from "@/firebaseFunctions";
import authMixin from "@/components/mixins/authMixin";
import DashboardTable from "@/components/Dashboard/DashboardTable.vue";
import { doc as firestoreDoc, getDoc } from "firebase/firestore";

// lodash deep copy
import cloneDeep from 'lodash/cloneDeep';
import uniq from 'lodash/uniq';
import { v4 as uuidv4 } from 'uuid';
import assetsMixin from "@/components/mixins/assetsMixin.js" 

export default {
    name: "Dashboard",
    mixins: [authMixin, assetsMixin],
    components: {
        PlotlyChart,
        DashboardTable
    },
    props: {
        runResult: {
            type: Object,
            required: true
        },
    },
    data: () => ({
    }),
    watch: {
    },
    computed: {
        isRunCompleted() {
            return this.$store.state.runresult.runCompleted;
        },
        runId() {
            return this.$store.state.runresult.lastRunId;
        },
        outputs: function() {
            if(!this.runResult || !this.runResult.outputs) {
                return []
            }

            return this.runResult.outputs.map((output, index) => {
                return {
                    ...output,
                    id: index,
                    ai_generated: output.ai_generated ? output.ai_generated : false,
                    resolution: output.resolution ? output.resolution : null,
                    crs: output.crs ? output.crs : null
                }
            })
        },
        groupedOutputs: function() {
            if(!this.runResult || !this.runResult.outputs) {
                return []
            }

            // get the unique ids from runResult.outputs
            const ids = uniq(this.runResult.outputs.map(output => output.id))
            /**
             *  construct an array of arrays, where each array contains all outputs with the same id, formatted as follows:
             * return [
             *  {
             *   id: id1,
             *   outputs: [output1, output2, ...]
             *  },
             *  {
             *   id: id2,
             *   outputs: [output3, output4, ...]
             *  }
             * ]
             **/ 
            let groupedOutputs = ids.map(id => {
                return {
                    id: id,
                    outputs: this.runResult.outputs.filter(output => output.id === id)
                }
            })
            
            // if any outputs don't have an id, add them to their own group
            this.runResult.outputs.forEach(output => {
                if(!output.id) {
                    groupedOutputs.push({
                        id: uuidv4(),
                        outputs: [output]
                    })
                }
            })
            // add title to each group to match the table output title
            groupedOutputs = groupedOutputs.map(group => {
                let title = ''
                let description = ''
                group.outputs.map(output => {
                    if (output.type === 'Table') {
                        if (output.title) {
                            title = output.title
                        }
                        if (output.description) {
                            description = output.description
                        }
                    }
                })
                return {
                    ...group,
                    title: title,
                    description: description
                }
            })

            // order the group outputs to table is always last
            groupedOutputs = groupedOutputs.map(group => {
                let tableIndex = group.outputs.findIndex(output => output.type === 'Table')
                if (tableIndex === -1 || tableIndex === group.outputs.length - 1) {
                    return group
                }
                let table = group.outputs[tableIndex]
                group.outputs.splice(tableIndex, 1)
                group.outputs.push(table)
                return group
            })
            
            return groupedOutputs
        }
    },
    methods: {
        downloadLog(returnAois = false) {
            if (!this.runId) {
                console.error('No run id found')
                return
            }
            functions.extract_workflow_log({
                run_doc_id: this.runId,
                return_aois: returnAois
            }).then((response) => {
                if (response.status !== 200) {
                    console.error("Error extracting workflow log, status code", response.status);
                    return;
                }
                // response.body is a ReadableStream, use getReader() to read it
                const reader = response.body.getReader();

                // read() returns a promise that resolves when a value has been read
                // read the value and process it
                reader.read().then(function processOutput({done, value}) {
                    if (done) {
                        // Stream has been read
                        return;
                    }
                    const url = window.URL.createObjectURL(new Blob([value]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'workflow_log.txt');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    return reader.read().then(processOutput);
                });
            }).catch((error) => {
                // handle error
                console.error("Error extracting workflow log", error);
            });
        },
        saveCsv: function(title, df) {
            let saveData = cloneDeep(df);

            const columns = Object.keys(saveData[0]);

            const rows = saveData.map((row) => {
                return columns.map((fieldName) => {
                    return row[fieldName]
                })
            })

            // wrap column names in quotes to prevent commas from breaking the csv
            columns.forEach((column, index) => {
                columns[index] = `"${column}"`
            })

            rows.unshift(columns);
            this.saveCsvFromRows(rows, title)
        },
        saveCsvFromRows(rows, title){
            let csv = rows.map(row => row.join(','));
            csv = csv.join('\n');

            // workaround for downloading file
            let filename = title && title.length > 0 ? title + ".csv" : "table.csv";
            this.saveFile(csv, filename, 'text/csv')
        },
        saveFile(file, filename, mediaType) {
            let element = document.createElement('a');
            element.setAttribute('href', `data:${mediaType};charset=utf-8,${encodeURIComponent(file)}`);
            element.setAttribute('download', filename);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        },
        /**
         * Gets the output, and the associated document, calls the google-function which generates a new chart and replaces it
         * TODO: subscribe to the firestore document getting updating and update the cell
         * @param {String} prompt - the user prompt
         * @param {String} cellId - id of the cell to create a new chart
         */
        async handleCreateChart(prompt, outputID, handleResetChart, handleChartFailed) {
            const output = this.outputs.find(output => output.id === outputID)

            if(!output) {
                console.error(`could not find cell with id ${outputID}`)
                return
            }

            const run_id = this.runResult.doc_id;
            const output_docid = output.doc_id;

            const data = {
                prompt,
                run_id,
                output_docid
            }

            const res = await functions.generateChart(data).catch(err => {
                console.error(err)
                handleChartFailed();
                return;
            })

            // check status
            if(res === undefined || res === null || (res.data && res.data.response !== 'Succeeded')) {
                console.error(`could not generate chart, status code ${res.data.response}`)
                handleChartFailed();
                return
            }

            // list of new firestore doc ids
            const output_ids = res.data.output_ids;

            const outputs = output_ids.map(async (id) => {
                const docRef = firestoreDoc(this.userRef, 'runs', run_id, 'outputs', id)
                const doc = await getDoc(docRef);
                if(!doc.exists()) {
                    console.error(`could not find document with id ${id}`)
                    return
                }
                let data = doc.data()
                let output = JSON.parse(data.output)
                output = {
                    ...output,
                    doc_id: id
                }
                return output

            })

            Promise.all(outputs).then(outputs => {
                let insertIndex = this.runResult.outputs.findIndex(output => output.doc_id === output_docid)
                const newOutputs = this.runResult.outputs.slice()
                newOutputs.splice(insertIndex + 1, 0, ...outputs)
                this.$emit('update-run-result-outputs', newOutputs)
                handleResetChart();
            })
        }   
    }
}
</script>