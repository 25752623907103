<template>
    <div class="ebx-template">
        <div class="dataset-page--back-action ebx-action-text ebx-action-text--primary ebx-action-text__icon" @click="exitView">
            <span class="material-icons-outlined ebx-icon">chevron_left</span>
            <p>{{backLinkText}}</p>
        </div>
        <template v-if="loading">
            <div class="ebx-primary">
                Loading Please Wait
            </div>
            
        </template>
        <template v-else-if="loadingErrored">
            <div class="ebx-primary">
                Error Loading Workflow
            </div>
            
        </template>
        <template v-else>
            <div v-if="workflow">
                <h1 class="ebx-header-1">{{workflow.name}}</h1>
                <div class="ebx-template--content">
                    <div v-if="hasImage" class="workflow-image-container">
                        <img :src="workflow.thumbnail" class="workflow-image" alt="thumbnail">
                    </div>
                    <div>
                        <div v-if="hasDescription">
                            <div class="ebx-primary markdown-text" v-html="renderedMarkdown"></div>
                        </div>
                        <div v-else>
                            <p class="ebx-primary">No description entered</p>
                        </div>
                        <EbxButton @click="openWorkflow">Open</EbxButton>
                    </div>
                </div>

                <template v-if="uniqueDatasets.length > 0">
                    <h3 class="ebx-header-3">Datasets</h3>
                    <div class="ebx-template--datasets">
                        <div v-for="dataset in uniqueDatasets" :key="dataset.id" class="ebx-card ebx-card--dataset">
                            <img v-if="hasDatasetThumbnail(dataset)" :src="dataset.thumbnail" :alt="dataset.title" />
                            <img v-else-if="dataset.type==='vector'" :src="assets.icons.datasetVectorPlaceholder" alt="thumbnail">
                            <img v-else :src="assets.icons.datasetImagePlaceholder" alt="thumbnail">
                            <span class="ebx-primary-bold">{{ dataset.title }}</span>
                        </div>
                    </div>
                </template>

                <template v-if="outputs.length > 0">
                    <h3 class="ebx-header-3">Outputs</h3>
                    <div class="ebx-template--outputs">
                        <div v-for="output in outputs" :key="output.id" class="ebx-card">
                            <div class="ebx-card--pill">
                                <span v-if="output.type == 'map_layer'" class="ebx-pill ebx-pill--pine ebx-tertiary">Map Layer</span>
                                <span v-else-if="output.type == 'table'" class="ebx-pill ebx-pill--loch ebx-tertiary">Table</span>
                            </div>
                            <div class="ebx-primary-bold">{{ output.name }}</div>
                            <div class="ebx-secondary">{{ output.description }}</div>
                        </div>
                    </div>
                </template>
                
            </div>
        </template>
    </div>
</template>

<script>
import authMixin from "@/components/mixins/authMixin";
import { PROJECT_MODE } from '@/components/mixins/projectMixin';
import workflowMixin from '@/components/mixins/workflowMixin';
import EbxButton from '@/components/EbxComponents/EbxButton.vue';
import assetsMixin from "@/components/mixins/assetsMixin.js";
import * as commonmark from 'commonmark';
import { isNotNullOrUndefined } from '@/helpers/generalHelperFunctions.js'
import { doc as firestoreDoc, getDoc } from "firebase/firestore";
 

export default {
    name: 'TemplateWorkflow',
    components: {
        EbxButton
    },
    mixins: [authMixin, workflowMixin, assetsMixin],
    data() {
        return {
            workflow: null,
            packageTitle: '',
            loading: false,
            loadingErrored: false,
            datasets: [],
            backLinkText: 'Explore'
        }
    },
    computed: {
        hasWorkflow() {
            return this.workflow !== null
        },
        outputs() {
            if(this.hasWorkflow) {
                return this.workflowGetOutputs(JSON.parse(this.workflow.blockDefinition))
            } else {
                return []
            }
        },
        uniqueDatasets() {
            //using this.datasets filter out any duplicates using the id
            return this.datasets.filter((dataset, index, self) =>
                index === self.findIndex((t) => (
                    t.id === dataset.id
                ))
            )
        },
        hasDescription() {
            return this.workflow.description !== null && this.workflow.description !== undefined && this.workflow.description !== ''
        },
        hasImage() {
            return this.workflow.thumbnail !== null && this.workflow.thumbnail !== undefined && this.workflow.thumbnail !== ''
        },
        renderedMarkdown() {
            const reader = new commonmark.Parser();
            const parsed = reader.parse(this.workflow.description);
            const writer = new commonmark.HtmlRenderer();
            return writer.render(parsed);
        },
    },
   async mounted() {
        const pageState = this.$store.state.router.pageState
        this.packageTitle = pageState.packageTitle;
        if(pageState && pageState.workflow) {
            this.workflow = pageState.workflow
            this.datasets = await this.getWorkflowDatasetsWithService(this.workflow)
        } else if (this.$route.params.workflowId) {
            await this.loadWorkflow(this.$route.params.workflowId)
            this.datasets = await this.getWorkflowDatasetsWithService(this.workflow)
        } else {
            this.exitView()
        }
        
    },
    methods: {
        hasDatasetThumbnail(dataset) {
            return dataset.thumbnail !== null && dataset.thumbnail !== undefined && dataset.thumbnail !== ''
        },
        exitView() {
            this.$router.go(-1);
        },
        openWorkflow() {
            const workflow = this.workflow
            const workflowPayload = {
                workflow: workflow, 
                projectMode: workflow.mode,
                projectToBeLoaded: true
            }
            this.$store.commit('project/setWorkflowAndMode', workflowPayload)
            this.$router.push( {
                name:'Workflow',
                params: {
                    workflowId: workflow.id
                }
            })
        },
        async loadWorkflow(workflowId) {
            try {
                this.loadingErrored = false
                this.loading = true
                const ref = firestoreDoc(this.sharedCollectionRef, 'workflows', workflowId);
                const doc = await getDoc(ref)
                if (doc.exists() === false) {
                    throw new Error('Workflow does not exist')
                }
                this.workflow = {
                    id: doc.id,
                    ...doc.data(),
                    mode: PROJECT_MODE.GLOBAL_TEMPLATE
                }
            } catch (error) {
                console.error(error)
                this.loadingErrored = true
            } finally {
                this.loading = false
            }
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                if (isNotNullOrUndefined(to)) {
                    const pageState = this.$store.state.router.pageState
                    if(pageState === null) {
                        this.backLinkText = 'Templates'
                        return
                    }
                    if (pageState.packageTitle) {
                        this.backLinkText = pageState.packageTitle
                    } else if (pageState.origin === "Projects") {
                        this.backLinkText = pageState.origin
                    } else if (pageState.origin === "Explore") {
                        this.backLinkText = 'Explore'
                    } else { 
                        this.backLinkText = 'Templates'
                    }
                }
            },
        },
    }
}
</script>