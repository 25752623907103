/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

/* eslint-disable no-prototype-builtins */
import { createOrgConfig } from "../config/main";
import { db } from "@/firebase.js";
import { doc as firestoreDoc, getDoc, onSnapshot } from "firebase/firestore";
import { EBX_ACCESS,CONFIG_TOOLBOX_DOC, CONFIG_TOOLBOX_DOC_FIELD } from "../config/constants.js";
import {reactive} from 'vue'
// Manage base configs pulled from the db, and individual OrgConfig objects
let service = reactive({
    baseConfigs: {},
    baseToolboxXml: "",
    orgConfigs: {},
    pendingOrgConfigsCallbacks: {}
});

/**
 * A service to manage access to configurations.
 */
const ConfigService = {
    /**
     * Initialise the service. Get the base config docs for each access type,
     * along with the base toolbox XML specification.
     */
    init: async() => {
        // Get the base configs
        console.info("Initialising ConfigService");
        Object.values(EBX_ACCESS).forEach((accessType) => {
            // Watch a snapshot for changes
            const docRef = firestoreDoc(db, "configuration", accessType);
            onSnapshot(docRef, (docSnapshot) => {
                    if (!docSnapshot.exists()) {
                        console.info(
                            `Configuration doc for ${accessType} not found`
                        );
                    } else {
                        // Record/update the base config data for the access type
                        console.info(
                            `Loading configuration doc for ${accessType}`
                        );
                        service.baseConfigs[accessType] = docSnapshot.data();
                        // Update org configs for orgs of the relevant updated access type
                        Object.values(service.orgConfigs).forEach((org) => {
                            if (org.accessType === accessType) {
                                org.updateProcessedConfigs();
                            }
                        });
                    }
                }, error => {
                    console.error('config', error);
                });
        });
        // Get the toolbox XML. We do not watch a snapshot for changes, as these should happen only
        // very rarely when new features are released. New features will appear when a user logs in again.
        const configRef = firestoreDoc(db, "configuration", CONFIG_TOOLBOX_DOC);
        let toolboxConfigDoc = await getDoc(configRef)
        if (!toolboxConfigDoc.exists()) {
            console.error(
                "Toolbox configuration doc not found: there will be no toolbox!"
            );
        } else {
            console.info("Loading toolbox configuration");
            service.baseToolboxXml =
                toolboxConfigDoc.get(
                    CONFIG_TOOLBOX_DOC_FIELD
                ) || "";
        }
        console.info("ConfigService initialised");
    },

    cleanup: () => {
        Object.keys(service.orgConfigs).forEach((orgId) => {
            service.orgConfigs[orgId].unsubscribeListeners();
            delete service.orgConfigs[orgId];
        })
    },

    /**
     * Return one of the predefined configs for an org access type.
     * If configType is specified, pull out the subconfig for that type.
     * If no configType is specified, the whole config is returned
     * @param {*} accessType
     * @param {*} configType if no configType is specified, the whole config is returned
     * @returns a (shallow) copy of the config object
     */
    getBaseConfig(accessType, configType = null) {
        let cfg = service.baseConfigs[accessType] || {};
        if (!configType) {
            return Object.assign({}, cfg);
        } else {
            return cfg.hasOwnProperty(configType)
                ? Object.assign({}, cfg[configType])
                : {};
        }
    },

    /**
     * Return the toolbox XML which represents the full toolbox. Individual
     * configurations will modify the contents of the toolbox.
     */
    getBaseToolboxXml() {
        return service.baseToolboxXml;
    },

    /**
     * Get an OrgConfig object for an org id. Create one if it doesn't exist.
     * The OrgConfig will be ready to use, with all properties loaded from Firestore.
     * This method could get called multiple times while loading the config, If so we add a promise to hold the callbacks until the first load is complete.
     * @param {*} orgId
     * @returns
     */
    getOrgConfig(orgId) {
        if (!orgId) return Promise.resolve(null);

        if (service.orgConfigs.hasOwnProperty(orgId)) {
            return new Promise((resolve) => {
                resolve(service.orgConfigs[orgId])
            });
        }

        const loadConfig = async(orgId) => {
            try {
                service.orgConfigs[orgId] = await createOrgConfig(orgId);
                service.pendingOrgConfigsCallbacks[orgId].forEach((callback) => {
                    callback.resolve(service.orgConfigs[orgId])
                })
            } catch(error) {
                service.pendingOrgConfigsCallbacks[orgId].forEach((callback) => {
                    callback.reject(error)
                })
            } finally {
                delete service.pendingOrgConfigsCallbacks[orgId]
            }
        }

        return new Promise((resolve, reject) => {
            if (!service.pendingOrgConfigsCallbacks.hasOwnProperty(orgId)) {
                service.pendingOrgConfigsCallbacks[orgId] = [];
                service.pendingOrgConfigsCallbacks[orgId].push({resolve, reject})
                loadConfig(orgId)
            }else {
                service.pendingOrgConfigsCallbacks[orgId].push({resolve, reject})
            }
        });
    },
};

export { ConfigService };
