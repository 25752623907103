<template>
    <div>
        <p class="ebx-primary mb-4 text-center">
            <slot :total-count="totalCount" :loaded-count="loadedCount" :progress-percent="progressPercent">{{ checkingFilesText }}</slot>
        </p>
        <div class="process-container">
            <v-progress-linear :model-value="progressPercent" color="primary"></v-progress-linear>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        progressPercent: {
            type: Number,
            required: true
        },
        totalCount: {
            type: Number,
            required: true
        },
        loadedCount: {
            type: Number,
            required: true
        }
    },
    computed: {
        checkingFilesText() {
            if (this.totalCount === 1) {
                return 'Checking file';
            } else {
                return 'Checking files (' + this.loadedCount + '/' + this.totalCount + ')';
            }
        }
    }
}
</script>