/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

/**
 * Exports AuthService.
 */
import { BehaviorSubject } from 'rxjs';
import { auth, db } from '@/firebase.js';
import { EmailAuthProvider, onAuthStateChanged, onIdTokenChanged, signInWithEmailAndPassword, signInWithCustomToken, signOut, updateProfile, updateEmail, updatePassword, reauthenticateWithCredential, sendEmailVerification, sendPasswordResetEmail, getIdTokenResult, reload as reloadUser, getRedirectResult } from "firebase/auth";
import { collection, doc as firestoreDoc, getDoc } from "firebase/firestore";
import { LTI_ROLES } from "@/constants/appConstants.js";
import { ConfigService } from '@/services/config.service';
import { CONFIG_TYPE } from "@/config/constants.js";
import { reactive } from 'vue';
import { functions } from "@/firebaseFunctions";

/**
 * Define a service variable with a "loggedUser" property, which is a RxJS BehaviourSubject.
 * This is oinitialised with the default user, nd updated with logged in user when one authenticates.
 */
var service = reactive({
    // Create a BehaviorSubject with a default value
    loggedUser: new BehaviorSubject(getDefaultUser()),
    waitForValidUser: null,
    currentPromiseResolution: null
});

/**
 * Return a default user with empty properties and display name "Guest".
 */
function getDefaultUser() {
    return {
        uid: null,
        photoURL: null,
        roles: []
    };
}

/**
 * Construct a capitalised display name from the email address of a user.
 * Takes the string up to the '@' sign, replaces dots with spaces and capitalises individual words.
 * @param {*} email an aemail address
 */
function getDisplayNameFromEmail(email, uid) {

    if (!email) // lti authenticated does not always provide an email
    {

        let learnerid = uid.substring(uid.indexOf(':'));
        // extract LTI id from uid id
        return "Learner " + learnerid;
    }

    let name = email.substring(0, email.indexOf('@')).replace(".", " ");
    let nameWithCapitals = name.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    return nameWithCapitals;
}

/**
 * Check whether an SSO redirect sign in has occurred, and whether the user
 * is a new one that needs an org assigned. If necessary, calls the
 * completeSSOSignup function to complete the user records.
 * 
    * @return {boolean} whether a new user signup occurred via SSO redirect
 */
async function handleSSOSignInResult() {
    console.debug("handleSSOSignInResult: Checking for redirect result")
    // getRedirectResult returns null if no redirect operation was called
    const redirectResult = await getRedirectResult(auth);
    if (redirectResult !== null) {
        // User has signed in with SSO redirect.
        // console.log('signed in', redirectResult)
        console.info(`Signing in SSO user`)
        console.debug(JSON.stringify(redirectResult.user))
        // Pass whole redirectResult user object to backend to complete sign in/up
        const isNewUser = redirectResult.additionalUserInfo?.isNewUser
        const hasOrg = await AuthService.isUserAssignedOrg()
        if (isNewUser && !hasOrg) {
            console.info(`Completing SSO signup for new user: ${redirectResult.user.uid}`);
            try {
                // Convert the UserCredential object to JSON for the function call
                // https://firebase.google.com/docs/reference/js/auth.usercredential.md.
                const redirectResultJSON = JSON.parse(JSON.stringify(redirectResult))
                const functionResult = await functions.completeSSOSignup(redirectResultJSON)
                console.info(`New user was assigned to org ${functionResult.data.orgId}`);
                // this.$router.push({ name: "SignIn", params: { welcome: 1 } });
            } catch(error) {
                console.error("Error completing SSO redirect", error);
            }
        }
        console.debug("End of getRedirectResult processing")
        return isNewUser
    } else {
        // No redirect operation was called
        return false
    }
}

// Construct a user object from firebaseUser properties and token claims
const handleUserChange = (firebaseUser, token) => {

    let user = {
        uid: firebaseUser.uid,
        email: firebaseUser.email,
        emailVerified: firebaseUser.emailVerified,
        displayName: firebaseUser.displayName ? firebaseUser.displayName : getDisplayNameFromEmail(firebaseUser.email, firebaseUser.uid),
        photoURL: firebaseUser.photoURL,
        orgId: token.claims.orgId,
        roles: token.claims.roles,
        termsAgreed: token.claims.termsAgreed,
        termsVersion: token.claims.termsVersion,
        isLtiUser: token.claims.roles?.some(r => LTI_ROLES.includes(r)) || false
    };
    // add user's gmpEntitlements to user object if gmp user
    if (Object.keys(token.claims).includes('gmpEntitled')) {
        user.gmpEntitled = token.claims.gmpEntitled;
    } else {
        user.gmpEntitled = null;
    }

    if (!user.orgId && !user.roles?.includes("superadmin")) {
        console.error('User is not assigned any organisation');
        alert('Your user is not assigned any organisation! Please contact the administrator.');
        AuthService.signOut();
        return
    }
    if (!user.emailVerified) {
        console.log("Not forwarding to workflow, email not verified, sign them out without redirect");
        AuthService.signOut(true);
        return;
    }

    // Emit a new user value to subscribers via loggedUser
    service.loggedUser.next(user);

    
    ConfigService.getOrgConfig(user.orgId).then(function (orgConfig) {
        let orgCustomisation = null;
        orgConfig ? orgCustomisation = orgConfig.getConfig(CONFIG_TYPE.CUSTOMISATION) : orgCustomisation = undefined;
        let defaultWorkflow = ''
        if (orgCustomisation) {
            orgCustomisation['url.path.default.workflow'] ? defaultWorkflow = orgCustomisation['url.path.default.workflow'] : defaultWorkflow
        }
    }.bind(this));

}

/**
 * A service to authenticate users.
 */
const AuthService = {
    init: () => {
        console.info("Initialising AuthService");
        // An observer for changes to the signed-in user's ID token, which includes sign-in, sign-out, and token refresh events,
        // including analytics preference changes.
        // https://firebase.google.com/docs/reference/js/auth.md#onidtokenchanged_b0d07ab
        // NOTE: "The token refreshes lazily when interacting with other Firebase services"
        // https://github.com/firebase/firebase-js-sdk/issues/2985#issuecomment-626985962
        onIdTokenChanged(auth, function (firebaseUser) {
            console.debug(" auth.service.onIdTokenChanged ");
            if (firebaseUser) {
                // Do something with the id token, e.g.
                // firebaseUser.getIdTokenResult(true).then(token => {
                //     console.log(token.claims.orgId)
                //     console.log(token.claims.roles)
                // });
            }
        });

        // An observer for changes to the user's sign-in state. Triggered on sign-in or sign-out.
        // https://firebase.google.com/docs/reference/js/auth.md#onauthstatechanged_b0d07ab
        onAuthStateChanged(auth, function (firebaseUser) {
            console.debug(" auth.service.onAuthStateChanged ");
            AuthService.setupWaitPromise();
            if (firebaseUser) {
                firebaseUser.getIdTokenResult(true).then(token => {
                    // We don't call handleUserChange when someone logs in via SAML the first time,
                    // because they may not have a fully specified user auth record yet
                    console.info(`User logged in via ${token.signInProvider}`)
                    if (token.signInProvider.startsWith('saml.') || token.signInProvider.startsWith('oidc.')) {
                        const orgStr = token.claims?.orgId ? `org ${token.claims?.orgId}` : "orgId not set yet"
                        console.info(`User is in SSO org: ${orgStr}`);
                        handleSSOSignInResult().then(async(wasSSOSignup) => {
                            // Force refresh the token to get updated claims
                            if (wasSSOSignup) {
                                console.info("New signup, refreshing user")
                                token = await firebaseUser.getIdTokenResult(true)
                                // Hard refresh the page to pick up user changes. Router.go() doesnt work
                                window.location = '/'
                            }
                            handleUserChange.call(this, firebaseUser, token)
                        });
                    } else {
                        handleUserChange.call(this, firebaseUser, token);
                    }
                }, error => {
                    console.error("Could not get user token", error);
                });
            } else {
                console.info("Auth service: onAuthStateChanged no user - will use default user");
                // Emit a default user value to subscribers via loggedUser
                service.loggedUser.next(getDefaultUser());
            }
        });

        

        
    },
    signInWithEmailAndPassword: (email, password) => {
        // returns a promise
        return signInWithEmailAndPassword(auth, email, password);
    },
    signInWithCustomToken: (token) => {
        console.info('Signing in with custom token')
        return signInWithCustomToken(auth, token)
    },
    signOut: async (stay) => {
        if(!auth || !auth.currentUser) {
            console.debug("Auth service: No user to sign out");
            return;
        }
        let userId = auth.currentUser.uid;
        let redirectToHome = !stay;

        try {
            await signOut(auth)
            console.log("Auth service: Signed Out ", userId, redirectToHome);
        } catch (error) {
            console.error("Sign Out Error", error);
        } finally {
            if (redirectToHome) {
                window.location.href = "https://earthblox.io";
            }
        }
    },
    isUserLoggedIn: () => {
        if (auth?.currentUser) {
            if (auth.currentUser.emailVerified) {
                return true;
            } else {
                return false;
            }
        } else {
            return false
        }
        //return auth.currentUser != null;
    },
    isUserAssignedOrg: async () => {
        if (auth?.currentUser) {
            const token = await auth.currentUser.getIdTokenResult(true)
            if (token.claims?.orgId) {
                return true;
            } else {
                return false;
            }
        }
        return false
    },
    updateProfile: user => {
        let promise = updateProfile(auth.currentUser, user);
        promise.then(() => {
            // Update the logged user BehaviourSubject with new values
            let loggedUser = service.loggedUser.value;
            loggedUser.displayName = user.displayName;
            loggedUser.photoURL = user.photoURL;
        });
        return promise;
    },
    updateEmail: async (currentPassword, newEmail) => {
        const credential = EmailAuthProvider.credential(
            auth.currentUser.email,
            currentPassword
        );
        await reauthenticateWithCredential(auth.currentUser, credential);
        await updateEmail(auth.currentUser, newEmail);

        // Update the logged user BehaviourSubject with new values
        let loggedUser = service.loggedUser.value;
        loggedUser.email = newEmail;

        // note that you cannot set email verified to true with this type of non admin client
        // auth.currentUser.updateProfile({ emailVerified: 1}); Doesn't work!
        // so resort to sending a verification email
        await sendEmailVerification(auth.currentUser);

        return newEmail;
    },
    updatePassword: async (currentPassword, newPassword) => {
        const credential = EmailAuthProvider.credential(
            auth.currentUser.email,
            currentPassword
        );
        await reauthenticateWithCredential(auth.currentUser, credential);
        return updatePassword(auth.currentUser, newPassword);
    },
    refreshCurrentUser: async () => {
        const token = await getIdTokenResult(auth.currentUser, true)
        handleUserChange.call(this, auth.currentUser, token)
    },
    /**
     * Reload the current authed user. This will log them out if they have been disabled.
     */
    checkUserAuth: async () => {
        await reloadUser(auth.currentUser)
    },
    resetPassword: (emailAddress) => {
        var actionCodeSettings = {
            // After password reset, the user will be give the ability to go back to this page.
            url: `${window.location.origin}/#/signin`,
            handleCodeInApp: false,
        };
        return sendPasswordResetEmail(auth, emailAddress, actionCodeSettings);
    },
    firestoreUser: async () => {
        let coll = collection(db, 'users')
        let ref = firestoreDoc(coll, auth.currentUser.uid);
        let userDoc = await getDoc(ref);
        return userDoc
    },
    getExportQuotas: async () => {
        const quotas = {
            geoTIFFDownloadQuotaRemaining: 0,
            vectorDownloadQuotaRemaining: 0
        }
        try {
            let firebaseUser = await AuthService.firestoreUser()
            const firebaseData = firebaseUser.data()
            for (let key in quotas) {
                if (firebaseData[key]) {
                    if(Number.isInteger(firebaseData[key])){
                        quotas[key] = firebaseData[key]
                    } else {
                        console.warn(`${firebaseData[key]} is not a number.`)
                    }
                }
            }
        } catch (err) {
            console.warn(`Quota not fetchable Message:${err}`);
            return quotas
        }
    },

    resetSessionTimeout: () => {
        let timeoutId = null;

        const sessionTimeout = () => {
            timeoutId = setTimeout(() => {
                AuthService.signOut()
            }, 1800000); //After 30 minutes of inactivity, the user will be logged out and redirected to the home page.
        };

        const reset = () => {
            clearTimeout(timeoutId);
            sessionTimeout();
        };

        reset();
    },

    /**
     * Set up a promise that waits for a valid user to be logged in.
     * This is used to delay the router navigation and provide flow control for async user setup.
     * The Flow:
     * 1. AuthService.init() is called in main.js
     * 2. AuthService.init() calls AuthService.setupWaitPromise()
     * 3. AuthService.init() sets up an observer on the loggedUser BehaviorSubject
     * 4. App.vue is mounted and the router is created
     * 5. App.vue listens loggedUser$ observable which is always triggered because of the default user & BehaviorSubject
     * 6. If the router routes to an authenticated route, waits for the waitForValidUser promise to resolve
     * 6. App.vue If has a valid user sets up the required vuex store data and resolves the waitForValidUser promise
     * 7. Router continues to the authenticated route
     */
    setupWaitPromise() {
        service.waitForValidUser = new Promise((resolve, reject) => {
            service.currentPromiseResolution = {resolve, reject}
        })
    },
    async handleAuthSetup(setupPromise) {
        try {
            const user = await setupPromise();
            if(user.uid !== null) {
                service.currentPromiseResolution.resolve();
                service.waitForValidUser = null;   
            } else {
                console.debug('User not logged in yet, waiting for user to be logged in');
            }
        } catch (error) {
            service.currentPromiseResolution.reject(error);
        }
    },

    // Expose an AuthService property which is an Observable on the loggedUser.
    // Observers can subscribe to this, but cannot emit new values to it.
    loggedUser$: service.loggedUser.asObservable(),
    waitForValidUser$: () => {
        return service.waitForValidUser
    }, 
    
}

export {
    AuthService
}
