import { defaultStorageBucket } from "@/firebase.js";
import { ref as storageRef, listAll } from "firebase/storage";

export default {
    data: () => {
        return {
            prohibitedAssets: [
                'snbs/KeyBiodiversityAreas',
                'snbs/KeyBiodiversityAreasSimplified',
                'snbs/world_database_protected_areas',
                'snbs/world_database_protected_areas_simplified_mk2',
                'snbs/images'
            ]
        }
    },
    computed: {
        userAssetsOperations() {
            return this.$store.state.userassets.userOperations;
        },
        userAssetsSuccessStates() {
            return this.$store.state.userassets.successStates;
        },
        userAssetsFailedStates() {
            return this.$store.state.userassets.failedStates;
        }
    },
    methods: {
        /**
         * Gets the users exports from the storage path of the user
         * 
         * Returns an array of objects with the following properties:
         * - name: the name of the export
         * - fullPath: the full path to the export
         * - href: the href to the export
         * - fileDownloaded - boolean indicating if the file has been downloaded
         * - runDocId - the id of the run document that the export belongs to
         * 
         * @param {String} userStoragePath
         * @returns {Promise} resolving to an array of the user exports
         */
        getUserExports(userStoragePath) {
            return new Promise((resolve, reject) => {
                const userExports = [];
                // Default storage bucket
                const userStorage = storageRef(defaultStorageBucket, userStoragePath)
                listAll(userStorage)
                    .then((result) => {
                        const getFilesPromises = result.prefixes.map((runDocRef) => {
                            return listAll(runDocRef).then((files) => {
                                if (files.items.length > 0) {
                                    files.items.forEach((file) => {
                                        userExports.push({
                                            name: file.name,
                                            fullPath: file.fullPath,
                                            href: "/",
                                            fileDownloaded: false,
                                            runDocId: runDocRef.name,
                                        });
                                    });
                                }
                            });
                        });

                        Promise.all(getFilesPromises)
                            .then(() => {
                                resolve(userExports);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        /**
         * Process the uploaded assets and return a filtered list of assets.
         *
         * @param {Array} assets - The list of assets to process.
         * @returns {Array} The filtered list of assets.
         */
        processUploadedAssets(assets) {
            let uploadedAssets = [];
            assets.forEach((entry) => {
                const entryParts = entry.name.split('/');
                const entryId = entryParts.slice(3, entryParts.length).join('/');
                if (!this.prohibitedAssets.includes(entryId)) {
                    const processedName = entry.name.split('/');
                    const assetData = {
                        id: entry.id,
                        name: processedName[processedName.length - 1],
                        type: entry.type
                    };
                    uploadedAssets.push(assetData);
                }
            });

            return uploadedAssets;
        }
    }
}