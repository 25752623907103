export default {
    data() {
        return {
            filterOptionKeyword: '',
        }
    },
    computed: {
        userDatasets() {
            return this.$store.state.userdatasets.userDatasets;
        },
        datasetsMetadata() {
            return this.$store.state.userdatasets.userDatasetsMetadata.concat(this.$store.state.userdatasets.orgDatasetsMetadata);
        },
        searchBarFilterOptions() {
            const filterOptionsKeyword = this.filterOptionKeyword || 'datasets'
            const options = [{
                value: 'all',
                label: 'All '+filterOptionsKeyword
            }]
            if (this.canShareWorkflows) {
                options.push({
                    value: 'org',
                    label: 'Team '+filterOptionsKeyword
                })
            }
            options.push({
                value: 'user',
                label: 'My '+filterOptionsKeyword
            })
            return options
        },
        featureCollectionMetadata() {
            return this.datasetsMetadata.filter(asset => asset.status === 'COMPLETED' && asset.type === 'FeatureCollection');
        },
        imageCollectionMetadata() {
            return this.datasetsMetadata.filter(asset => asset.status === 'COMPLETED' && asset.type !== 'FeatureCollection'); // Type could be empty for image collections
        },
        userDatasetsLoading() {
            return this.$store.getters['userdatasets/datasetsLoading'];
        }
    },
    methods: {
        userDatasetsHasLoadedImages(datasetId) {
            return this.$store.getters['userdatasets/hasDatasetImages'](datasetId);
        },
        userDatasetsFormState(key){
            return this.$store.getters['userdatasets/getFormState'](key);
        },
        userDatasetsResetFormState(key){
            this.$store.commit('userdatasets/resetSaveState', key);
        },
        userDatasetExists(id) {
            return this.$store.getters['userdatasets/datasetExists'](id)
        },
        userDatasetLocation(id) {
            return this.$store.getters['userdatasets/getDatasetLocation'](id)
        },
        userDatasetCanEdit(id, uid) {
            return this.$store.getters['userdatasets/canEditDataset'](id, uid)
        },
        userDatasetsLoad(orgId, uid) {
            const promises = [
                this.$store.dispatch('userdatasets/queryOrgDatasetsMetadata', orgId),
                this.$store.dispatch('userdatasets/queryUserDatasetsMetadata', uid),
                this.$store.dispatch('userdatasets/queryUserDatasets', {
                    userId: uid, 
                    orgId: orgId
                })
            ]
            return Promise.all(promises)
        },
        userDatasetsProperties(assetId) {
            const dataset = this.$store.getters['userdatasets/getDisplayDataset'](assetId)
            if (dataset && Array.isArray(dataset.gee_schema)) {
                return dataset.gee_schema
            }
            return []
        },
    }
}