<template>
    <md-dialog v-model:md-active="showImageUploadDialog" :md-click-outside-to-close="false" class="image-upload ebx-dialog modal-m">
    <span class="md-dialog-title md-title">
        <div class="top-actions--container">
            <div class="title--container">
                <span>Upload Image</span>
            </div>
            <div class="close-button">
                <md-button class="md-primary" @click="cancelUpload"><md-icon>close</md-icon></md-button>
            </div>
        </div>
    </span>
    <md-dialog-content class="dialog-content">
        <md-content class="content">
            <div class="upload-container">
                <div
                    id="drop_zone"
                    class="upload-container--drop-zone"
                    :class="{'upload-container--drop-zone--has-files': filesToUpload.length > 0 && metaDataLoaded}"
                    @drop="dropHandler($event)"
                    @dragover="dragOverHandler($event)"
                >
                    <div v-if="filesToUpload.length === 0 || errorIdentified === true" class="upload-container--drop-zone--no-files">
                        <p class="ebx-primary">Drag and drop a GeoTIFF file. To create an image mosaic, drag and drop multiple files.</p>
                        <EbxButton theme="tertiary" class="no-margin no-padding" @click="triggerFileSelection">CHOOSE FILE</EbxButton>
                    </div>
                    <template v-else>
                        <ul  v-if="metaDataLoaded" class="list-container"> 
                            <li v-for="(file, index) in filesToUpload" :key="index" class="list-item">
                                <span class="info">{{ file.name }}</span>
                                <md-button class="md-icon-button" @click.stop="isMetaLoading(file.name) ? () => {} : removeFile(index)">
                                    <md-progress-spinner v-if="isMetaLoading(file.name)" class="md-accent" md-mode="indeterminate" :md-diameter="20" :md-stroke="2"></md-progress-spinner>
                                    <md-icon v-else>delete</md-icon>
                                </md-button>
                            </li>
                        </ul>
                        <template v-else>
                            <p class="ebx-primary mb-4">Checking Files ({{ metaDataLength }}/{{ filesToUpload.length }})</p>
                            <div class="process-container">
                                <md-progress-bar class="md-accent" :md-value="metaDataProgress" ></md-progress-bar>
                            </div>
                        </template>
                        
                    </template>
                    
                    <input
                        v-show="false"
                        ref="fileInput"
                        class="ebx-button md-button-secondary md-accent"
                        :accept="acceptedTypes"
                        multiple
                        type="file" 
                        @change="checkFiles($event);" 
                    />

                </div>
            </div>
            <div class="error-container">
                <div v-if="errorIdentified === true" class="md-error">All files must have the same bands and attributes and projection to create an image mosaic.</div>
            </div>
            <form v-if="filesToUpload.length > 0 && metaDataLoaded && errorIdentified===false" class="mt-20" @submit.prevent="startUpload">
                <md-field> 
                    <label>Name</label>
                    <md-input v-model="name">{{name}}</md-input>
                </md-field>
                <p class="md-error" v-if="v$.name.$invalid">A name is required</p>

                <h2>Image Date</h2>
                <md-radio v-model="imageType" value="point">
                    Point in time
                    <p class="imageTypeLabel muted">Image taken on a specific day or time</p>
                </md-radio>

                <div v-if="imageType=='point'" class="radio-options-container">
                    <div class="radio-options-container--column">
                        <div class="first">
                            <md-datepicker v-model="selectedDate" @md-closed="showSelectedDateErrors = true"/>
                            <p class="md-error" v-if="v$.selectedDate.$invalid && (showHiddenErrors || showSelectedDateErrors)">A valid date is required</p>
                        </div>
                    </div>
                </div>

                <md-radio v-model="imageType" value="period">
                    Period of time
                    <p class="imageTypeLabel muted">Combined images representing a time period</p>
                </md-radio>
                <div v-if="imageType=='period'" class="radio-options-container">
                    <div class="radio-options-container--column">
                        <div class="first">
                            <div class="date-label">
                                <label for="FromDatepicker" class="ebx-primary">From</label>
                            </div>
                            <md-datepicker id="FromDatepicker" v-model="selectedFromDate" @md-closed="showSelectedFromDateErrors = true"/>
                        </div>
                        <div class="last">
                            <div class="date-label">
                                <label for="ToDatepicker" class="ebx-primary">To</label>
                            </div>
                            <md-datepicker id="ToDatepicker" v-model="selectedToDate" @md-closed="showSelectedFromDateErrors = true"/>
                        </div>
                    </div>
                    <p class="md-error" v-if="v$.selectedFromDate.date.$invalid && (showHiddenErrors || showSelectedFromDateErrors)">From date must be before to date</p>
                    <p class="md-error" v-if="(v$.selectedFromDate.requiredIfPeriod.$invalid || v$.selectedToDate.requiredIfPeriod.$invalid ) && (showHiddenErrors || showSelectedFromDateErrors)">Select a date range</p>
                </div>
            </form>
            <div v-if="isLoading" class="process-container mt-20">
                <md-progress-bar class="md-accent" :md-value="uploadProgress"></md-progress-bar>
             </div>
        </md-content>
    </md-dialog-content>
    <md-dialog-actions>
        <EbxButton theme="secondary" @click="cancelUpload">Cancel</EbxButton>
        <EbxButton :disabled="!canUpload || anythingLoading" :allow-disabled-clicks="true" :loading="anythingLoading" @click="startUpload">Upload</EbxButton>
      </md-dialog-actions>
    </md-dialog>
</template>

<script>

/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/

import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf, minLength } from '@vuelidate/validators'
import { doc as firestoreDoc, getDoc, setDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { usersCollection, unscannedBucketRef } from "@/firebase.js";
import { ref as storageRef, uploadBytesResumable } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import { AuthService } from "@/services/auth.service";
import {GeotiffFileHandler} from '../scripts/GeotiffFileHandlers'
import moment from 'moment'
import valueMixin from '@/components/mixins/valueMixin'

export default {
    name: "ImageUpload",
    mixins: [valueMixin],
    setup: () => ({ v$: useVuelidate() }),
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        "update:modelValue"
    ],
    data: () => ({
        isDialogVisible: false,
        selectedDate: new Date(),
        selectedToDate: new Date(),
        selectedFromDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString(),
        name: "",
        content: "",
        imageType: null,
        filesToUpload: [],
        fileMeta: {},
        allowedExtensions: [".tif", ".tiff"],
        acceptedTypes: "",
        user: null,
        loadingImages: false,
        isLoading: false,
        metaDataConcurrency: 5,
        uploadConcurrency: 5,
        maxUploadAttempts: 3,
        fileUploadTimeout: 30000, // 30 seconds
        uploadStats: {
            fileCount: 0,
            uploading: 0,
            uploaded: 0
        }, 
        errorIdentified: false,
        showHiddenErrors: false,
        showSelectedDateErrors: false,
        showSelectedFromDateErrors: false
    }),
    watch: {
        filesToUpload: {
            handler: async function() {
                if (this.filesToUpload.length === 0) {
                    this.name = "";
                    this.loadingImages = false;
                } else {
                    try {
                        this.loadingImages = true;
                        await this.processMetaForUploadedFiles();
                    } finally {
                        this.loadingImages = false;
                    }
                }
            },
            deep: true 
        },
        showImageUploadDialog() {
            this.cleanUp();
        },
    },
    computed: {
        userRef() {
            return this.user.uid ? firestoreDoc(usersCollection, this.user.uid) : null;
        },
        showImageUploadDialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            }
        },
        canUpload() {
            return this.filesToUpload.filter(file => {
                return this.checkFileMetaDataIsValid(file.name).length === 0;
            }).length > 0 && !this.v$.$invalid;
        },
        anythingLoading() {
            return this.loadingImages || this.isLoading;
        },
        firstMetaData() {
            const keys = Object.keys(this.fileMeta);
            return this.fileMeta[keys[0]];
        },
        metaDataLength() {
            return Object.keys(this.fileMeta).length;
        },
        metaDataLoaded() {
            return this.metaDataLength === this.filesToUpload.length;
        },
        metaDataProgress() {
            return this.metaDataLength / this.filesToUpload.length * 100;
        },
        uploadProgress() {
            return this.uploadStats.uploaded / this.uploadStats.fileCount * 100;
        },
    },
    mounted() {
        this.subscription = AuthService.loggedUser$.subscribe((user) => {
            this.user = user;
        });
        this.acceptedTypes = this.allowedExtensions.join(",");
    },
    methods: {
        isMetaLoading(fileName) {
            return this.fileMeta[fileName] && this.fileMeta[fileName].loading;
        },
        isMetaUploaded(fileName) {
            return this.fileMeta[fileName] && this.fileMeta[fileName].uploaded === true;
        },
        checkFileMetaDataIsValid(fileName) {
            const errors = [];
            if (this.isMetaLoading(fileName) || this.isMetaLoading(this.filesToUpload[0].name)) {
                this.errorIdentified = false;
                return errors;
            }
            if (this.fileMeta[fileName] === undefined) {
                this.errorIdentified = false;
                return []
            }
            const metaDataToCheck = this.fileMeta[fileName];
            const firstMeta = this.firstMetaData;
            if (firstMeta.espg !== metaDataToCheck.espg) {
                errors.push(`The ESPG code does not match`);
                this.errorIdentified = true;
            }
            if (JSON.stringify(firstMeta.bands) !== JSON.stringify(metaDataToCheck.bands)) {
                errors.push(`The number of bands does not match`);
                this.errorIdentified = true;
            }
            return errors;
        },
        async processMetaForUploadedFiles() {
            let pendingFiles = this.filesToUpload.slice(0)

            const loadMeta = async file => {
                if (this.fileMeta[file.name]) {
                    if (pendingFiles.length > 0) {
                        return await loadMeta(pendingFiles.shift());
                    }else{
                        return false
                    }
                }
                var handle = async () => {
                    const handler = new GeotiffFileHandler(file);
                    this.fileMeta[file.name] = {
                        loading: true
                    }
                    this.fileMeta[file.name] = {
                        loading: false,
                        uploaded: false,
                        espg: await handler.getESPG(),
                        bands: await handler.getBands(),
                    }
                    handler.close()
                };
                await handle();
                if (pendingFiles.length > 0) {
                    return await loadMeta(pendingFiles.shift());
                }
            }

            const concurrency = Math.min(pendingFiles.length, this.metaDataConcurrency)
            const toRun = pendingFiles.slice(0, concurrency);
            pendingFiles = pendingFiles.slice(concurrency);

            return await Promise.all(toRun.map(file => loadMeta(file)));

        },
        closeDialog() {
            this.showImageUploadDialog = false;
        },
        showDialog() {
            this.showImageUploadDialog = true;
        },
        removeFile(index) {
            this.filesToUpload.splice(index, 1);
        },
        triggerFileSelection() {
            this.$refs.fileInput.click();
        },
        dropHandler(event) {
            console.log("File(s) dropped");
            // Prevent default behavior (Prevent file from being opened)
            event.preventDefault();
            if (event.dataTransfer.items) {
                // Use DataTransferItemList interface to access the file(s)
                [...event.dataTransfer.items].forEach((item, i) => {
                    // If dropped items aren't files, reject them
                    if (item.kind === "file") {
                        const file = item.getAsFile();
                        this.checkFiles(event, [file]);
                        console.log(`… file[${i}].name = ${file.name}`);
                    }
                });
            }
            else {
                // Use DataTransfer interface to access the file(s)
                [...event.dataTransfer.files].forEach((file, i) => {
                    this.checkFiles(event, [file]);
                    console.log(`… file[${i}].name = ${file.name}`);
                });
            }
        },
        dragOverHandler(event) {
            // Prevent default behavior (Prevent file from being opened)
            event.preventDefault();
        },
        checkFileName(fileName) {
            if (fileName == "") {
                console.error("Error -> no filename entered");
            }
        },
        getFileExtension(filename) {
            return filename.substring(filename.lastIndexOf("."), filename.length) || filename;
        },
        checkFiles(event, files) {
            if (!files) {
                files = event.target.files;
            }
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                let extension = this.getFileExtension(file.name);
                if (!this.allowedExtensions.includes(extension)) {
                    console.info("Extension check fails");
                }
                else {
                    this.filesToUpload.push(file);
                    this.checkFileName(file.name);
                    this.name = file.name.substring(0, file.name.lastIndexOf(".")) || file.name;
                }
            }
        },
        async createOperationDoc(filesToUpload, uploadJob) {
            let docId = `${this.user.uid}_${uploadJob}`;
            // check if an operation doc for this upload already exists
            // if it does then raise error as filename is not unique
            let docRef = firestoreDoc(this.userRef, "operations", docId);
            let docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists()) {
                console.warn("Operation document already exists.");
                return;
            }
            // TODO replace with psuedo state enum
            let currentState = "UPLOADING";
            await setDoc(docRef, {
                files: filesToUpload.map((file) => file.name),
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
                state: currentState,
                submittedBy: this.user.uid,
                task_type: "INGEST_IMAGE",
                formData: {
                    name: this.name,
                    selectedDate: moment(this.selectedDate).toDate(), // convert date or string to date
                    startDate: moment(this.selectedFromDate).toDate(),  // convert date or string to date
                    endDate: moment(this.selectedToDate).toDate(),  // convert date or string to date
                    imageType: this.imageType,
                },
                hasDismissedAssetNotification: {
                    isUploading: true,
                    isFinished: false,
                }
            });
            return docRef;
        },
        async uploadToStorageBucket(fileList, storagePath) {
            this.uploadStats.fileCount = fileList.length;
            // function that uploads file list to storage bucket
            let pendingFiles = fileList.slice(0).map((file,i) => {
                return {
                    fileIndex: i,
                    attempts: 0,
                    error: null,
                    progress: 0
                }
            })

            const uploadFile = async (fileData) => {
                const file = fileList[fileData.fileIndex];
                this.uploadStats.uploading += 1;
                let fileName = file.name;
                if (fileName.indexOf(" ") >= 0) {
                    fileName = fileName.replaceAll(" ", "_");
                }
                try {
                    
                    await new Promise((resolve, reject) => {
                        const handleTimeout = () => {
                            reject("upload taking too long (cancelled): "+ file.name)
                        }
                        fileData.timer = setTimeout(handleTimeout,this.fileUploadTimeout)
                        const bucket = storageRef(unscannedBucketRef, `${storagePath}/${fileName}`);
                        fileData.task = uploadBytesResumable(bucket, file)
                        fileData.task.on(
                                "state_changed", 
                                snapshot => {
                                    console.log("uploading file", file.name, snapshot.state)
                                    fileData.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                    clearTimeout(fileData.timer)
                                    fileData.timer = setTimeout(handleTimeout,this.fileUploadTimeout)
                                },
                                (err) => {
                                    clearTimeout(fileData.timer)
                                    reject(err)
                                }, 
                                () => {
                                    clearTimeout(fileData.timer)
                                    this.fileMeta[file.name].uploaded = true;
                                    resolve()
                                }
                            );
                    });

                    this.uploadStats.uploaded += 1;
                    this.uploadStats.uploading -= 1;
                } catch (error) {
                    if(fileData.task) {
                        fileData.task.cancel()      
                    }
                    this.uploadStats.uploading -= 1;
                    if (fileData.attempts >= this.maxUploadAttempts) {
                        console.error("Failed to upload file after "+this.maxUploadAttempts+" attempts");
                        throw error;
                    }else {
                        console.error("Failed to upload file", error);
                        fileData.attempts += 1;
                        fileData.error = error;
                        pendingFiles.push(fileData)
                    }
                }
                
                if (pendingFiles.length > 0) {
                    return await uploadFile(pendingFiles.shift());
                }
            }

            const concurrency = Math.min(pendingFiles.length, this.uploadConcurrency)
            const toRun = pendingFiles.slice(0, concurrency);
            pendingFiles = pendingFiles.slice(concurrency);
            return await Promise.all(toRun.map(mapFile => uploadFile(mapFile)));

        },
        async uploadFiles() {
            if (!this.canUpload) {
                return;
            }
            let uploadJob = uuidv4();
            let storagePath = `${this.user.orgId}/${this.user.uid}/${uploadJob}`;
            this.isLoading = true;
            const operationDocRef = await this.createOperationDoc(this.filesToUpload, uploadJob);
            try {
                await this.uploadToStorageBucket(this.filesToUpload, storagePath);
                await updateDoc(operationDocRef, {
                    updatedAt: serverTimestamp()
                });
            }
            catch (file) {
                console.error("File failed to upload", file);
                updateDoc(operationDocRef, {
                    state: "ERROR",
                    updatedAt: serverTimestamp()
                });
            }
            finally {
                this.isLoading = false;
                this.closeDialog();
            }
        },
        async startUpload() {
            this.v$.$touch();
            this.showHiddenErrors = true
            if (this.v$.$invalid) {
                return;
            }
            if (this.canUpload) {
                await this.uploadFiles();
                this.isDialogVisible = false;
            }
        },
        cancelUpload() {
            this.closeDialog();
        },
        cleanUp() {
            this.filesToUpload = [];
            this.fileMeta = {};
            this.name = "";
            this.imageType = null;
            this.selectedDate = new Date();
            this.selectedFromDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().split('T')[0];
            this.selectedToDate = new Date();
            this.showHiddenErrors = false;
            this.showSelectedDateErrors = false
            this.showSelectedFromDateErrors = false
            this.uploadStats = {
                fileCount: 0,
                uploading: 0,
                uploaded: 0
            }
        },
    },
    validations () {
        return {
            name: {
                required
            },
            imageType: {
                required
            },
            selectedDate: {
                requiredIfPoint: requiredIf(this.imageType === "point"),
                date: function (value) {
                    if (this.imageType === "period") {
                        return true;
                    }
                    if (value == null) {
                        return false;
                    }
                    return value instanceof Date;
                }
            },
            selectedFromDate: {
                requiredIfPeriod: requiredIf(this.imageType === "period"),
                date: function (value) {
                    if (this.imageType === "point") {
                        return true;
                    }
                    if (value == null) {
                        return false;
                    }
                    if (this.selectedToDate !== null) {
                        const fromDate = new Date(value);
                        const toDate = new Date(this.selectedToDate);
                        return fromDate < toDate;
                    }
                    return value instanceof Date;
                }
            },
            selectedToDate: {
                requiredIfPeriod: requiredIf(this.imageType === "period"),
                date: function (value) {
                    if (this.imageType === "point") {
                        return true;
                    }
                    if (value == null) {
                        return false;
                    }
                    return value instanceof Date;
                }
            },
            filesToUpload: {
                minLength: minLength(1)
            }
        }
    }
}
</script>
