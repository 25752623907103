<template>
    <div>
        <FilesSubform :file-data="fileData" :disabled="disabled" :stac-id="stacId" :valid-bands="validBands" @update:file-data="handleFilesChanged" />
        <slot name="afterForm"></slot>
        <ImageDateSelection v-if="fileData.files.length > 0" :form-data="formData" @update:formData="handleFormChange"/>
    </div>
</template>

<script>
import FilesSubform from './FilesSubform.vue';
import ImageDateSelection from './ImageDateSelection.vue';

export default {
    props: {
        fileData: {
            type: Object,
            default: () => ({files: [], meta: {}})
        },
        formData: { 
            type: Object,
            default: () => ({})
        },
        disabled: {
            type: Boolean,
            default: false
        },
        stacId: {
            type: [String, null],
            default: null
        },
        validBands: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            validForm: false
        }
    },
    emits: [
        'update:fileData',
        'formValid',
        'update:formData'
    ],
    components: {
        FilesSubform,
        ImageDateSelection
    },
    methods: {
        handleFilesChanged(fileData) {
            this.$emit('update:fileData', fileData);
            this.emitValidForm();
        },
        handleFormChange(formData, isFormValid) {
            this.$emit('update:formData', formData);
            this.validForm = isFormValid;
            this.emitValidForm();
        },
        emitValidForm() {
            const areFilesValid = this.fileData.files.length > 0;
            const isValid = this.validForm && areFilesValid;
            this.$emit('formValid', isValid);
        }
    }
}
</script>