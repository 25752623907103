<template>
    <div>
        <div class="blockly-div" ref="blocklyDiv" id="blockly-workspace"></div>
    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
/**
 * @license
 *
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @fileoverview Blockly Vue Component.
 * @author samelh@google.com (Sam El-Husseini)
 */

import Blockly from "blockly";
import { toolboxXML} from "../blocks/toolbox";
import { NavigationController } from '@blockly/keyboard-navigation';
import {CONFIG_TYPE} from "@/config/constants";
import { validateWorkspace } from "@/blocks/workspace_validation";
import { ImageService } from "@/services/image.service";
import {CaptureStateVisitor} from '../blocks/visitors/CaptureStateVisitor';
import {PopulateStateVisitor} from '../blocks/visitors/PopulateStateVisitor';
import {DefaultStateVisitor} from '../blocks/visitors/DefaultStateVisitor';
import {SubscribeWorkflowVisitor} from '../blocks/visitors/SubscribeWorkflowVisitor';
import {NEXT_GEN_BLOCKS,NEXT_GEN_TOP_BLOCKS} from '../constants/nextGenConstants'
import { WorkflowState } from '../workflow/state'
import {EBXZoomToFitControl} from "@/blocklyZoomToFit";
import { DatasetService } from "../services/dataset.service";
import {rebindWorkspacePaste} from "@/blocks/clipboard";
import {EventFinished} from '@/events/event_finished'
import authMixin from '../components/mixins/authMixin'
import { registerBlocklyContextMenuOption } from "@/contextMenus";
import { VariablesService } from "../services/variable.service";
import valueMixin from "@/components/mixins/valueMixin";
import {toRaw} from 'vue'

export default {
    name: "BlocklyComponent",
    props: ["modelValue", "loadWorkflow",'workflowRegistry','runData','globalDatasetService'],
    emits: [
        'load-json',
        'newWorkspace',
        'toolbox-width-change',
        'is-empty',
        'run-workflow-subset',
        'stop-workflow',
        'change',
        'update:modelValue'
    ],
    mixins: [authMixin, valueMixin],
    data() {
        return {
            options: {
                media: "media/",
                toolbox: ``,
                sounds: true,
                scrollbars: true,
                horizontalLayout: false,
                trashcan: true,
                renderer: 'colours_renderer',
                theme: '',
                grid:{
                    spacing: 20,
                    length: 0,
                    snap: true
                },
                zoom: {
                    controls: true,
                    wheel: true, // false if we don't want zoom to happen when using mouse scroll
                    startScale: 0.8,
                    maxScale: 2,
                    minScale: 0.3,
                    scaleSpeed: 1.2,
                    pinch: true
                },
                move:{
                    scrollbars: {
                        horizontal: true,
                        vertical: true
                    },
                drag: true,
                wheel: false // true if we want to move when we use mouse scroll
                }
            },
            hasDownload: this.downloadsEnabled,
            toolboxDownloadContent: "",
            uiEvents: [],
            workflowStackRunning: false,
            draggingBlocks: false,
            navigationController: null,
            isProcessingVisitor: false,
            blocklyStackError: null,
            stackedEvents: {
                stack: [],
                lastEvent: null
            },
            popVisitorRunning: false,
            localBlockData: [],
        };
    },
    watch: {
        // Watcher on toolboxContent; when it updates, the toolbox XML is updated
        toolboxContent: function () {
            console.info("Toolbox content updated; updating toolbox XML");
            this.insertToolbox();
        },
        runData: {
            deep: true,
            handler(newRunData) {
                if(Blockly.getMainWorkspace() && newRunData.blocks.length > 0){
                    this.workflowRegistry.setRunState(newRunData)
                    setTimeout(() => {
                        this.updateLblockStates(newRunData.blocks[0].id)
                    }, 0) 
                }
            }
        },
        modelValue: {
            deep: true,
            immediate: true,
            handler(newBlockData) {
                this.localBlockData = newBlockData;
            }
        },
        localBlockData: {
            deep: true,
            handler(newBlockData) {
                if(newBlockData !== this.modelValue){
                    this.$emit('update:modelValue', newBlockData);
                }
            }
        }
    },
    mounted() {
        this.options.theme = Blockly.Themes.NextGen;

        if (!this.user || !this.user.uid) {
            return;
        }

        // computed object so first time watcher doesn't trigger so we trigger on mount
        this.insertToolbox();

        if (this.canLoadJson) {
            registerBlocklyContextMenuOption(
                "LOAD JSON",
                () => this.$emit("load-json"),
                () => this.canLoadJson ? 'enabled' : 'hidden', // handles the case when user loads the workspace as an admin, but then has admin rights revoked
                undefined,
                "load-json"
            )
        }

        this.datasetSubscription = DatasetService.serviceUpdated$.subscribe(() => {
            const blockIds = Blockly.getMainWorkspace().getTopBlocks();

            blockIds.forEach((id) => {
                try {
                    const block = Blockly.getMainWorkspace().getBlockById(id);
                    const event = new Blockly.Events.BlockChange(block);
                    this.onWorkspaceChange(event)
                } catch {
                    console.warn("Failed to update", id, "with new datasets")
                }
                
            })
        })

        this.globalDatasetService.subscribe((_, provider) => {
            if (provider !== this.workflowRegistry) {
                this.onWorkspaceChange({type: 'change', blockId: 'global_dataset_update'})
            }
        })

    },
    beforeUnmount() {
        this.areasubscription?.unsubscribe();
        this.datasetSubscription?.unsubscribe();
        if(this.navigationController) {
            this.navigationController.dispose()
        }
    },
    methods: {
        insertToolbox() {
            if (!this.toolboxContent) {
                console.info("toolboxContent not yet loaded; not rendering toolbox");
                return;
            }

            const xmlStr = Blockly.utils.xml.textToDom(this.toolboxContent);

            this.options.toolbox = xmlStr;

            // inject toolbox from file if db call fails
            var options = this.options || {};
            if (!options.toolbox) {
                options.toolbox = toolboxXML
            }

            // inject toolbox into workspace
            const workspace = Blockly.inject('#blockly-workspace', options);

            workspace.addChangeListener(this.onWorkspaceChange);
            this.popVisitorRunning = false;
            ImageService.registerWorkspace(workspace);
            rebindWorkspacePaste(Blockly, workspace)

            // register button action to open the trial request link
            if(!workspace.getButtonCallback('registerWithEBX')) {
                workspace.registerButtonCallback('registerWithEBX', function() {
                    window.open("https://blox.earth/#demo");
                });
            }   
        
            // keyboad navigation plugin code
            const navigationController = new NavigationController();
            navigationController.init();
            navigationController.addWorkspace(workspace);
            this.navigationController = navigationController
            // EO --- keyboad navigation plugin code

            // zoom to fit plugin injection
            const zoomToFit = new EBXZoomToFitControl(workspace);
            zoomToFit.init();
            // EO --- zoom to fit plugin injection

            this.$emit("newWorkspace", workspace);
        },
        /** Updates the local block data so we can pass it back to Workflow Component */
        updateLocalBlockData(event) {
            if(event.type === Blockly.Events.BLOCK_CREATE || event.type === Blockly.Events.BLOCK_DELETE || event.type === Blockly.Events.BLOCK_MOVE) {
                const workspace = Blockly.getMainWorkspace();
                const topBlocks = workspace.getTopBlocks();
                this.localBlockData = topBlocks
                    .filter((block, idx) => {
                        // check block is not dragged from toolbox and has not been dropped into workspace
                        if(block.isInsertionMarker()) {
                            return false;
                        }
                        // check if next block is an insertion marker. it means this block is related and should be ignored
                        // Upon Creating a new block from the toolblox 2 blocks are created. the insertion marker and the actual block behind it.
                        if(topBlocks[idx+1] && topBlocks[idx+1].isInsertionMarker()) {
                            return false;
                        }
                        return true 
                    })
                    .map(block => {
                        const index = this.localBlockData.findIndex(b => b.id === block.id);
                        if(index === -1) {
                            return this.createBlockData(block);
                        }
                        return this.localBlockData[index];
                    });
            }
        },
        isEventPlayButtonChange(event) {
            return event?.type === 'change' && event.element === 'field' && event.name == 'run_button_label';
        },
        async onWorkspaceChange(event) {
            event = toRaw(event)
            if(this.isEventPlayButtonChange(event)) {
                // ignore change events on the play button on workflow c blocks
                return
            }
            const workspace = Blockly.getMainWorkspace();
            
            this.updateLocalBlockData(event)
            if(this.isProcessingVisitor) {
                // just debounce any events and keep the last
                if (this.popVisitorRunning === false) {
                    //this.stackedEvents.push(event)
                    this.stackedEvents.lastEvent = event
                    if(event.type === 'move' && event.newParentId && !event.oldParentId) {
                        this.stackedEvents.stack.push(event)
                    }
                }
                return
            }
            this.isProcessingVisitor = true
            let supressEventEnd = false;
           
            // to calculate empty state position in workflow.vue
            if (event.type === "toolbox_item_select") {
                this.$emit("toolbox-width-change", workspace.getToolbox().getWidth());
            }

            this.$emit('is-empty',workspace.getTopBlocks().length === 0)
            
            if(event.type === 'drag') {
                this.draggingBlocks = event.isStart
                if (workspace.getBlockById(event.blockId) &&
                    workspace.getBlockById(event.blockId).triggerVisitorOnDragStart === true && 
                    this.draggingBlocks) {
                    await this.updateLblockStates(event.blockId, event.type)
                }
            }
            if (event.type === "drag" && event.blocks.length > 0) {
                for (const block in event.blocks) {
                    if (block.type === 'modifier_mask' && block.parentBlock_) {
                        await this.updateLblockStates(event.blockId, event.type)
                    }
                }
            }

            
            // updated to the new ui events manager as per https://groups.google.com/g/blockly/c/4StIgJJXPwY/m/VSHqnqADAgAJ
            if(event.isUiEvent){
                this.uiEvents.push(event)
                if(this.uiEvents.length>2){
                    this.uiEvents.shift();
                }
                this.isProcessingVisitor = false
                if (this.stackedEvents.stack.length > 0) {
                    const nextEvent = this.stackedEvents.stack.shift()
                    this.onWorkspaceChange(nextEvent)
                }
                if(this.stackedEvents.lastEvent) {
                    const nextEvent = this.stackedEvents.lastEvent
                    this.stackedEvents.lastEvent = null
                    this.onWorkspaceChange(nextEvent)
                }
                return;
            }

            switch (event.type) {
                case "move": {
                    await this.updateLblockStates(event.blockId, event.type)
                    if (event.newParentId) {
                        if(!event.oldParentId){
                            const defaultVistior = new DefaultStateVisitor(this.workflowRegistry, this.globalDatasetService, event.blockId, workspace)
                            await workspace.getBlockById(event.blockId).accept(defaultVistior)
                            supressEventEnd = true
                        }
                    }
                    break;
                }
                case 'run_workflow': {
                    await this.updateLblockStates(event.blockId, event)
                    this.$emit('run-workflow-subset', event.blockId)
                    break;
                }
                case 'stop_workflow': {
                    this.$emit('stop-workflow', event.blockId)
                    break;
                }
                // Standard event triggers with no extra logic, just update lblock states
                case "delete":
                case "change":
                case 'workflow_finished':
                case 'finished_loading':
                case 'duplicate_finished': {
                    await this.updateLblockStates(event.blockId, event)
                    break;
                }
            }
            this.workflowRegistry.changed()
            this.isProcessingVisitor = false
            if (this.stackedEvents.stack.length > 0) {
                const nextEvent = this.stackedEvents.stack.shift()
                this.onWorkspaceChange(nextEvent)
            }else if(this.stackedEvents.lastEvent) {
                const nextEvent = this.stackedEvents.lastEvent
                this.stackedEvents.lastEvent = null
                this.onWorkspaceChange(nextEvent)
            } else {
                if(supressEventEnd === false && event.type !== Blockly.Events.BLOCK_EVENT_FINISHED && event.blockId) {
                    Blockly.Events.fire(new EventFinished(workspace.getBlockById(event.blockId), 'event_finished', event.blockId, null));
                }
            }
        },
        /**
         * The Lblocks state visitor function that controls the visitors and block states
         * - Runs on specified workspace events and kicks of block traversal, 
         * - Collects block states into a workflow state
         * - Distributes the workflow state back into the block states
         * @param {String} blockId
         */
        async updateLblockStates(blockId, currentEvent) {
            const workspace = Blockly.getMainWorkspace()

            if (this.popVisitorRunning) {
                return;
            }

            this.workflowStackRunning = true
            this.blocklyStackError = null
            let blocksToIgnore = []

            try {
                this.workflowRegistry.clear()
                this.workflowRegistry.setDragging(this.draggingBlocks)
                this.workflowRegistry.setCurrentEvent(currentEvent)
                let block = workspace.getBlockById(blockId);

                //Clear Variables extra state
                VariablesService.getVariables().forEach(variable => {
                    variable.clearExtraState()
                })
                
                // get all top blocks in workspace
                let allTopBlocks = workspace.getTopBlocks();
                if (!block || NEXT_GEN_BLOCKS.includes(block.type)) {
                    // TODO: abstract compatible block types to a list inside a const
                    let i;
                    const nextGenTopBlocks = allTopBlocks
                            .filter(topBlock => NEXT_GEN_TOP_BLOCKS.includes(topBlock.type));

                    this.workflowRegistry.resetTraversedBlocks()
                    for(i=0; i < nextGenTopBlocks.length; i++) {
                        const topBlock = nextGenTopBlocks[i]
                        this.workflowRegistry.createState(topBlock.id, WorkflowState);
                        let subscribeStateVisitor = new SubscribeWorkflowVisitor(this.workflowRegistry, this.globalDatasetService, topBlock.id);
                        await subscribeStateVisitor.traverseBlockHierarchy(topBlock);
                        // Get ids for disabled workflow blocks
                        if (topBlock.disabled) {
                            blocksToIgnore.push(...topBlock.getDescendants())
                        }
                    }

                    // Try to sort the top blocks in topological order
                    let sortedTopBlocks;
                    try {
                        sortedTopBlocks = this.workflowRegistry.topologicalSortTopBlocks(nextGenTopBlocks)
                    } catch (e) {
                        console.error('Error in topologicalSortTopBlocks', e)
                        sortedTopBlocks = nextGenTopBlocks
                        this.blocklyStackError = e
                    }
                    
                    this.workflowRegistry.resetTraversedBlocks()
                    for(i=0; i < sortedTopBlocks.length; i++) {
                        const topBlock = sortedTopBlocks[i]
                        let captureStateVisitor = new CaptureStateVisitor(this.workflowRegistry, this.globalDatasetService, topBlock.id);
                        await captureStateVisitor.traverseBlockHierarchy(topBlock);
                    }
                    this.workflowRegistry.resetTraversedBlocks()
                    for(i=0; i < sortedTopBlocks.length; i++) {
                        const topBlock = sortedTopBlocks[i]
                        let populateStateVisitor = new PopulateStateVisitor(this.workflowRegistry, this.globalDatasetService, topBlock.id);
                        
                        if (topBlock.disabled) {
                            continue
                        } else {
                            try {
                                this.popVisitorRunning = true;
                                await populateStateVisitor.traverseBlockHierarchy(topBlock);
                            } finally {
                                this.popVisitorRunning = false;
                            }
                        }
                    }
                    if(workspace){
                        await validateWorkspace(workspace);
                        const workflowBlocks = workspace.getAllBlocks().filter((block) => {
                            return blocksToIgnore.indexOf(block) === -1;
                        })
                        const warnings = []
                        workflowBlocks.forEach( (block) => {
                            if (block.warning !== null && !block.disabled) { 
                                let warning = block.getWarningText()
                                let blockId = block.type
                                if(typeof warning === 'string') {
                                    let warningInfo = { 
                                        "block_id": blockId, 
                                        "key":"warning_1",
                                        "message":  warning
                                    }
                                    warnings.push(warningInfo)
                                } else {
                                    const warningKeys = Object.keys(warning)
                                    warningKeys
                                        // add each warning to the warnings array
                                        .forEach( (key) => {
                                            let warningInfo = { 
                                                "block_id": blockId, 
                                                "key": key,
                                                "message":  warning[key]
                                            }
                                            warnings.push(warningInfo)
                                        })
                                }                                
                            }
                        })
                        this.$store.commit('blockly/setBlockWarnings', warnings);
                    }
                    this.$emit('change')
                }
            
            }catch(e){
                // store the error for later refernce, however continue to run the workflow stack.
                // This helps preserve state so it can be saved and maybe recover by allowing us to change blockly fields
                console.error('Error in updateLblockStates', e)
                this.blocklyStackError = e
            }finally{
                this.workflowStackRunning = false
            }
            
        },
        createBlockData(block) {
            return {
                id: block.id,
                type: block.type
            };
        }
    },
    computed: {
        toolboxContent() {
            return this.orgConfig ? this.orgConfig.getConfig(CONFIG_TYPE.TOOLBOX) : undefined;
        },
        defaultWorkflowUrl() {
            if (!this.orgCustomisations) {
                return '';
            }

            return this.orgCustomisations['url.path.default.workflow'] ? this.orgCustomisations['url.path.default.workflow'] : '';
        },
        canLoadJson() {
            return this.isSuperAdmin || this.isCustomerSupport;
        }
    }
};
</script>

<style scoped>
.blockly-div {
    height: 100%;
    width: 100%;
    text-align: left;
    flex-grow: 1;
}

.blocklyFlyoutBackgroundtt {
    fill: whitesmoke;
    fill-opacity: 1;
}
</style>
