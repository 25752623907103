<template>
    <div>
        <v-form>
            <v-text-field label="Name" v-model="localFormData.name" :error-messages="errorMessages.name"/>
            <v-textarea label="Description (optional)" v-model="localFormData.description" hide-details="auto"/>
        </v-form>
    </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import {required} from '@vuelidate/validators'
export default {
    name: "DatasetInfoForm",
    props: {
        formData: {
            type: Object,
            required: true
        }
    },
    emits: ['update:formData', 'formValid'],
    computed: {
        localFormData: {
            get() {
                return this.formData
            },
            set(value) {
                this.$emit('update:formData', value)
            }
        },
        errorMessages() {
            return {
                name: this.v$.localFormData.name.$dirty && this.v$.localFormData.name.required.$invalid ? 'Please add a name': null
            }
        }
    },
    setup: () => ({ v$: useVuelidate() }),
    watch: {
        'v$.$invalid': {
            handler() {
                this.$emit('formValid', !this.v$.$invalid)
            },
            immediate: true
        }
    },
    validations () {
        return {
            localFormData: {
                name: { required, $autoDirty: true },
                description: { $autoDirty: true }
            }
        }
    }
}
</script>