/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import "./registerServiceWorker";

// import styles
import "./styles/reset.scss";
import "./vue3-material/theme.scss";
import 'vue3-swatches/dist/style.css';
import './theme.scss';

import {createApp} from 'vue'
import store from "@/store/index";
import {createGlobalRouter} from "./router";
import {GlobalPlugin} from './plugins/global'
import {VuetifyPlugin} from "./vuetify/vuetifyPlugin";
import {VueMaterialPlugin} from "./vue3-material/material";
import { EbxPlugin } from "@/components/EbxComponents/EbxPlugin.js";
import { vMaska } from 'maska/vue'

// import services
import { AuthService } from './services/auth.service';
import { ConfigService } from "./services/config.service";
import { DatasetService } from "./services/dataset.service";
import { ContentService } from "./services/content.service"; 

// Import Blockly Requirements/Setup
import './toolbox_title'
import './toolbox_label'
import './blocklyTheme'
import './colours_renderer'
import './warning'
import './comment'
import { initialiseDataset } from '@/blocks/dataset'
import './blocks/init'

// import components
import App from './App.vue'

(async () => {

    // Init a bunch of services asynchronously
    await AuthService.init();
    await ConfigService.init();
    await DatasetService.init(AuthService);
    await ContentService.init();
    await initialiseDataset();

    // Setup the App
    const app = createApp(App)
        .use(VuetifyPlugin)
        .use(store)
        .use(GlobalPlugin()) // must be after store
        .use(createGlobalRouter())
        .use(VueMaterialPlugin)
        .use(EbxPlugin)
        .directive('maska', vMaska)
    
    // Mount the Application
    app.mount('#app')
})()