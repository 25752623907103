<template>
    <div class="dataset-page--overview">
        <div v-if="!isFeatureCollection" class="dataset-page--overview-section">
            <div class="dataset-page--date-section">
                <div class="dataset-page--date-section--period">
                    <p class="ebx-label dataset-page--overview-section__label">Cadence</p>
                    <div class="ebx-inline-input">
                        <div class="ebx-inline-input--view-only">
                            <span class="non-editable-date text-body-2">{{ cadenceToText(date.cadence) }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="dataset-page--overview-section">
            <p class="ebx-label dataset-page--overview-section__label">License</p>
            <EbxInlineInput 
                v-model="datasetLicense" 
                :disabled="!canEdit"
                :field-being-edited="fieldBeingEdited"
                :id="'dataset-license'"
                :input-placeholder="licensePlaceholder" 
                :input-type="`text`"
                :input-style-classes="['ebx-primary']"
                :input-validation="licenseValidation"
                :edit-actions-position="`right`"
                @editing-ebx-field="updateFieldBeingEdited"
                @save="updateLicense">
            </EbxInlineInput>
        </div>
        <div class="dataset-page--overview-section">
            <p class="ebx-label dataset-page--overview-section__label">Provider</p>
            <EbxInlineInput 
                v-model="datasetProvider"
                :disabled="!canEdit"
                :field-being-edited="fieldBeingEdited"
                :id="'dataset-provider'"
                :input-placeholder="providerPlaceholder" 
                :input-type="`text`"
                :input-style-classes="['ebx-primary']"
                :input-validation="providerValidation"
                :edit-actions-position="`right`"
                @editing-ebx-field="updateFieldBeingEdited"
                @save="updateProvider">
            </EbxInlineInput>
        </div>
        <div class="dataset-page--overview-section">
            <p class="ebx-label dataset-page--overview-section__label">Provider link</p>
            <EbxInlineInput 
                v-model="datasetProviderLink"
                :disabled="!canEdit"
                :field-being-edited="fieldBeingEdited"
                :id="'dataset-provider-link'"
                :input-placeholder="providerLinkPlaceholder" 
                :input-type="`text`"
                :input-style-classes="['ebx-primary']"
                :input-validation="providerLinkValidation"
                :edit-actions-position="`right`"
                @editing-ebx-field="updateFieldBeingEdited"
                @save="updateProviderLink">
            </EbxInlineInput>
        </div>
        <div class="dataset-page--overview-section">
            <p class="ebx-label dataset-page--overview-section__label">Description</p>
            <EbxInlineInput 
                v-model="datasetDescription"
                :disabled="!canEdit"
                :field-being-edited="fieldBeingEdited"
                :id="'dataset-description'"
                :input-placeholder="descriptionPlaceholder" 
                :input-type="`text`"
                :input-style-classes="['ebx-primary']"
                :input-validation="descriptionValidation"
                :edit-actions-position="`right`"
                @editing-ebx-field="updateFieldBeingEdited"
                @save="updateDescription">
            </EbxInlineInput>
        </div>
    </div>
</template>

<script>
import EbxInlineInput from '@/components/EbxComponents/EbxInlineInput.vue'

export default {
    name: 'UserDatasetView',
    components: {
        EbxInlineInput
    },
    props: {
        dataset: {
            type: Object,
            required: true
        },
        datasetImage: {
            type: Object,
            required: false,
            default: null
        },
        canEdit: {
            type: Boolean,
            required: true
        },
        fieldBeingEdited: {
            type: String,
            required: true
        }
    },
    emits: [
        'editing-ebx-field'
    ],
    data() {
        return {
            dateValidation: {
                error: '',
                active: false
            },
            descriptionValidation: {
                error: '',
                active: false
            },
            licenseValidation: {
                error: '',
                active: false
            },
            providerValidation: {
                error: '',
                active: false
            },
            providerLinkValidation: {
                error: '',
                active: false
            },
            datePlaceholder: 'Add a start date',
            datasetDescription: '',
            descriptionPlaceholder: 'Add a description to show in the dataset finder',
            datasetLicense: '',
            licensePlaceholder: 'Add licence details',
            datasetProvider: '',
            providerPlaceholder: 'Add provider',
            datasetProviderLink: '',
            providerLinkPlaceholder: 'Add provider URL',
            datasetId: '',
            date: {}
        }
    },
    computed: {
        isFeatureCollection() {
            return this.dataset.type === 'FeatureCollection';
        },
        hasDate() {
            return this.date.start_date !== null;
        }
    },
    watch: {
        dataset(newVal) {
            this.setLocalDatasetProperties(newVal);
        }
    },
    created() {
        this.setLocalDatasetProperties(this.dataset);
    },
    methods: {
        setLocalDatasetProperties(dataset) {
            this.datasetDescription = dataset.description || '';
            this.datasetLicense = dataset.license || '';
            this.datasetProvider = dataset.provider || '';
            this.datasetProviderLink = dataset.provider_url || '';
            this.datasetId = dataset.id || '';
            this.date = {
                start_date: dataset.start_date,
                cadence: dataset['ebx:cadence'].unit,
                imageType: dataset['ebx:cadence'].unit === 'epoch' ? 'point' : 'period'
            }
        },
        updateLicense() {
            this.$store.dispatch('userdatasets/updateDatasetProperties', {
                datasetId: this.datasetId,
                properties: { license: this.datasetLicense}
            })
        },
        updateProvider() {
            this.$store.dispatch('userdatasets/updateDatasetProperties', {
                datasetId: this.datasetId,
                properties: { provider: this.datasetProvider}
            })
        },
        updateProviderLink() {
            this.$store.dispatch('userdatasets/updateDatasetProperties', {
                datasetId: this.datasetId,
                properties: { providerUrl: this.datasetProviderLink}
            })
        },
        updateDescription() {
            this.$store.dispatch('userdatasets/updateDatasetProperties', {
                datasetId: this.datasetId,
                properties: { description: this.datasetDescription}
            })
        },
        updateFieldBeingEdited(field) {
            this.$emit('editing-ebx-field', field);
        },
        cadenceToText(cadence) {
            switch(cadence) {
                case 'year':
                    return 'Yearly';
                case 'month':
                    return 'Monthly';
                case 'week':
                    return 'Weekly';
                case 'day':
                    return 'Daily';
                default:
                    return 'Unknown';
            }
        }
    },
}
</script>