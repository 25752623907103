/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import * as Blockly from 'blockly/core';
import CalculatorModal from '@/modals/Calculator.vue';
import { AbstractBlock, AbstractFieldHelpers } from '@/blocks/_abstractBlock';
import { keyBy } from 'lodash'
import { CALCULATOR_EXPRESSION_STATE, VALID_BANDNAME_REGEX, INVALID_BANDNAME_ERROR, TABLE_DATE_CONSTANTS } from '@/constants/nextGenConstants';
import { ContentService } from '@/services/content.service';
import { standariseVariableDates, removeFieldFromInput, validateGlobalVariableUsageOnDataset } from './helper_functions';
import assets from '@/assets.js';

const FIELD = {
    MODAL: 'calculator',
    EXPRESSION: 'expression',
    NAME: 'NAME',
    LABEL: 'LABEL'
}

const INPUT = {
    MODAL: 'MODAL',
    EXPRESSION: 'EXPRESSION',
    NAME: 'NAME'
}

var calculatorJson ={
    "type": "analysis_calculator",
    "lastDummyAlign0": "RIGHT",
    "message0": "%1 %2 %3",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "calculator_title",
            "text": "%{BKY_ANALYSIS_CALCULATOR_BLOCK_TITLE}",
            "class": "boldTitleField"
        },
        {
            "type": "field_modal",
            "name": FIELD.MODAL,
            "modal_component": CalculatorModal,
            "modal_data": {
                expression: '',
                name: '',
                type: 'band',
                expressionState: CALCULATOR_EXPRESSION_STATE.CHECK,
                definedVariables: []
            },
            "src": assets.blockly.edit24dp,
            "width": 24,
            "height": 24,
            "opt_alt": "ALT STRING"
        },
        {
            "type": "input_dummy",
            "name": INPUT.MODAL
        },
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "calculate",
    "tooltip": "",
    "helpUrl": ""
}


Blockly.Blocks['analysis_calculator'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,
    onInit: function() {
        this.jsonInit(calculatorJson)

        this.about_block_url = ContentService.getAboutBlockUrl('analysis_calculator')

        this.getField(FIELD.MODAL).addEventListener('expression', (expression) => {
            this.setState('expression', expression)
            this.removedVars = [] // because the expression has just been successfully revalidated, we can clear this warning about removedVars
            this.updateShapeOnChange()
        })

        this.getField(FIELD.MODAL).addEventListener('type', (type) => {
            this.setState('type', type)
            this.updateShapeOnChange()
        })

        this.getField(FIELD.MODAL).addEventListener('name', (name) => {
            this.setState('name', name)
            this.updateShapeOnChange()
        })
        this.removedVars = []
    },
    accept: async function (visitor) {
        await visitor.visitAnalysisCalculatorBlock(this);
    },
    nameValidator(newValue) { 
        const block = this.getSourceBlock();
        let modalData = block.getFieldValue(FIELD.MODAL)
        const test = VALID_BANDNAME_REGEX.test(newValue);
        if(!test) {
            return block.setWarningText(INVALID_BANDNAME_ERROR)
        } else {
            modalData.name = newValue
            block.setFieldValue(modalData, FIELD.MODAL)
            block.setWarningText(null)
        }
        return newValue
    },
    ebxValidate: function(tooltips) {

        this.setWarningText(null, 'not_raster')
        const isRaster = this.getState('isRaster')
        if(!isRaster) {
            this.setWarningText(tooltips['not_raster'] || 'This block is only compatible with raster datasets.', 'not_raster')
            return
        }

        const modalField = this.getFieldValue(FIELD.MODAL)
        if(modalField && modalField.definedVariables) {
            if(modalField.definedVariables.length === 0) {
                return this.setWarningText(tooltips['no_variables_defined'] || 'Add at least one variable', 'no_variables_defined')
            }
            if(modalField.definedVariables.filter(defVar => defVar.dates.length === 0).length > 0) {
                return this.setWarningText(tooltips['no_variable_dates_named'] || 'Add a name for each variable', 'no_variable_dates_named')
            }
            if(modalField.definedVariables.filter(defVar => defVar.properties.length === 0).length > 0) {
                return this.setWarningText(tooltips['no_variable_bands_named'] || 'Choose a band for each variable', 'no_variable_bands_named')
            }
    
            // Check Variables
            const datasets = this.getState('image_collections')
            const rowErrors = validateGlobalVariableUsageOnDataset(datasets, modalField.definedVariables, tooltips, this)
            if(rowErrors.length > 0) {
                const message = tooltips['invalid_dataset_with_variable'] || 'Cannot use table variable with date period as variables are used in the dataset'
                return this.setWarningText(message, 'invalid_dataset_with_variable')
            }

            if (this.removedVars && this.removedVars.length > 0){
                modalField.expressionState = CALCULATOR_EXPRESSION_STATE.CHECK
                var plural = false
                this.removedVars.length > 1 && (plural = true)
                var message = `The variable${plural ? 's' : ''} ${this.removedVars.map(String).join(', ')} ${plural ? 'are' : 'is'} no longer available. Please revalidate your expression.`;
                return this.setWarningText(message, 'no_dataset_for_variable')    
            }

            this.setWarningText(null, 'no_variables_defined')
            this.setWarningText(null, 'no_variable_dates_named')
            this.setWarningText(null, 'no_variable_bands_named')
            this.setWarningText(null, 'invalid_dataset_with_variable')
            this.setWarningText(null, 'no_dataset_for_variable')
        }
        return true
    },
    renameDefaultDataset(previousId, newBlockId) {
        const modalData = this.getFieldValue(FIELD.MODAL)
        if(modalData && Array.isArray(modalData.definedVariables)) {
            modalData.definedVariables = modalData.definedVariables.map(v => {
                if(v.dataset.slice(0, previousId.length) === previousId) {
                    v.dataset = v.dataset.replace(previousId, newBlockId)
                }
                return v
            })
            this.setFieldValue(modalData, FIELD.MODAL)
            this.checkDefinedVariablesAreValid()
        }
    },
    updateShape_() {
        const surroundParent = this.getSurroundParent()
        if(surroundParent && surroundParent.isEnabled() === false) {
            return
        }
        if(this.isEnabled() === false) {
            return
        }
        if(this.hasStateChanged('expression')) {
            if(this.inputExists(INPUT.EXPRESSION) === false) {
                this.appendDummyInput(INPUT.EXPRESSION)
            }
            if(this.getField(FIELD.EXPRESSION) === null) {
                this.getInput(INPUT.EXPRESSION).appendField(this.getState('expression'), FIELD.EXPRESSION)
            } else {
                this.setFieldValue(this.getState('expression'), FIELD.EXPRESSION)
            }
        }
        if(this.hasStateChanged('image_collections')) {
            const modalData = this.getFieldValue(FIELD.MODAL)
            if(modalData) {
                this.checkDefinedVariablesAreValid()
            }
            this.getField(FIELD.MODAL).setModalData('datasets', this.getState('image_collections'))
        }
        if(this.hasStateChanged('type')) {
            if (this.inputExists(INPUT.NAME) === false) {
                if (this.getState('type') === 'band') {
                    this.appendDummyInput(INPUT.NAME).appendField('Band name',FIELD.LABEL)
                } else {
                    this.appendDummyInput(INPUT.NAME).appendField('Dataset name',FIELD.LABEL)
 
                }
            } 
            if(this.getField(FIELD.LABEL) === null) {
                if (this.getState('type') === 'band') {
                    this.getInput(INPUT.NAME).appendField('Band name', FIELD.LABEL)
                } else { 
                    this.getInput(INPUT.NAME).appendField('Dataset name', FIELD.LABEL)
                }
            } else {
                if (this.getState('type') === 'band') {
                    this.setFieldValue('Band name', FIELD.LABEL)
                } else { 
                    this.setFieldValue('Dataset name', FIELD.LABEL)
                }
            }
        }

        if (this.hasStateChanged('name')) {
            if(this.fieldExists(FIELD.NAME)) {
                removeFieldFromInput(this.getInput(INPUT.NAME), FIELD.NAME)
            }
            this.getInput(INPUT.NAME).appendField(new Blockly.FieldTextInput(this.getState('name')), FIELD.NAME)
            this.getField(FIELD.NAME).setValidator(this.nameValidator);
            }
        },
    getAvailableBands() {
        let bands = []
        const modalData = this.getFieldValue(FIELD.MODAL)

        if(modalData && Array.isArray(modalData.definedVariables)){
            modalData.definedVariables.forEach(v => {
                let properties = v.properties
                if(v.propertiesTransform !== null) {
                    properties = [{'ebx:name': [v.variableName, v.propertiesTransform].join('_') }]
                }
    
                if(bands.length === 0) {
                    properties.forEach(property => bands.push([property['ebx:name']]))
                } else {
                    const newBands = []
                    properties.forEach(property => {
                        bands.forEach(b => {
                            const propBand = b.slice(0)
                            propBand.push(property['ebx:name'])
                            newBands.push(propBand)
                        })
                    })
                    bands = newBands
                }
            })
        }
        return bands.map(b => b.join('_'))
    },
    checkDefinedVariablesAreValid() {
        const modalData = this.getFieldValue(FIELD.MODAL)
        if(modalData) {
            const filteredDefinedVariables = []
            const datasets = this.getState('image_collections')
            const validDatasets = keyBy(datasets,'id')
            const oldDefinedVariables = JSON.stringify(modalData.definedVariables)
            modalData.definedVariables.forEach(definedVariable => {
                if(validDatasets[definedVariable.dataset]) {
                    const useDefinedVariable = Object.assign({}, definedVariable)
                    const currentDataset = validDatasets[definedVariable.dataset]

                    const currentDatasetDates = [...currentDataset.multi_image_temporal.temporal.map(date => date.value), ...TABLE_DATE_CONSTANTS.map(d => d.value)]
                    const standardisedDates = standariseVariableDates(definedVariable.cadence, currentDataset.multi_image_temporal.cadence, definedVariable.dates)
                    useDefinedVariable.dates = standardisedDates.filter(d => currentDatasetDates.indexOf(d) >= 0)  

                    const currentDatasetProperties = currentDataset.bands.map(property => property['ebx:name'])
                    const newProperties = definedVariable.properties.filter(d => currentDatasetProperties.indexOf(d['ebx:name']) >= 0)
                    useDefinedVariable.properties = newProperties

                    useDefinedVariable.cadence = currentDataset.multi_image_temporal.cadence


                    filteredDefinedVariables.push(useDefinedVariable)
                } else {
                    if (!this.removedVars.includes(definedVariable.variableName)){
                        this.removedVars.push(definedVariable.variableName)
                    }
                }
            })
            modalData.definedVariables = filteredDefinedVariables
            const newDefinedVariables = JSON.stringify(modalData.definedVariables)
            if(oldDefinedVariables != newDefinedVariables) {
                this.setFieldValue(modalData, FIELD.MODAL)
            }
        }
        
    }
}; 