<template>
    <div class="image-date-selection">
        <div v-if="!hasStacId">
            <p class="font-weight-bold my-2"> Image type </p> 
            <v-radio-group hide-details="auto" v-model="updatedForm.imageType">
                <v-radio label="Image is from a specific date" value="point"></v-radio>
                    <div v-if="imageType === 'point'" class="date-input-container date-input-container__indented">
                        <VEbxDateInput 
                            v-model="updatedForm.startDate" 
                            label="Select date"
                            :error-message="dateErrorMessage"/>
                    </div>
                <v-radio label="Image represents a time period" value="period"></v-radio>
                    <div v-if="imageType === 'period'" class="date-input-container date-input-container__indented date-input-container--dual">
                        <v-select 
                            v-model="cadence" 
                            variant="outlined"
                            :items="cadenceOptions" 
                            item-title="text" 
                            item-value="value"
                            label="Cadence" 
                            return-object
                            hide-details="auto"
                            />
                        <VEbxDateInput 
                            v-model="updatedForm.startDate" 
                            label="Start date" 
                            :error-message="dateErrorMessage"/>
                    </div>
            </v-radio-group>
        </div>
        <div v-else>
            <div v-if="isPoint">
                <p class="font-weight-bold  my-2"> Point in time </p> 
                <div class="date-input-container">
                    <VEbxDateInput v-model="updatedForm.startDate"
                        label="Select date" 
                        :error-message="dateErrorMessage"/>
                </div>
            </div>
            <div v-else-if="isPeriod">
                <p class="font-weight-bold  my-2"> Time period </p>
                <div class="date-input-container date-input-container--dual mt-2">
                    <v-select 
                        v-model="cadence"
                        variant="outlined"
                        :items="cadenceOptions" 
                        item-title="text" 
                        item-value="value" 
                        label="Cadence" 
                        :disabled="cadenceIsReadOnly"
                        return-object
                        hide-details="auto"
                        />
                    <VEbxDateInput 
                        v-model="updatedForm.startDate" 
                        label="Start date" 
                        :error-message="dateErrorMessage"/>
                </div>  
            </div>          
        </div>
    </div>
</template>


<script>
import VEbxDateInput from '@/components/EbxComponents/VEbxDateInput.vue';
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
    components: {
        VEbxDateInput
    },
    setup: () => ({ v$: useVuelidate() }),
    props: {
        formData: { 
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            cadenceOptions: [
                { text: 'Yearly', value: 'year' },
                { text: 'Monthly', value: 'month' },
                { text: 'Weekly', value: 'week' }
            ],
            prevCadence: null,
            updatedForm: {
                cadence: '',
                imageType: '',
                startDate: null,
                stac_id: null
            },
        }
    },
    emits: ['update:formData', 'formValid'],
    computed: { 
        hasStacId() {
            return this.formData.stac_id !== null;
        },
        imageType() {
            return this.updatedForm.imageType;
        },
        isPoint() {
            return this.imageType === "point";
        },
        isPeriod() {
            return this.imageType === "period";
        },
        cadenceIsReadOnly() {
            return this.hasStacId;
        },
        cadence: {
            get() {
                // match the cadence value to the cadenceOptions value
                return this.cadenceOptions.find(option => option.value === this.updatedForm.cadence);
            },
            set(cadenceOption) {
                if(this.hasStacId === false) {
                    this.updatedForm.cadence = cadenceOption.value;
                    this.prevCadence = cadenceOption.value;
                }
            }
        },
        dateErrorMessage() {
            if (this.v$.updatedForm.startDate.$dirty) {

                if (this.v$.updatedForm.startDate.required.$invalid) {
                    return 'Please enter a valid date';
                }

                if (this.v$.updatedForm.startDate.between.$invalid) {
                    return 'Date must be between 1970 and 9999';
                }
            }
            return '';
        },
    }, 
    watch: {
        imageType(value) {
            if (value === "point") {
                this.updatedForm.cadence = 'day';
            } else if (value === "period") {
                if (this.prevCadence !== null) {
                    this.updatedForm.cadence = this.prevCadence;
                } else {
                    this.updatedForm.cadence = 'year';
                }
            }
            return value;
        },
        updatedForm: {
            handler(newValue) {
                this.$emit('update:formData', newValue, !this.v$.$invalid)
                return newValue;
            },
            deep: true
        },
    },
    created() {
        this.updatedForm = Object.assign({}, this.formData);
        this.prevCadence = this.formData.cadence;
    },
    validations () {
        return {
            updatedForm: {
                startDate: {
                    $autoDirty: true,
                    required,
                    between: value => {
                        if (value === null) {
                            return true;
                        }
                        const date = Date.parse(value);
                        return date >= Date.parse('1970-01-01') && date <= Date.parse('9999-12-31');
                    }
                },
                imageType: {
                    required,
                }
            },
        }
    },
}
</script>
