<template>
    <v-card variant="flat" class="bg-slate-50" rounded>
        <v-card-text>
            <slot>
                <slot name="prompt">
                    <p class="text-body-1 text-center">Choose a GeoTIFF file to upload.</p>
                </slot>
                <div class="d-flex justify-center">
                    <slot name="action">
                        <v-btn class="mt-4" color="primary" prepend-icon="mdi-tray-arrow-up" :disabled="disabled" @click="handleClick">Choose file</v-btn>
                    </slot>
                </div>
                <slot name="secondary-prompt">
                    <p class="text-body-2 text-center mt-4">To create an image mosaic, choose multiple files.</p>
                </slot>
            </slot>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    },
    emits: ['click'],
    methods: {
        handleClick() {
            this.$emit('click');
        }
    }
}
</script>