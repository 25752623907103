<template>
    <v-dialog :class="dialogClass" v-bind="dialogProps"> 
      <template v-slot:activator="{ props: activatorProps }">
          <slot name="activator" :props="activatorProps"></slot>
      </template>
      <template v-slot:default>
          <v-card>
              <v-toolbar v-if="showToolbar">
                <slot name="toolbar">
                  <v-toolbar-title v-if="showToolbarTitle">
                      {{ toolbarTitle }}
                  </v-toolbar-title>
                  <v-btn v-if="showDismissButton" icon :disabled="dismissButtonDisabled" @click="dismiss">
                      <v-icon>mdi-close</v-icon> 
                  </v-btn>
                </slot>
              </v-toolbar>
              <v-divider v-if="showDivider"></v-divider>
              <v-card-text>
                  <slot>
                    {{ cardText }}
                  </slot>
              </v-card-text>
              <v-divider v-if="showDivider"></v-divider>
              <v-card-actions>
                <div class="mr-auto"><slot name="left-actions"></slot></div>
                <slot name="actions">
                  <v-btn
                    v-if="cancelButtonShow"
                    :class="{ 'mr-4': confirmationButtonShow }"
                    :variant="cancelButtonVariant"
                    :color="cancelButtonColour" 
                    :disabled="cancelButtonDisabled"
                    @click="cancel"
                  >
                    <slot name="cancel-button">{{ cancelButtonText }}</slot>
                  </v-btn>
                  <v-btn
                    v-if="confirmationButtonShow"
                    :class="{ 'ml-0': cancelButtonShow }"
                    :variant="confirmationButtonVariant"
                    :color="confirmationButtonColour" 
                    :disabled="confirmationButtonDisabled"
                    @click="confirm"
                  >
                    <slot name="confirm-button">{{ confirmationButtonText }}</slot>
                  </v-btn>
                 </slot>
              </v-card-actions>
          </v-card>
      </template>
    </v-dialog>
</template>

<script>
export default {
  name: "VEbxModal",
  props: {
    showDismissButton: { //Close button may not be required on all modals so make it configurable
      type: Boolean,
      default: true,
    },
    dismissButtonDisabled: {
      type: Boolean,
      default: false,
    },
    cardText: {
      type: String,
      default: "",
    },
    toolbarTitle: {
      type: String,
      default: "Are you sure?",
    },
    cancelButtonText: {
      type: String,
      default: "Cancel",
    },
    cancelButtonVariant: {
      type: String,
      default: "text",
    },
    cancelButtonColour : {
      type: String,
      default: 'primary',
    },
    dismissCloseOnAction: {
      type: Boolean,
      default: true,
    },
    cancelCloseOnAction: {
      type: Boolean,
      default: true,
    },
    cancelButtonShow: {
      type: Boolean,
      default: true,
    },
    cancelButtonDisabled: {
      type: Boolean,
      default: false,
    },
    confirmationButtonText: {
      type: String,
      default: "Okay",
    },
    confirmationButtonVariant: {
      type: String,
      default: "flat",
    },
    confirmationButtonColour : {
      type: String,
      default: "primary",
    },
    confirmationCloseOnAction: {
      type: Boolean,
      default: true,
    },
    confirmationButtonShow: {
      type: Boolean,
      default: true,
    },
    confirmationButtonDisabled: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false
    },
    showToolbar: {
      type: Boolean,
      default: true,
    },
    showToolbarTitle: {
      type: Boolean,
      default: true,
    },
    showDivider: {
      type: Boolean,
      default: true,
    },
    isModalScrollable : {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: undefined
    },
    theme: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: null
    },
    
  },
  emits: ['update:modelValue', 'afterEnter', 'afterLeave', 'cancel', 'confirm', 'dismiss'],
  data() {
    return {
      localIsActive: false,
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(val) {
        if(val !== undefined) {
          this.localIsActive = val
        }
      }
    },
  },
  computed: {
    dialogClass() {
      if(this.size === null) {
        return ''
      }
      return `modal-${this.size}`
    },
    dialogProps() {
      const props = {
        scrollable: this.isModalScrollable,
        onAfterEnter: () => this.$emit('afterEnter'), 
        onAfterLeave: () => this.$emit('afterLeave'),
      }
      props.modelValue = this.localIsActive
      props['onUpdate:modelValue'] = (val) => {
        if(this.modelValue !== undefined) {
          this.$emit('update:modelValue', val)
        } else {
          this.localIsActive = val
        }
      }
      if(this.fullscreen) {
        props.fullscreen = true
      }
      if(this.persistent) {
        props.persistent = true
      }
      if(this.theme) {
        props.theme = this.theme
      }
      return props
    }
  },
  methods: {
    dismiss() {
      if(this.dismissCloseOnAction) {
        this.localIsActive = false
        if(this.modelValue !== undefined) {
          this.$emit('update:modelValue', this.localIsActive)
        }
      }
      this.$emit('dismiss')
    },
    cancel() {
      if(this.cancelCloseOnAction) {
        this.localIsActive = false
        if(this.modelValue !== undefined) {
          this.$emit('update:modelValue', this.localIsActive)
        }
      }
      this.$emit('cancel', {isActive: this.localIsActive})
    },
    confirm() {
      if(this.confirmationCloseOnAction) {
        this.localIsActive = false
        if(this.modelValue !== undefined) {
          this.$emit('update:modelValue', this.localIsActive)
        }
      }
      this.$emit('confirm', {isActive: this.localIsActive})
    }
  }
};
</script>
